
  <div  class="top-bar">
    <button class="blue-button1" (click)="openModalTable()">Open Report</button>
    <button class="blue-button1" (click)="saveAll()">Save Report</button>
    <a class="blue-button1" [routerLink]="['/User_guide']" target="_blank">User-Guide</a>
  </div>

  <br><br>
  <div id="bodydiv">
    <h5 style="display: inline-block;">Project Lifecycle Cost</h5>
    <br>
    <label class="nots">Note: All Fields with an asterisk(<label class="mark">*</label>) are mandatory</label>
    <br><br>

    <jw-modal id="custom-modal-1">
      <table>
        <tr>
          <td>
            <h3>Inquiry Compliant First Cost:</h3>
          </td>
          <td style="align-content: flex-end;">
          </td>
        </tr>
        <!-- <tr><td>&bull; Off-BEP Operation</td><td>&bull; Degradation of Bearings</td></tr> -->
        <tr>
          <td>&bull; Here "Inquiry Compliant First Cost" stands for the sum of "Installation Cost" & "Net Price".</td>
        </tr>
      </table>
    </jw-modal>

    <div style="width: 50%;display: inline-block;padding: 0%; padding-right: 5%;">

      <label class="labl">Inquiry Compliant First Cost:<label class="mark">*</label>
        <i class='iconcls' (click)="openModal('custom-modal-1')" *ngIf="!hint1" [tooltip]="HtmlContent"
          content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
          class="fa">&#xf05a;</i>
        <i class='iconcls' (click)="hint1 = false" *ngIf="hint1" [tooltip]="HtmlContent2" content-type="template"
          placement="top" showDelay="500" style="font-size:12px; color:red" class="fa">&#xf2d1;</i>
      </label>
      {{curncy}}<input type="text" class="inputnam" [(ngModel)]="Jsondata['inqcst1']"><br><br>

      <label class="labl">Inquiry Compliant Annual Maintenance Cost:<label class="mark">*</label></label>
      {{curncy}}<input class="inputnam" [(ngModel)]="Jsondata['inqmnt1']">
    </div>
    <div style="width: 50%;display: inline-block;padding: 0%; padding-left: 5%;">
      <label class="labl">Inquiry Compliant Annual Energy Cost:<label class="mark">*</label></label>
      {{curncy}}<input type="text" class="inputnam" [(ngModel)]="Jsondata['inqeng1']"><br><br>


    </div><br><br>
    <div style="width: 50%;display: inline-block;padding: 0%; padding-right: 5%;">
      <label class="labl">Value Add Bid First Cost:<label class="mark">*</label></label>
      {{curncy}}<input type="text" class="inputnam" [(ngModel)]="Jsondata['inqcst2']"><br><br>
      <label class="labl">Value Add Bid Annual Maintenance Cost:<label class="mark">*</label></label>
      {{curncy}}<input class="inputnam" [(ngModel)]="Jsondata['inqmnt2']">
    </div>
    <div style="width: 50%;display: inline-block;padding: 0%; padding-left: 5%;">
      <label class="labl">Value Add Bid Annual Energy Cost:<label class="mark">*</label></label>
      {{curncy}}<input type="text" class="inputnam" [(ngModel)]="Jsondata['inqeng2']"><br><br><br><br>
    </div><br><br>

    <div *ngIf="showMesuggest1"><label class="not1">*NOTE: Though "Essential" is auto selected with the selection of
        "ALL SUBSCRIPTIONS", but it is highly recommended to select "Professional" along with these below
        subscriptions.</label>

    </div>
    <div *ngIf="!showMesuggest1 && showMesuggest2"><label class="not1">*NOTE: Though "Essential" & "Smart Start-Up" are
        default selections with the selection of "Smart Commissioning", but it is strongly recommended to select
        "Professional" subcription along with it.</label>

    </div>
    <div *ngIf="!showMesuggest2 &&  showMesuggest3"><label class="not1">*NOTE: Though "Essential" is auto selected with
        the selection of "Start-Up", but it is highly recommended to select "Professional" subcription along with "Smart
        Start-Up".</label>

    </div><br>

    <label class="labl">Value Added Services:<label class="mark">*</label></label>

    <div class="form-check">
      <div *ngIf="selall">
        <input class="form-check-input" type="checkbox" [(ngModel)]="Jsondata['allsel']" name="flexCheckDefault"
          (change)="bulk($event)" checked>
        <label class="form-check-label" for="flexCheckDefault">
          Select All
        </label>
      </div>
      <div *ngIf="!selall">
        <input class="form-check-input" type="checkbox" [(ngModel)]="Jsondata['alldesel']" name="flexCheckDefault2"
          (change)="commselec()" checked>
        <label class="form-check-label" for="flexCheckDefault2">
          De-select All
        </label>
      </div>
      <div>
        <input type="checkbox" [disabled]="disabpro" [(ngModel)]="Jsondata['pumps']" name="pumpprof"
          (change)="commselec4()" checked><label style="margin-left: 5px;" class="labl1" for="pumpprof"> Pump Manager
          Professional</label><br>
      </div>
      <div>

        <input type="checkbox" [disabled]="disabess" [(ngModel)]="Jsondata['pumpes']" name="pumpess"
          (change)="commselec3()"><label style="margin-left: 5px;" class="labl1" for="pumpess"> Pump Manager
          Essential</label><br>

        <input type="checkbox" [disabled]="disabsms" [(ngModel)]="Jsondata['smarts']" name="Startup"
          (change)="commselec2()"><label style="margin-left: 5px;" class="labl1" for="Startup"> SMART
          Start-Up</label><br>

        <input *ngIf="disabcoms" type="checkbox" [(ngModel)]="Jsondata['smartc']" name="Commiss"
          (change)="commselec8()">
        <label *ngIf="disabcoms" style="margin-left: 5px;" class="labl1" for="Commiss"> SMART Commissioning</label><br
          *ngIf="disabcoms">

        <input type="checkbox" [(ngModel)]="Jsondata['perfup']" name="perfupg" (change)="commselec5()"><label
          style="margin-left: 5px;" class="labl1" for="perfupg"> Performance Upgrade(DEPC Retrofit)</label><br>
      </div>

      <div class="checklst">
        <input type="checkbox" [disabled]="disabcew" [(ngModel)]="Jsondata['cew']" name="pumpcew"
          (change)="commselec1()" checked><label style="margin-left: 5px;" class="labl1" for="pumpcew">Extended Warranty
          (EW)</label><br>
        <input type="checkbox" [(ngModel)]="Jsondata['train']" name="training" (change)="commselec6()" checked><label
          style="margin-left: 5px;" class="labl1" for="training">Training</label><br>
        <!-- <input type="checkbox"  [(ngModel)]= "Jsondata['pkits']" name="partkit" (change) = "commselec2()" checked ><label style="margin-left: 5px;" class="labl1" for="partkit">Parts & Kits</label><br> -->


        <input type="checkbox" [(ngModel)]="Jsondata['scont']" name="servcont" (change)="commselec7()" checked><label
          style="margin-left: 5px;" class="labl1" for="servcont">Active Performance Management Service
          Contracts</label><br>

      </div>
    </div>


    <label class="not">Instructions:</label>
    <ul class="note">
      <li>Please provide corresponding ADEPT DEVA entries for “Compliant First Cost” and “Value Added Bid Cost” fields.
        If you do not provide new input values, the tool will consider current default values as displayed.
      </li>
      <li>Input/default values will be utilized for evaluating the monetary impact of Armstrong services on the project
        lifecycle cost.</li>
    </ul>
    <button style="display: inline-block;" class="blue-button2" (click)="back()">Back</button>

    <button style="display: inline-block;" class="blue-button" (click)='rout()'>Next</button>
    <!-- if you want to disale the btn then- "[disabled]="!Jsondata['inqcst1'] || !Jsondata['inqmnt1'] || !Jsondata['inqeng1'] || !Jsondata['inqcst2'] || !Jsondata['inqmnt2'] || !Jsondata['inqeng2']"-- add this in above statement -->
    <br><br>
  </div>

  <!-- <div class="form-check">
  <input type="checkbox" (change)="bulk($event)"><br>
  <input type="checkbox" [checked]="checks"><br>
  <input type="checkbox" [checked]="checks"><br>
  <input type="checkbox" [checked]="checks"><br>
</div>-->

  <ng-template #HtmlContent2>
    <div>Minimize Content</div>
  </ng-template>
  <ng-template #HtmlContent>
    <div>Click to View <b>Possible Impact</b></div>
  </ng-template>


