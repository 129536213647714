import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { ProcessserviceService } from '../processservice.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {

  get hasHistory(): boolean {
    return window.history.length > 1;
  }

  constructor(private location: Location, private Appcomp: AppComponent, private Router: Router, private prservice: ProcessserviceService) { }


  //pdfSrc=" ./DEVA_user guide_test1.pdf "
  captureScreen() {
    let popupWinindow
    let innerContents = document.getElementById('contentToConvert').innerHTML;
    popupWinindow = window.open('', '_blank', 'width=1200px,height=600px,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>Deva Analytics</title>
       <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>
      <link rel="stylesheet" type="text/css" href="assets/styles.css" />
        <script src="http://code.jquery.com/jquery-2.1.0.min.js"></script>
        <script>
          window.onload = function() {
            setTimeout(() => {
              window.moveTo(0,0);
          window.resizeTo(1400, 860);
              window.print();
              window.close()
            }, 2000);
          };
        </script>
      </head>
      <body>
        ${innerContents}
      </body>
    </html>
    `);
    popupWinindow.document.close();
  }


  ngOnInit(): void {
  }
  back(): void {
    this.location.back()
  }

}
