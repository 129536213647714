import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../_modal/modal.service';
import { ModalTableService } from '../modal-table/modal-table.service';
import { ProcessserviceService } from '../processservice.service';
import { FormSaverService } from '../services/formsaver.service';

@Component({
  selector: 'app-cost-evaluation',
  templateUrl: './cost-evaluation.component.html',
  styleUrls: ['./cost-evaluation.component.css']
})
export class CostEvaluationComponent implements OnInit {
  public disabcoms = false
  public Jsondata: object = {
    'inqcst1': "40000",
    'inqeng1': "10750",
    'inqmnt1': "2250",
    'inqcst2': "50000",
    'inqeng2': "10000",
    'inqmnt2': "2000",
    'Pumpservice': "pumppro",
    'Pumpservice1': "pumpess",
    'pumps': true,
    'smarts': false,
    'smartc': false,
    'pumpes': false,
    'perfup': false,
    'cew': false,
    'train': false,
    'allsel': false,
    'alldesel': false
  }
  public disabsms = false
  public disabess = false
  public disabpro = false;
  public disabcew = false;
  public hint1 = false;

  showMesuggest1: boolean = false;
  showMesuggest2: boolean = false;
  showMesuggest3: boolean = false;
  selall: boolean = true;

  country: any = [];
  currency: any = [];
  value: any;
  curncy: any;
  checks = false;

  constructor(
    private modalService: ModalService,
    private Router: Router,
    private prservice: ProcessserviceService,
    public formSaver: FormSaverService,
    private modalTableService: ModalTableService,
  ) { }

  symbol: { id: number, name: string }[] = []

  crncy = {};

  ngOnInit(): void {
    this.display();

    if ('inqcst1' in this.prservice.costinfo) {
      this.Jsondata = this.prservice.costinfo
    }

    this.disabcoms = this.prservice.userinfo["Projecttype"] !== "newbuilding"
    this.country = this.prservice.country();

    this.formSaver.formsData$.subscribe((data) => {
      this.restoreFormData(data.costInfo);
    });

  }

  display() {
    const dropvalue = localStorage.getItem('curncy');
    if (dropvalue) {
      this.curncy = JSON.parse(dropvalue);
    }
  }

  saveAll() {
    this.processForm();
    this.formSaver.saveAll();
  }

  restoreFormData(formData: object) {
    if (formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          this.Jsondata[key] = formData[key];
        }
      }
      this.processForm();
    }
  }

  processForm() {
    this.prservice.costinfo = this.Jsondata
  }

  rout() {
    this.processForm();
    if (!this.Jsondata['inqcst1'] || !this.Jsondata['inqmnt1'] || !this.Jsondata['inqeng1'] || !this.Jsondata['inqcst2'] || !this.Jsondata['inqmnt2'] || !this.Jsondata['inqeng2']) {
      this.Router.navigate(['../costeval'])
      alert('Empty Mandatory Field Detected !')
    }
    else {
      this.Router.navigate(['../calculation-view']);
    }
  }

  bulk(e) {
    if (e.target.checked == true) {
      //this.checks=true;
      console.log("dont know");
      // this.prservice.userinfo["Projecttype"]=="newbuilding"
      this.Jsondata['perfup'] = true
      this.Jsondata['smartc'] = true
      this.disabcoms = true
      this.Jsondata['smarts'] = true
      this.disabpro = false
      this.Jsondata['pumpes'] = true
      this.Jsondata['cew'] = true
      this.Jsondata['train'] = true
      this.Jsondata['pkits'] = true
      this.Jsondata['scont'] = true
      this.showMesuggest1 = true
      this.showMesuggest2 = false
      this.commselec8();
      //this.commselec5();
      // this.commselec2();

      //this.Jsondata['pumps']= true
    }
    else {
      // this.disabsms = false
      // this.Jsondata['pumps'] = false
      // this.showMesuggest2 = false
      // this.showMesuggest3 = true
      this.Jsondata['perfup'] = false
      this.Jsondata['smartc'] = false
      this.Jsondata['pumpes'] = false
      this.disabess = false
      this.Jsondata['cew'] = false
      this.Jsondata['train'] = false
      this.Jsondata['pkits'] = false
      this.Jsondata['scont'] = false
      this.Jsondata['pumps'] = false
      this.Jsondata['smarts'] = false
      this.disabsms = false
      // this.selall = true
      this.showMesuggest1 = false
      this.showMesuggest2 = false
      this.showMesuggest3 = false
    }

  }

  commselec() {
    if (this.Jsondata['allsel'] == true && this.prservice.userinfo["Projecttype"] == "newbuilding") {
      console.log('allsel doing')
      this.Jsondata['perfup'] = true
      this.Jsondata['smartc'] = true
      this.Jsondata['pumpes'] = true
      this.Jsondata['cew'] = true
      this.Jsondata['train'] = true
      this.Jsondata['pkits'] = true
      this.Jsondata['scont'] = true
      this.Jsondata['pumps'] = true
      this.showMesuggest1 = true
      this.showMesuggest2 = false
      this.selall = false
      this.Jsondata['alldesel'] = false
    }



    if (this.Jsondata['smartc'] == true && this.prservice.userinfo["Projecttype"] == "newbuilding") {
      this.Jsondata['smarts'] = true
      this.disabsms = true
      this.Jsondata['pumpes'] = true
      this.disabess = true
      this.Jsondata['pumps'] = false
      this.showMesuggest2 = true
    }
    // if(this.Jsondata['smarts'] == true && this.Jsondata['pumps']== true){
    //   this.disabess = false}
    // else if(this.Jsondata['smarts'] = true){

    //   this.Jsondata['pumpes'] = true
    //   this.disabess = true
    // }
    else {
      this.disabsms = false
      this.Jsondata['pumps'] = false
      this.showMesuggest2 = false
      this.showMesuggest3 = true
    }



  }


  commselec2() {
    if (this.Jsondata['smarts'] = true && this.bulk) {
      this.showMesuggest3 = true
      this.showMesuggest1 = false
      this.showMesuggest2 = false
      this.Jsondata['pumpes'] = true
      this.disabess = true
      this.Jsondata['pumps'] = false

    } else {
      this.disabess = false
      this.Jsondata['pumps'] = false
      this.showMesuggest3 = false
      this.showMesuggest2 = false
      this.showMesuggest1 = false
    }



  }
  commselec1() {

    if (this.Jsondata['scont'] == true) {
      this.Jsondata['pumps'] = true
      this.disabpro = true


    }

    if (this.Jsondata['cew'] == true || this.Jsondata['train'] == true) {
      this.Jsondata['pumps'] = false
      this.Jsondata['pumpes'] = false
    }



  }
  commselec3() {
    if (this.Jsondata['pumpes'] == true) {
      this.Jsondata['pumps'] = false
      this.showMesuggest1 = false
      this.showMesuggest2 = false
      this.showMesuggest3 = false
    }
  }
  commselec4() {
    if (this.Jsondata['pumps'] == true) {
      this.Jsondata['pumpes'] = false
      this.disabess = true
      this.showMesuggest1 = false
      this.showMesuggest2 = false
      this.showMesuggest3 = false
    }
    else {
      this.Jsondata['pumpes'] = true
      this.showMesuggest1 = true
      this.showMesuggest2 = true
      this.showMesuggest3 = true
      //    this.disabess= true
    }

    // if(this.Jsondata['allsel']== false && this.Jsondata['smarts']== true){
    //   this.showMesuggest3 = true
    //   console.log("seriouslyyyyyy")
    // }
  }

  commselec5() {
    if (this.Jsondata['perfup'] = true || this.bulk) {
      this.showMesuggest2 = false
      this.Jsondata['smartc'] = true
      this.disabcoms = true
      this.Jsondata['smarts'] = true
      this.disabsms = true
      this.Jsondata['pumpes'] = false
      this.disabess = true
      this.Jsondata['pumps'] = true
      this.disabpro = true
      this.Jsondata['cew'] = true
      this.showMesuggest1 = false
      this.showMesuggest2 = false
      console.log("happening results");
    } else {
      this.Jsondata['pumps'] = false
      this.disabpro = false
    }

  }

  commselec6() {
    if (this.Jsondata['train'] == true) {
      this.Jsondata['pumps'] = false
      this.Jsondata['pumpes'] = false
    }
  }

  commselec7() {
    if (this.Jsondata['scont'] == true) {
      this.Jsondata['pumps'] = true
      this.disabpro = true
      this.Jsondata['pumpes'] = false
      this.disabess = true


    }
  }
  commselec8() {
    if (this.Jsondata['smartc'] == true) {
      this.Jsondata['smarts'] = true
      this.disabsms = true
      this.Jsondata['pumpes'] = true
      this.disabess = true
      this.Jsondata['pumps'] = false
      this.showMesuggest2 = true
    }

    else {
      this.disabsms = false
      this.Jsondata['pumps'] = false
      this.showMesuggest2 = false
      this.showMesuggest3 = true
    }
  }
  back() {
    this.Router.navigate(['../UserDetails'])
  }

  openModalTable() {
    this.modalTableService.openModal();
  }

  User_guide() {
    this.Router.navigate(['../User_guide'])
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
