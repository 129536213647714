import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTableComponent } from './modal-table.component';

@Injectable({
  providedIn: 'root',
})
export class ModalTableService {

  constructor(private dialog: MatDialog) { }

  openModal() {
    this.dialog.open(ModalTableComponent, {
      width: '1000px',
      minHeight: '800px',
    });
  }

}
