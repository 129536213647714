import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";
import { sumArrayToIndex } from "../utils";
import { IInputTable, createInputTable } from "./InputTable";


const warrantyPricingTable_DE = [0.03, 0.0275, 0.025]
const warrantyPricingTable_NonDE = [0.02, 0.018, 0.017]


export interface IWarrantyPricingTable {
  input: IInputTable;

  de: number;
  nonDe: number;
  de_Total: number;
  nonDe_Total: number;
}

const warrantyPricingTableFormulas: CachedPropertyMethods<IWarrantyPricingTable> = {
  input: p => createInputTable(),

  de: p => warrantyPricingTable_DE[p.input.years - 1],
  nonDe: p => warrantyPricingTable_NonDE[p.input.years - 1],
  de_Total: p => sumArrayToIndex(warrantyPricingTable_DE, p.input.years),
  nonDe_Total: p => sumArrayToIndex(warrantyPricingTable_NonDE, p.input.years),
}


export const createWarrantyPricingTable = (predefinedValues: Partial<IWarrantyPricingTable> = {}) =>
  createCachedPropertiesWithFormula<IWarrantyPricingTable>(warrantyPricingTableFormulas, predefinedValues);
