
import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";
import { createRange } from "../utils";
import { IInputTable, createInputTable } from "./InputTable";


// | DE Pump                                  |                                               |                    |                    |
// |------------------------------------------|-----------------------------------------------|--------------------|--------------------|
// | Percentage risk of occurrence            | 0.1                                           |                    |                    |
// | Energy drift due to Pump mechanical d... | 0.03                                          |                    |                    |
// | Inflation                                | 0.08                                          |                    |                    |
// | Cost of Power                            | 0.05                                          |                    |                    |
// | Do Nothing! Base Case costs for Customer |                                               |                    |                    |
// | 3-Year cost customer could’ve incurred   | Year 1                                        | Year 2             | Year 3             |
// | Annual Energy cost                       | =(P104/(Input!E36+Input!E57)                  | =Q117*($Q$112+1)   | =R117*($Q$112+1)   |
// |                                          |    *(Input!E17+Input!E23+Input!E25))          |                    |                    |
// |                                          |    +((1-R88)*(P104/(Input!E36+Input!E57)      |                    |                    |
// |                                          |    *(Input!E13)))+((1-R88)*(1+0.13)           |   *($Q$114+1)      |   *($Q$114+1)      |
// |                                          |    *(P104/(Input!E36+Input!E57)*(Input!E15))) |                    |                    |
// | Annual Planned Maintenance cost          | =P100                                         | =(Q118*(1+$Q$113)) | =(R118*(1+$Q$113)) |
// | Annual Unplanned Maintenance cost        | =(3%*$P90)+(IF(Input!M12="GBP",$AG$3,         | =(Q119*(1+$Q$113)) | =(R119*(1+$Q$113)) |
// |                                          |     (IF(Input!M12="USD",$AE$3,                |                    |                    |
// |                                          |     (IF(Input!M12="CAD",$AF$3,$AD$3))))))*Q111                     |                    |
// | Annual Operation & Downtime cost:        | =P96+P98                                      | =(Q120*(1+$Q$113)) | =(R120*(1+$Q$113)) |
// | TOTAL Cost                               | =SUM(Q117:Q120)                               | =SUM(R117:R120)    | =SUM(S117:S120)    |


export interface ICostCustomerCouldIncurredTable {
  input: IInputTable;

  totalEnergyCost: number;
  totalPlannedMaintenanceCost: number;
  totalUnplannedMaintenanceCost: number;
  totalOperationAndDowntimeCost: number;

  totalCost: number;

  energyCostWithArmstrongServiceContract: number;
}

const costCustomerCouldIncurredFormulas: CachedPropertyMethods<ICostCustomerCouldIncurredTable> = {
  input: p => createInputTable(),

  totalEnergyCost: p => p.input.energyCost_StatusQuo * createRange(1, p.input.years).sum(considerEnergyCostForNYears),
  totalPlannedMaintenanceCost: p => p.input.plannedMaintenance_StatusQuo * createRange(1, p.input.years).sum(considerInflationForNYears),
  totalUnplannedMaintenanceCost: p => p.input.unplannedMaintenance_StatusQuo * createRange(1, p.input.years).sum(considerInflationForNYears),
  totalOperationAndDowntimeCost: p => p.input.trainingDowntime_StatusQuo * createRange(1, p.input.years).sum(considerInflationForNYears),

  totalCost: p => p.totalEnergyCost + p.totalPlannedMaintenanceCost + p.totalUnplannedMaintenanceCost + p.totalOperationAndDowntimeCost,

  energyCostWithArmstrongServiceContract: p => p.input.energyCost_Armstrong
    * createRange(1, p.input.years).sum(considerEnergyCostForNYearsWithArmstrongServiceContract),
}

export const createCostCustomerCouldIncurredTable = (predefinedValues: Partial<ICostCustomerCouldIncurredTable> = {}) =>
  createCachedPropertiesWithFormula<ICostCustomerCouldIncurredTable>(costCustomerCouldIncurredFormulas, predefinedValues);

const energyDriftDueToPumpMechanicalDegradation = 0.03;
const inflation = 0.08;
const costOfPower = 0.05;

const energyDriftDueToPumpMechanicalDegradationWithPumpManager = energyDriftDueToPumpMechanicalDegradation * 0.7;
const inflationWithPumpManager = 0;
const costOfPowerWithPumpManager = 0.05;

const considerEnergyCostForNYears = (year) => Math.pow(energyDriftDueToPumpMechanicalDegradation + 1, year - 1) * Math.pow(costOfPower + 1, year - 1);

const considerInflationForNYears = (year) => Math.pow(inflation + 1, year - 1);

const considerEnergyCostForNYearsWithArmstrongServiceContract = (year) =>
  Math.pow(energyDriftDueToPumpMechanicalDegradationWithPumpManager + 1, year - 1)
  * Math.pow(inflationWithPumpManager + 1, year - 1)
  * Math.pow(costOfPowerWithPumpManager + 1, year - 1);
