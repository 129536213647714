
  <div id="header" class="btn-div">
    <button *ngIf="hasHistory" style="display: inline-block;" class="blue-button5" (click)="back()" >Back</button>
    <button style="display: inline-block;" id="btnPrint1" class="blue-button buttongen"
      (click)="captureScreen()">Generate PDF</button>
    <br>
    <br>

    <div id="contentToConvert" style="border: 5px solid black; margin-left: 200px; margin-right: 200px;">

      <p>
        <a name="_Hlk91775182"></a>
        <strong></strong>
      </p>
      <p align="center">
        <strong></strong>
      </p>
      <!-- <p align="center">
    <p class="fig1">
        <img
            width="604"
            height="207"

            src="assets/images/Armstrong1.jpg"
            alt="A picture containing text

    Description automatically generated"
        />
    </p> -->

      <p align="center">
        <strong> -: DESIGN ENVELOPE VALUE ATTRICULATION :-</strong>
      </p>
      <p align="center">
        <strong>USER GUIDE</strong>
      </p>
      <br>
      <br>
      <br>
      <p align="center">
        Publish Date: 01 January 2022
      </p>
      <p align="center">
        Project type:
      </p>
      <p>
        <strong><u></u></strong>
      </p>
      <p>
        <strong><u></u></strong>
      </p>
      <p>
        <strong><u>Table of Contents</u></strong>
        <strong> :</strong>
      </p>
      <p>
        <strong>1.0</strong>
        <strong> <u>Overview</u></strong>
      </p>

      <p>
        Design envelope value articulation is a Web-based Browser Application,
        which allow users to calculate energy savings for energy upgrade projects.
        It provides an approximate energy saving report in a pdf format. <strong></strong>
      </p>
      <br>

      <p>
        <strong></strong>
      </p>
      <p>
        <strong>2.0</strong>
        <strong> <u>Organization of Manual</u></strong>
      </p>
      <p>
        <strong>Points to Note before getting started</strong>
      </p>
      <p>
        1. Open online with the Chrome browser for better visualization.
      </p>
      <p>
        2. Do not use browser refresh button.
      </p>
      <p>
        3. It is recommended to change the page size and margin before printing the
        report
      </p>
      <br>
      <br>
      <p>
        <strong>Tool Summary :</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        Design envelope value articulation tool consists of four tabs: Project
        details, Project life-
      </p>
      <p>
        -cycle cost, Fault/Degradation Definitions and Assessment Report.
      </p>
      <p class="fig">
        <img width="604" height="207" src="assets/fig1.PNG" alt="A picture containing text

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.1 DEVA Tool</strong>
      </p>
      <br>
      <br>
      <p>
        In <strong>project details</strong> tab, User needs to add details of the
        project such as Type of Building (existing/new), Project Name, Project
        Address, ADEPT Quote Ref No. and User details (Name, mail-Id) etc.
      </p>
      <p class="fig">
        <img width="604" height="273" src="assets/fig2.PNG" alt="Graphical user interface, text, application, email

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.2 Project Details</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        Select the Building Type in same page.
      </p>
      <p class="fig">
        <img width="601" height="129" src="assets/fig3.PNG" alt="A picture containing text

Description automatically generated" />
        <strong></strong>
      </p>
      <p>
        <strong>Fig.1.3 Project Details</strong>
      </p>
      <p>
        In <strong>project lifecycle cost</strong> tab, User needs to give the cost
        values for Inquiry complaint first cost (£),
      </p>
      <p>
        Inquiry complaint annual energy cost (£), Inquiry complaint annual
        maintenance cost (£), Value
      </p>
      <p>
        add bid first cost (£), Value add bid annual energy cost (£) and Value add
        bid annual maintenance cost (£).
      </p>
      <p class="fig">
        <img width="603" height="272" src="assets/fig4.PNG" alt="Graphical user interface, text, application

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.4 Project lifecycle cost.</strong>
      </p>
      <p>
        <strong>NOTE</strong>
        :
        <em>
          If you don’t provide any new input values, the tool will consider the
          given default values in the input fields. And the input/default values
          will be utilized for evaluating the monetary impact of Armstrong
          services.
        </em>
      </p>
      <p>
        <em></em>
      </p>
      <p>
        <em></em>
      </p>
      <p>
        <em></em>
      </p>
      <p>
        <em></em>
      </p>
      <p>
        <em> </em>
      </p>
      <p>
        Also in the same tab, User has to choose the <strong>value-added services</strong> as well.
      </p>
      <p>
        Like – if you have chosen *<strong>New Building</strong>* in project
        details page, then you can see 3 checkboxes as:
      </p>
      <ul>
        <li>
          Smart start-up (auto selects)
        </li>
        <li>
          Pump manager professional
        </li>
        <li>
          Smart commissioning
        </li>
      </ul>
      <p class="fig">
        <img width="604" height="181" src="assets/fig5.PNG" alt="Graphical user interface, text, application

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.5 Project lifecycle cost.</strong>
      </p>
      <p>
        – if you have chosen *<strong>Existing Building</strong>* in project
        details page, then you can see 2 checkboxes as:
      </p>
      <ul>
        <li>
          Smart start-up
        </li>
        <li>
          Pump manager professional
        </li>
      </ul>
      <p class="fig">
        <img width="604" height="172" src="assets/fig6.PNG" alt="Graphical user interface, text, application

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.6 Project lifecycle cost.</strong>
      </p>
      <p>
        In <strong>fault/degradation definitions</strong> tab, User has to enter
        the expected/observed instances for some given fault types such as: pump in
        hand(energy/maintenance), dead heading(energy/maintenance) …etc and find
        the dynamic aggregated savings (%) of the value-added services you have
        selected previously in project lifecycle cost page.
      </p>
      <p class="fig">
        <img width="603" height="283" src="assets/fig7.PNG" alt="Graphical user interface, application

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.7 Fault/Degradation definitions.</strong>
      </p>
      <p>
        In <strong>assessment report</strong> tab, User can see the summarised
        report that contains-
      </p>
      <p>
        · Services &amp; Parts value and benefits (offerings value summary),
      </p>
      <p class="fig">
        <img width="601" height="313" src="assets/fig8.PNG" alt="Text

Description automatically generated with low confidence" />
      </p>
      <p>
        <strong>Fig.1.8 Assessment Report.</strong>
      </p>
      <p>
        · Project assessment selection details (value-added/compliant selections by
        User),
      </p>
      <p class="fig">
        <img width="604" height="182" src="assets/fig8_1.PNG" alt="Graphical user interface, application

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.1.9 Assessment Report.</strong>
      </p>
      <p>
        · Project assessment selection value &amp; benefits (Design Envelope saving
        summary of energy/maintenance) for User selected services: PM Professional,
        Smart Start-up, Smart Commissioning),
      </p>
      <p class="fig">
        <img width="604" height="481" src="assets/fig9.PNG" alt="Table

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.2.0 Assessment Report.</strong>
      </p>
      <p>
        · Services &amp; Parts comparative savings summaries.
      </p>
      <p>
        This is of two types-
      </p>
      <p>
        1) Energy &amp; Maintenance expenditure comparison
      </p>
      <ul>
        <li>
          10yr energy expenditure graph
        </li>
      </ul>
      <p class="fig">
        <img width="681" height="398" src="assets/fig10.PNG" alt="Graphical user interface

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.2.1 Assessment Report.</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <ul>
        <li>
          10yr maintenance expenditure graph
        </li>
      </ul>
      <p class="fig">
        <img width="700" height="252" src="assets/fig11.PNG" alt="Chart, bar chart

Description automatically generated" />
      </p>
      <p>
        <strong> Fig.2.2 Assessment Report.</strong>
      </p>
      <ul>
        <li>
          10yr total Op-Ex bar-graph
        </li>
      </ul>
      <p class="fig">
        <img width="699" height="251" src="assets/fig12.PNG" alt="Chart, bar chart

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.2.3 Assessment Report.</strong>
      </p>
      <p>
        2) Service &amp; Parts comparative offering ROI comparison
      </p>
      <p>
        · 10yr total Op-Ex line-graph
      </p>
      <p class="fig">
        <img width="700" height="432" src="assets/fig13.PNG" alt="Chart, line chart

Description automatically generated" />
      </p>
      <p>
        <strong>Fig.2.4 Assessment Report.</strong>
      </p>


    </div>
  </div>
