import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { ProcessserviceService } from '../processservice.service';



@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  firstname: string;
  lastname: string;
  emailid: string;
  message: string;





  constructor(private Appcomp: AppComponent, private Router: Router, private prservice: ProcessserviceService) {
    // this.contactForm = fb.group({
    //   'contactFormName': ['', Validators.required],
    //   'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
    //   'contactFormSubjects': ['', Validators.required],
    //   'contactFormMessage': ['', Validators.required],
    //   'contactFormCopy': [''],
    // });
  }

  // onSubmit() {
  //   this.connectionService.sendMessage(this.contactForm.value).subscribe(() => {
  //     alert('Your message has been sent.');
  //     this.contactForm.reset();
  //     this.disabledSubmitButton = true;
  //   }, error => {
  //     console.log('Error', error);
  //   });
  // }

  ngOnInit(): void {
  }
  //   save(){
  //     Swal.fire({
  //       title: 'Do you want to save the changes?',
  //       showDenyButton: true,
  //       showCancelButton: true,
  //       confirmButtonText: 'Save',
  //       denyButtonText: `Don't save`,
  //     }).then((result) => {
  //       /* Read more about isConfirmed, isDenied below */
  //       if (result.isConfirmed) {
  //         Swal.fire('Saved!', '', 'success')
  //       } else if (result.isDenied) {
  //         Swal.fire('Changes are not saved', '', 'info')
  //       }
  //     })
  //   }

  // Submit(){
  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       confirmButton: 'btn btn-success',
  //       cancelButton: 'btn btn-danger'
  //     },
  //     buttonsStyling: false
  //   })

  //   swalWithBootstrapButtons.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Send Request!',
  //     cancelButtonText: 'No, cancel!',
  //     reverseButtons: true
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       swalWithBootstrapButtons.fire(
  //         'Requested!',
  //         'Your request has been submited.',
  //         'success'
  //       )
  //     } else if (
  //       /* Read more about handling dismissals below */
  //       result.dismiss === Swal.DismissReason.cancel
  //     ) {
  //       swalWithBootstrapButtons.fire(
  //         'Cancelled',
  //         'Your inputs are safe :)',
  //         'error'
  //       )
  //     }
  //   })
  // }

  // rout(){
  //   this.Router.navigate(['http://localhost:4201'])
  // }

  onsubmit(val: any) {
    this.prservice.sendemail(this.firstname, this.lastname, this.emailid, this.message).subscribe(
      data => {
        console.log(data)
      })
    this.prservice.sendemailuser(this.firstname, this.lastname, this.emailid).subscribe(
      subject => {
        console.log(subject)
      })
    console.warn(val);
    var from = document.getElementById("email")
  }
}
