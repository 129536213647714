<div class="container-fluid navbar navbar-default header">
  <div class="navbar-header">

    <span class="navbar-brand header-text">DEVA Services Assessment Tool</span>
  </div>

  <link rel="stylesheet" type="text/css"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css">
  <div class="href1">
    <a href="https://armstrongfluidtechnology.com/en/help-and-support/email-us" target="_blank" rel="noopener"><i
        class="fa fa-user-circle-o" aria-hidden="true"
        style="margin-right: 12px;  color: white; font-size: 18px; font-weight: bold ;">Contact Us</i></a>
    <a *ngIf="isLoggedIn$ | async" (click)="logout()" href="javascript:void(0)">
      <i class="fa fa-sign-out" aria-hidden="true"
        style="margin-right:5px; margin-top:10px; color: white; font-size: 18px; font-weight: bold ;">
        Logout
      </i>
    </a>

    <a *ngIf="!(isLoggedIn$ | async)" [href]="signUpLink">
      <i class="fa fa-sign-in" aria-hidden="true"
        style="margin-right:5px; margin-top:10px; color: white; font-size: 18px; font-weight: bold ;">
        Sign-Up
      </i>
    </a>

    <!--also this href-link works--<a class="logout" align="right" style="margin-left:835px; margin-bottom:-10px; border: 1px solid white;text-align: center; width: 5%;" href="./logout">Logout</a>-->
  </div>

  <span class="nav navbar-nav navbar-right">
    <img src="assets/armstrong_logo.png" alt="Company Logo">
  </span>
</div>


  <!-- <img src="assets/fig17.PNG"> -->

<div *ngIf="errorMessage" class="errortext">

</div>

<!-- <div class="footersample">
  <app-footer class="ng-tns-c15-6"><div class="footer-div">
<div class="test1">
<ul class="footer-links">
<li class="footer-link">
<a href="https://armstrongfluidtechnology.com" id="footer-link-0" target="_blank">Armstrong Fluid Technology</a>
</li>
</ul>
</div>
<div class="test2">
<a href="http://twitter.com/ArmstrongFT" target="_blank"><img alt="Icon Twitter" src="https://armstrongfluidtechnology.com:443/~/media/images/icons/footer/icon_twitter.png"></a>
<a href="http://www.linkedin.com/company/armstrong-fluid-technology" target="_blank"><img alt="Icon Linkedin" src="https://armstrongfluidtechnology.com:443/~/media/images/icons/footer/icon_linkedin.png"></a>
<a href="http://www.youtube.com/user/ArmstrongFT" target="_blank"><img alt="Icon Youtube" src="https://armstrongfluidtechnology.com:443/~/media/images/icons/footer/icon_youtube.png"></a>
<a href="https://www.facebook.com/ArmstrongFluidTechnology/" target="_blank"><img alt="Icon Facebook" src="https://armstrongfluidtechnology.com:443/~/media/images/icons/footer/icon_facebook.png"></a>
<a href="https://www.flickr.com/photos/armstrongintegrated/collections/" target="_blank"><img alt="Icon Flickr" src="https://armstrongfluidtechnology.com:443/~/media/images/icons/footer/icon_flickr.png"></a>
</div>

</div>
<hr class="fotterline">
<div class="footer-div">
<div class="test1">
<ul class="footer-links2">
<li class="footer-link">
<a href="/terms-conditions" id="footer-link-0" target="_blank">Terms &amp; Conditions</a>
</li>
<li class="footer-link">
<a href="http://armstrongfluidtechnology.com/en/legal/privacy" id="footer-link-0" target="_blank">Privacy</a>
</li>
<li class="footer-link">
<a href="/contactus" id="footer-link-0" target="_blank">Contact Us</a>
</li>
</ul>
</div>
<div class="test2">
<span class="copyright">© 2020 Armstrong Fluid Technology</span>
</div>
</div></app-footer>
</div> -->
<router-outlet></router-outlet>

<!-- <li>
<button class="blue-button" (click)="popup()">cont</button><br><br>
</li> -->
