import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";
import { round } from "../utils";
import { IApmServiceContractQuoteTable, createApmServiceContractQuoteTable } from "./ApmServiceContractQuoteTable";
import { ICostCustomerCouldIncurredTable, createCostCustomerCouldIncurredTable } from "./CostCustomerCouldIncurred";
import { IInputTable, createInputTable } from "./InputTable";


export interface IPrintOutput {
  input: IInputTable;
  apmServiceContractQuoteTable: IApmServiceContractQuoteTable;
  costCustomerCouldIncurredTable: ICostCustomerCouldIncurredTable;

  energyCost_StatusQuo: number; // energy1
  plannedMaintenance_StatusQuo: number; // planMaint
  unplannedMaintenance_StatusQuo: number; // unplanMaint
  trainingDowntime_StatusQuo: number; // downtime

  energyCost_Armstrong: number; // energy2
  apmServiceContract_Armstrong: number; // serContract

  total_StatusQuo: number; // contStatusQuo
  total_Armstrong: number; // serviceCont

  operatingSavingsPercent: number; // agrContSave
  operatingSavings: number;

}


const printOutputFormulas: CachedPropertyMethods<IPrintOutput> = {
  input: p => createInputTable(),
  apmServiceContractQuoteTable: p => createApmServiceContractQuoteTable(),
  costCustomerCouldIncurredTable: p => createCostCustomerCouldIncurredTable(),

  energyCost_StatusQuo: p => Math.round(p.costCustomerCouldIncurredTable.totalEnergyCost),
  plannedMaintenance_StatusQuo: p => Math.round(p.costCustomerCouldIncurredTable.totalPlannedMaintenanceCost),
  unplannedMaintenance_StatusQuo: p => Math.round(p.costCustomerCouldIncurredTable.totalUnplannedMaintenanceCost),
  trainingDowntime_StatusQuo: p => Math.round(p.costCustomerCouldIncurredTable.totalOperationAndDowntimeCost),
  energyCost_Armstrong: p => Math.round(p.costCustomerCouldIncurredTable.energyCostWithArmstrongServiceContract),

  apmServiceContract_Armstrong: p => Math.round(p.apmServiceContractQuoteTable.finalQuotePriceDiscountedAndAdjusted),

  total_StatusQuo: p => p.energyCost_StatusQuo
    + p.plannedMaintenance_StatusQuo
    + p.unplannedMaintenance_StatusQuo
    + p.trainingDowntime_StatusQuo,

  total_Armstrong: p => p.energyCost_Armstrong + p.apmServiceContract_Armstrong,

  operatingSavingsPercent: p => round(((p.total_StatusQuo - p.total_Armstrong) / p.total_StatusQuo) * 100, 2),
  operatingSavings: p => p.total_StatusQuo - p.total_Armstrong,
};


export const createPrintOutputTable = (predefinedValues: Partial<IPrintOutput> = {}) =>
  createCachedPropertiesWithFormula<IPrintOutput>(printOutputFormulas, predefinedValues);
