import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProcessserviceService } from '../processservice.service';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  changetype: boolean = true;
  visible: boolean = true;
  credid: string = "@armstrongfluidtechnology.com"
  credpass: string = ""
  signUpLink: string = environment.baseUrl + "/sign-up";
  message: string;
  paramValue: string;

  constructor(
    private _Processservice: ProcessserviceService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.queryParamMap.get('code');
    if (code) {
      this.authService.authByADCode(code);
    }
  }

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  credentialverify() {
    if (!this.credid.includes('@')) {
      this.credid = this.credid + "@armstrongfluidtechnology.com"
    }
    if (this.credid.includes('@armstrongfluidtechnology.com')) {
      this._Processservice.authenticate(this.credid, this.credpass).subscribe(response => {
        console.log("Authenticate Response")
        console.log(response['url'])
        console.log(Object.keys(response))
        if (response['url']) {

          window.location.href = response['url']
        }
      })
    }
    else {
      this.message = "Only Armstrong domin user are allowed to login."
    }
  }

}
