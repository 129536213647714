<h3>table name: {{name}}</h3>

<div *ngFor="let key of propertyKeys">
  {{ key }}:

  <input *ngIf="getType(tableObject[key])==='boolean'" type="checkbox" [(ngModel)]="tableObject[key]" [name]="key"
    style="height: 20px;">
  <input *ngIf="getType(tableObject[key]) ==='number'" type="number" [(ngModel)]="tableObject[key]" [name]="key"
    style="height: 20px;">
  <input *ngIf="getType(tableObject[key]) ==='string'" type="text" [(ngModel)]="tableObject[key]" [name]="key"
    style="height: 20px;">
</div>
