import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CalculationViewComponent } from './calculation-view/calculation-view.component';
import { CostEvaluationComponent } from './cost-evaluation/cost-evaluation.component';
import { LoginComponent } from './login/login.component';
import { ReportComponent } from './report/report.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserGuideComponent } from './user-guide/user-guide.component';

const routes: Routes = [
  // { path: '**', redirectTo:'http://localhost:4201/'},

  {
    path: '',
    redirectTo: 'UserDetails',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },

  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'UserDetails', component: UserDetailsComponent },
      { path: 'calculation-view', component: CalculationViewComponent },
      { path: 'report', component: ReportComponent },
      { path: 'costeval', component: CostEvaluationComponent },
      { path: 'User_guide', component: UserGuideComponent },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
