<div class="saved-reports" id="saved-reports">
  <div class="list-title">
    <h3 style="float:unset">SAVED REPORTS</h3>
  </div>
  <div class="list-content">
    <table class="list-content-table">
      <tr class="list-content-table-header">
        <th style="width: 20px;">Id</th>
        <th>Project Name</th>
        <th>Created At</th>
        <th>Created By</th>
        <th>View</th>
      </tr>
      <tr *ngFor="let report of reports">
        <td style="width: 20px;">{{report.id}}</td>
        <td>{{report.displayName}}</td>
        <td style="min-width:85px">{{report.createdAt.replace('T', ' ')}}</td>
        <td>{{report.createdBy}}</td>
        <td><button class="blue-button1" (click)="viewReport(report.id)">Open</button></td>
      </tr>
    </table>
  </div>
</div>
