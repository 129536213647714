
  <div  class="top-bar">
    <button class="blue-button1" (click)="openModalTable()">Open Report</button>
    <button class="blue-button1" (click)="saveAll()">Save Report</button>
    <a class="blue-button1" [routerLink]="['/User_guide']" target="_blank">User-Guide</a>
  </div>

  <br><br>

  <div id="form" class="userBlock" *ngIf="userbody">
    <h5 style="display: inline-block;">Please Fill Project Details Below</h5>
    <br>
    <label style="font-weight: bolder;">Note: All Fields with an asterisk(<label class="mark">*</label>) are
      mandatory</label>
    <br><br>
    <!-- <div id="liveAlertPlaceholder"></div> -->
    <label class="labl">Email-Address:<label class="mark">*</label></label>
    <input required type="email" class="inputmail" id="liveAlertemail" [(ngModel)]="Jsondata['email']">
    <div *ngIf="!Jsondata['email']" class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <div>
        Please fill this email input field !!!
      </div>
    </div>
    <br>
    <label class="labl">User Name:<label class="mark">*</label></label>
    <input type="text" minlength="5" class="inputmail" [(ngModel)]="Jsondata['username']">
    <div *ngIf="!Jsondata['username']" class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <div>
        Please fill this User Name input field !!!
      </div>
    </div>
    <br>
    <label class="labl">Project Name:<label class="mark">*</label></label>
    <input type="text" class="inputmail" [(ngModel)]="Jsondata['prname']">
    <div *ngIf="!Jsondata['prname']" class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <div>
        Please fill this Project Name input field !!!
      </div>
    </div>
    <br>
    <label class="labl">Project Address:<label class="mark">*</label></label>
    <input type="text" class="inputmail" [(ngModel)]="Jsondata['praddress']">
    <div *ngIf="!Jsondata['praddress']" class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <div>
        Please fill this Project Address input field !!!
      </div>
    </div>
    <br>
    <label class="labl">ADEPT Quote Ref No.:<label class="mark">*</label></label>
    <input type="text" class="inputmail" [(ngModel)]="Jsondata['adept']">
    <div *ngIf="!Jsondata['adept']" class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
        class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </svg>
      <div>
        Please fill this ADEPT Quote input field !!!
      </div>
    </div>
    <br>
    <label class="labl">Building Type:<label class="mark">*</label></label>
    <select class="detailtab classic" [(ngModel)]="Jsondata['Projecttype']">
      <option value="existingbuilding">Existing Building</option>
      <option value="newbuilding" selected>New Building</option>
    </select>
    <br>

    <label class="labl">Country:<label class="mark">*</label></label>
    <div class="countrysel">

      <select class="detailtab classic" [ngModel]="Jsondata['cntry']" id="country"
        (ngModelChange)="onCountrySelect($event)">
        <option selected>Select Country</option>
        <option *ngFor="let countries of country" value="{{countries.id}}">{{countries.name}}</option>
      </select>
      <br><br><br>

      <select [ngModel]="Jsondata['sym']" (ngModelChange)="onCurrencySelect($event)" class="detailtab classic" id="mycurrency">
        <option *ngFor="let currency of currenciesFilteredByCountry" [ngValue]="currency.symbol">
          {{currency.symbol}}
        </option>
      </select>
      <br>

    </div>

    <div *ngIf="!Jsondata['cntry']">
      <td colspan="4"><input class="not1" (click)="open(mymodal)" type="button" value="*** Alert Message ***"></td>
    </div>

    <br><br>
    <button class="blue-button" (click)='rout()'>Next</button>
    <br><br>

  </div>



  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Instructions:</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>


    <div class="modal-body">
      DEVA services tool:
      <br><br>
      * There are multiple Teritories/Countries options in the "Country" dropdown, which you might have missed to
      select.<br><br>
      * The country + currency selection is required for which you will be able to see the currency conversion according
      to Your
      locality.<br><br>
      **If you do not select any country for now it will show "Null" at the report page**.
      <br><br>
      We highly recommend to select any of your country and currency type to see the articulated differences.
      <br>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>



<router-outlet></router-outlet>
