import { Component, Input, OnInit } from "@angular/core";
import { CachedProperties } from "../formulas/proxy";

@Component({
  selector: 'app-display-formula-table',
  templateUrl: './display-formula-table.component.html',
  styleUrls: ['./display-formula-table.component.css']
})
export class DisplayFormulaTableComponent implements OnInit {

  @Input() name: string;

  @Input() tableObject: any;

  propertyKeys: string[];

  constructor() {

  }

  ngOnInit(): void {
    if (this.tableObject && this.tableObject instanceof CachedProperties) {
      this.propertyKeys = Object.keys(this.tableObject.self.methods);
    }
  }

  getType(value: any) {
    return typeof value;
  }

}
