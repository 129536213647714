import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessserviceService } from '../processservice.service';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTableService } from '../modal-table/modal-table.service';
import { FormSaverService } from '../services/formsaver.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit {
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  public Jsondata: object = {
    'email': "demo@armstrongfluidtechnology.com", 'username': "Demo User", 'prname': "Demo Project", 'praddress': "1st Street, Brack",
    'adept': "#Dmo123", 'Projecttype': "newbuilding", 'cntry': "", 'sym': ""
  }
  public userbody = true
  newWindow: Window;
  closeResult: string;
  selectedTeam: string;
  formModal: any;

  country: any = [];
  currenciesFilteredByCountry: any[] = [];
  value: any;

  constructor(
    private modalService: NgbModal,
    private Router: Router,
    private prservice: ProcessserviceService,
    public formSaver: FormSaverService,
    private modalTableService: ModalTableService,
  ) { }

  ngOnInit(): void {
    this.country = this.prservice.country();

    this.formSaver.formsData$.subscribe((data) => {
      this.restoreFormData(data.userInfo);
    });
  }

  openModalTable() {
    this.modalTableService.openModal();
  }

  restoreFormData(formData: object) {
    if (formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          this.Jsondata[key] = formData[key];
        }
      }
      this.onCountrySelect(this.Jsondata['cntry'])
      this.onCurrencySelect(this.Jsondata['sym'])

      this.processForm();
    }
  }

  saveAll() {
    this.processForm();
    this.formSaver.saveAll();
  }

  processForm() {
    this.prservice.userinfo = this.Jsondata
    localStorage.setItem('details', JSON.stringify(this.prservice.userinfo))
  }

  rout() {
    this.processForm();

    if (!this.Jsondata['username'] || !this.Jsondata['email'] || !this.Jsondata['prname'] || !this.Jsondata['praddress'] || !this.Jsondata['adept']) {
      this.Router.navigate(['../login'])
    }
    else {
      this.Router.navigate(['../costeval']);
    }
  }

  User_guide() {
    this.Router.navigate(['../User_guide']);
  }

  onCountrySelect(country) {
    this.Jsondata['cntry'] = country;

    this.currenciesFilteredByCountry = this.prservice.currency().filter(e => e.id == country);
    if (this.currenciesFilteredByCountry.length > 0) {
      let selectedCurrency = this.currenciesFilteredByCountry.find(e => e.symbol == this.Jsondata['sym']);
      selectedCurrency = selectedCurrency ? selectedCurrency : this.currenciesFilteredByCountry[0];
      this.Jsondata['sym'] = selectedCurrency.symbol;
      localStorage.setItem('curncy', JSON.stringify(selectedCurrency.sym))
    }

  }

  onCurrencySelect(currencySymbol: string) {
    this.Jsondata['sym'] = currencySymbol;

    var testing = currencySymbol
    localStorage.setItem('curncis', JSON.stringify(testing));

    var arraytxt = currencySymbol.split("-");
    localStorage.setItem('curncy', JSON.stringify(arraytxt[1]));
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
