import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { ProcessserviceService } from "../processservice.service";

@Injectable({
  providedIn: 'root'
})
export class FormSaverService {
  private formsData = new BehaviorSubject<{
    userInfo?: object,
    costInfo?: object,
    calculationViewInfo?: object,
  }>({});

  public formsData$ = this.formsData.asObservable();

  constructor(
    private prservice: ProcessserviceService,
  ) { }

  private saveForm(reportData: any) {
    const url = `${environment.backendUrl}/api/forms/save`;

    const projectName = "DEVA-ServicesAssessmentTool"
    const username = localStorage.getItem('username');
    const displayName = reportData?.userInfo?.prname ?? 'Empty';

    const payload = {
      projectName,
      username,
      displayName,
      formsData: reportData,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(data => {
        console.log(data);
        alert('Report saved successfully');
      })
      .catch((err) => {
        console.log(err);
        alert('Error while saving report');
      });
  }

  public saveAll() {
    const localStorageData = this.getLocalStorageData();

    const objToSave = {
      userInfo: this.prservice.userinfo,
      costInfo: this.prservice.costinfo,
      calculationViewInfo: this.prservice.calculationViewInfo,
      localStorageData: localStorageData,
    }

    this.saveForm(objToSave);
  }

  public restoreAll(formsData: any) {
    if (formsData) {
      this.prservice.userinfo = formsData.userInfo;
      this.prservice.costinfo = formsData.costInfo;
      this.prservice.calculationViewInfo = formsData.calculationViewInfo;
      this.setLocalStorageData(formsData.localStorageData);

      this.formsData.next(formsData);
    }
  }


  private getLocalStorageData(): { [key: string]: any } {
    const localStorageData: { [key: string]: any } = {};
    const excludedFields = ['access_token', 'username', 'formSaved'];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!excludedFields.includes(key)) {
        localStorageData[key] = localStorage.getItem(key);
      }
    }
    return localStorageData;
  }

  private setLocalStorageData(data: { [key: string]: any }) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        localStorage.setItem(key, data[key]);
      }
    }
  }

}
