import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProcessserviceService {
  public userinfo: object = {};
  public costinfo: object = {};
  public calculationViewInfo: object = {};
  public objdata;
  public objdata1;
  public objdata2;
  public objdata3;
  public objdata4;
  public objdata5;
  public objdata6;
  public objdata7;
  public objdata_old;
  public objdata1_old;
  public objdata2_old;
  public objdata_nw;
  public objdata1_nw;
  public objdata2_nw;
  public objdata3_nw;
  public objdata4_nw;
  public objdata5_nw;
  public objdata6_nw;
  public objdata7_nw;
  public objdata_old_1;
  public objdata1_old_1;
  public objdata2_old_1;
  public objdata_nw_1;
  public objdata1_nw_1;
  public objdata2_nw_1;
  public objdata3_nw_1;
  public objdata4_nw_1;
  public objdata5_nw_1;
  public objdata6_nw_1;
  public objdata7_nw_1;
  public project = "old"
  public Projecttype = "newbuilding"
  public cheeck = ""
  public usermail = ""
  public selectedValuecrncy = [];


  constructor(private httpclient: HttpClient) { }


  authenticate(user_id, pass) {
    this.usermail = user_id
    const redirectUrl = environment.baseUrl + "/login";
    return this.httpclient.post("https://qa.pumpmanager.io/api/user/ad/login",
      {
        "redirectUrl": redirectUrl,
        "applicationType": "AZURE.REAT.LOCAL"
      }, { headers: { "Content-Type": "application/json" } })
  }

  sendemail(firstname, lastname, emailid, message): Observable<any> {
    const httpOptions = {

      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })

    };

    var data = {
      "from": "ArmstrongCloud@armstrongfluidtechnology.com",
      "to": "ArmstrongCloud@armstrongfluidtechnology.com",
      "cc": "bppattanaik@armstrongfluidtechnology.com , tasiwaju@armstrongfluidtechnology.com, ArmstrongCloud@armstrongfluidtechnology.com",
      "topic": "DEVA Service Tool Sign-Up Request !!!",
      "content": "Hi Team,<br><br> User with below details has requested access for DEVA Services.<br><br> Full Name: " + firstname + " " + lastname + ",<br> Email-id of user: " + emailid + ",<br>Received message: " + message + ",<br><br> Please review the request.<br><br><br> Thanks,<br> Armstrong Cloud Team."
    }

    // var url= "https://service.pumpmanager.io/api/sendDevMail"
    var url = "https://service.pumpmanager.io/api/sendProdMail"


    return this.httpclient.post(url, data, httpOptions);
  }

  sendemailuser(firstname, lastname, emailid): Observable<any> {
    const httpOptions = {

      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })

    };

    var subject = {
      "from": "ArmstrongCloud@armstrongfluidtechnology.com",
      "to": emailid,
      "cc": "ArmstrongCloud@armstrongfluidtechnology.com, tasiwaju@armstrongfluidtechnology.com",
      "topic": "DEVA Service Tool Sign-Up Request !!!",
      "content": "Hi " + firstname + ",<br><br> Thank you for signing up to DEVA services.<br><br> We are currently reviewing your request and will update the status once your request has been processed.<br><br><br> Thank you,<br> Armstrong Cloud Team."
    }

    // var url= "https://service.pumpmanager.io/api/sendDevMail"
    var url = "https://service.pumpmanager.io/api/sendProdMail"

    return this.httpclient.post(url, subject, httpOptions);
  }


  Valueassign() {
    this.objdata_old = {
      "C1100": 20, "C1200": 25, "C1300": 30, "C1400": "", "C1500": "",
      "C1101": "", "C1201": "", "C1301": "", "C1401": 5, "C1501": "",
      "C1102": "", "C1202": "", "C1302": "", "C1402": "", "C1502": 6,
      "C1103": "", "C1203": "", "C1303": "", "C1403": "", "C1503": "",
      "C1104": "", "C1204": "", "C1304": "", "C1404": "", "C1504": "",
      "C1105": "", "C1205": "", "C1305": "", "C1405": "", "C1505": "",
      "C1106": "", "C1206": "", "C1306": "", "C1406": "", "C1506": "",
      "C1107": 10, "C1207": 10, "C1307": 50, "C1407": "", "C1507": "",
      "C1108": "", "C1208": "", "C1308": "", "C1408": 1, "C1508": "",
      "C1109": "", "C1209": "", "C1309": "", "C1409": "", "C1509": 5,
      "C1110": "", "C1210": "", "C1310": "", "C1410": "", "C1510": "",
      "C1111": 50, "C1211": 10, "C1311": 20, "C1411": "", "C1511": "",
      "C1112": "", "C1212": "", "C1312": "", "C1412": 5, "C1512": "",
      "C1113": "", "C1213": "", "C1313": "", "C1413": "", "C1513": 10,
      "C1114": "", "C1214": "", "C1314": "", "C1414": "", "C1514": "",
      "C1115": "", "C1215": "", "C1315": "", "C1415": "", "C1515": "",
      "C1116": 50, "C1216": 10, "C1316": 15, "C1416": "", "C1516": "",
      "C1117": "", "C1217": "", "C1317": "", "C1417": 5, "C1517": "",
      "C1118": "", "C1218": "", "C1318": "", "C1418": "", "C1518": 8,
      "C1119": 50, "C1219": 15, "C1319": 15, "C1419": "", "C1519": "",
      "C1120": "", "C1220": "", "C1320": "", "C1420": 8, "C1520": "",
      "C1121": "", "C1221": "", "C1321": "", "C1421": "", "C1521": 8,
      "C1122": 30, "C1222": 5, "C1322": 15, "C1422": "", "C1522": "",
      "C1123": "", "C1223": "", "C1323": "", "C1423": 2, "C1523": "",
      "C1124": "", "C1224": "", "C1324": "", "C1424": "", "C1524": 5,
      "C1125": 20, "C1225": 10, "C1325": 15, "C1425": "", "C1525": "",
      "C1126": "", "C1226": "", "C1326": "", "C1426": 2, "C1526": "",
      "C1127": "", "C1227": "", "C1327": "", "C1427": "", "C1527": 3,
      "C1128": 10, "C1228": 6, "C1328": 25, "C1428": "", "C1528": "",
      "C1129": "", "C1229": "", "C1329": "", "C1429": 1, "C1529": "",
      "C1130": "", "C1230": "", "C1330": "", "C1430": "", "C1530": 3,
      "C1131": 10, "C1231": 20, "C1331": 50, "C1431": "", "C1531": "",
      "C1132": "", "C1232": "", "C1332": "", "C1432": 2, "C1532": "",
      "C1133": "", "C1233": "", "C1333": "", "C1433": "", "C1533": 5,
      "C14ag": 30, "C15ag": 51
    }
    this.objdata1_old = {
      "C2100": 20, "C2200": 25, "C2300": 30, "C2400": "", "C2500": "",
      "C2101": "", "C2201": "", "C2301": "", "C2401": 5, "C2501": "",
      "C2102": "", "C2202": "", "C2302": "", "C2402": "", "C2502": 6,
      "C24ag": 5, "C25ag": 6,
    }
    this.objdata2_old = {
      "C3100": 100, "C3200": 5, "C3300": 1, "C3400": "", "C3500": "",
      "C3101": "", "C3201": "", "C3301": "", "C3401": 5, "C3501": "",
      "C3102": "", "C3202": "", "C3302": "", "C3402": "", "C3502": 1,
      "C3103": 100, "C3203": 6, "C3303": 3, "C3403": "", "C3503": "",
      "C3104": "", "C3204": "", "C3304": "", "C3404": 6, "C3504": "",
      "C3105": "", "C3205": "", "C3305": "", "C3405": "", "C3505": 3,
      "C3106": 100, "C3206": "", "C3306": 10, "C3406": "", "C3506": "",
      "C3107": "", "C3207": "", "C3307": "", "C3407": "", "C3507": "",
      "C3108": "", "C3208": "", "C3308": "", "C3408": "", "C3508": 10,
      "C3109": 100, "C3209": "", "C3309": 9, "C3409": "", "C3509": "",
      "C3110": "", "C3210": "", "C3310": "", "C3410": "", "C3510": "",
      "C3111": "", "C3211": "", "C3311": "", "C3411": "", "C3511": 9,
      "C3112": "", "C3212": "", "C3312": "", "C3412": "", "C3512": "",
      "C3113": "", "C3213": "", "C3313": "", "C3413": "", "C3513": "",
      "C3114": "", "C3214": "", "C3314": "", "C3414": "", "C3514": "",
      "C3115": 100, "C3215": 10, "C3315": 3, "C3415": "", "C3515": "",
      "C3116": "", "C3216": "", "C3316": "", "C3416": 10, "C3516": "",
      "C3117": "", "C3217": "", "C3317": "", "C3417": "", "C3517": 3,
      "C3118": 100, "C3218": "", "C3318": 20, "C3418": "", "C3518": 20,
      "C34ag": 21, "C35ag": 46,
    }
    this.objdata3_nw = {
      "C4100": 100, "C4200": 5, "C4300": 1, "C4400": "", "C4500": "",
      "C4101": "", "C4201": "", "C4301": "", "C4401": 5, "C4501": "",
      "C4102": "", "C4202": "", "C4302": "", "C4402": "", "C4502": 1,
      "C4103": 100, "C4203": 6, "C4303": 3, "C4403": "", "C4503": "",
      "C4104": "", "C4204": "", "C4304": "", "C4404": 6, "C4504": "",
      "C4105": "", "C4205": "", "C4305": "", "C4405": "", "C4505": 3,
      "C4106": 100, "C4206": "", "C4306": 10, "C4406": "", "C4506": "",
      "C4107": "", "C4207": "", "C4307": "", "C4407": "", "C4507": "",
      "C4108": "", "C4208": "", "C4308": "", "C4408": "", "C4508": 10,
      "C4109": 100, "C4209": "", "C4309": 9, "C4409": "", "C4509": "",
      "C4110": "", "C4210": "", "C4310": "", "C4410": "", "C4510": "",
      "C4111": "", "C4211": "", "C4311": "", "C4411": "", "C4511": 9,
      "C4112": "", "C4212": "", "C4312": "", "C4412": "", "C4512": "",
      "C4113": "", "C4213": "", "C4313": "", "C4413": "", "C4513": "",
      "C4114": "", "C4214": "", "C4314": "", "C4414": "", "C4514": "",
      "C4115": 100, "C4215": 10, "C4315": 3, "C4415": "", "C4515": "",
      "C4116": "", "C4216": "", "C4316": "", "C4416": 10, "C4516": "",
      "C4117": "", "C4217": "", "C4317": "", "C4417": "", "C4517": 3,
      "C4118": 100, "C4218": "", "C4318": 1, "C4418": "", "C4518": 1,
      "C44ag": 21, "C45ag": 27,
    }

    this.objdata_nw = {
      "C1100": 20, "C1200": 8, "C1300": 10, "C1400": "", "C1500": "",
      "C1101": "", "C1201": "", "C1301": "", "C1401": 2, "C1501": "",
      "C1102": "", "C1202": "", "C1302": "", "C1402": "", "C1502": 2,
      "C1103": "", "C1203": "", "C1303": "", "C1403": "", "C1503": "",
      "C1104": "", "C1204": "", "C1304": "", "C1404": "", "C1504": "",
      "C1105": "", "C1205": "", "C1305": "", "C1405": "", "C1505": "",
      "C1106": "", "C1206": "", "C1306": "", "C1406": "", "C1506": "",
      "C1107": 10, "C1207": 3, "C1307": 17, "C1407": "", "C1507": "",
      "C1108": "", "C1208": "", "C1308": "", "C1408": 0, "C1508": "",
      "C1109": "", "C1209": "", "C1309": "", "C1409": "", "C1509": 2,
      "C1110": "", "C1210": "", "C1310": "", "C1410": "", "C1510": "",
      "C1111": '-', "C1211": "NA", "C1311": "NA", "C1411": "", "C1511": "",
      "C1112": "", "C1212": "", "C1312": "", "C1412": "", "C1512": "",
      "C1113": "", "C1213": "", "C1313": "", "C1413": "", "C1513": "",
      "C1114": "", "C1214": "", "C1314": "", "C1414": "", "C1514": "",
      "C1115": "", "C1215": "", "C1315": "", "C1415": "", "C1515": "",
      "C1116": 50, "C1216": 3, "C1316": 5, "C1416": "", "C1516": "",
      "C1117": "", "C1217": "", "C1317": "", "C1417": 2, "C1517": "",
      "C1118": "", "C1218": "", "C1318": "", "C1418": "", "C1518": 3,
      "C1119": 50, "C1219": 5, "C1319": 5, "C1419": "", "C1519": "",
      "C1120": "", "C1220": "", "C1320": "", "C1420": 3, "C1520": "",
      "C1121": "", "C1221": "", "C1321": "", "C1421": "", "C1521": 3,
      "C1122": 30, "C1222": 2, "C1322": 5, "C1422": "", "C1522": "",
      "C1123": "", "C1223": "", "C1323": "", "C1423": 1, "C1523": "",
      "C1124": "", "C1224": "", "C1324": "", "C1424": "", "C1524": 2,
      "C1125": 20, "C1225": 3, "C1325": 5, "C1425": "", "C1525": "",
      "C1126": "", "C1226": "", "C1326": "", "C1426": 1, "C1526": "",
      "C1127": "", "C1227": "", "C1327": "", "C1427": "", "C1527": 1,
      "C1128": 10, "C1228": 2, "C1328": 8, "C1428": "", "C1528": "",
      "C1129": "", "C1229": "", "C1329": "", "C1429": 0, "C1529": "",
      "C1130": "", "C1230": "", "C1330": "", "C1430": "", "C1530": 1,
      "C1131": 10, "C1231": 7, "C1331": 17, "C1431": "", "C1531": "",
      "C1132": "", "C1232": "", "C1332": "", "C1432": 1, "C1532": "",
      "C1133": "", "C1233": "", "C1333": "", "C1433": "", "C1533": 2,
      "C14ag": 10, "C15ag": 16
    }
    this.objdata1_nw = {
      "C2100": 20, "C2200": 10, "C2300": 20, "C2400": "", "C2500": "",
      "C2101": "", "C2201": "", "C2301": "", "C2401": 2, "C2501": "",
      "C2102": "", "C2202": "", "C2302": "", "C2402": "", "C2502": 2,
      "C2103": 10, "C2203": 3, "C2303": 17, "C2403": "", "C2503": "",
      "C2104": "", "C2204": "", "C2304": "", "C2404": 0, "C2504": "",
      "C2105": "", "C2205": "", "C2305": "", "C2405": "", "C2505": 2,
      "C24ag": 2, "C25ag": 4,
    }
    this.objdata2_nw = {
      "C5520": 20, "C5530": 10, "C5540": 20, "C5550": "", "C5560": "",
      "C5521": "", "C5531": "", "C5541": "", "C5551": 2, "C5561": "",
      "C5522": "", "C5532": "", "C5542": "", "C5552": "", "C5562": 2,

      "C3100": 70, "C3200": 5, "C3300": 1, "C3400": "", "C3500": "",
      "C3101": "", "C3201": "", "C3301": "", "C3401": 4, "C3501": "",
      "C3102": "", "C3202": "", "C3302": "", "C3402": "", "C3502": 0,
      "C3103": 40, "C3203": 6, "C3303": 3, "C3403": "", "C3503": "",
      "C3104": "", "C3204": "", "C3304": "", "C3404": 2, "C3504": "",
      "C3105": "", "C3205": "", "C3305": "", "C3405": "", "C3505": 1,
      "C3106": 40, "C3206": "", "C3306": 10, "C3406": "", "C3506": "",
      "C3107": "", "C3207": "", "C3307": "", "C3407": "", "C3507": "",
      "C3108": "", "C3208": "", "C3308": "", "C3408": "", "C3508": 4,
      "C3109": 70, "C3209": "", "C3309": 9, "C3409": "", "C3509": "",
      "C3110": "", "C3210": "", "C3310": "", "C3410": "", "C3510": "",
      "C3111": "", "C3211": "", "C3311": "", "C3411": "", "C3511": 7,
      "C3112": "", "C3212": "", "C3312": "", "C3412": "", "C3512": "",
      "C3113": "", "C3213": "", "C3313": "", "C3413": "", "C3513": "",
      "C3114": "", "C3214": "", "C3314": "", "C3414": "", "C3514": "",
      "C3115": 50, "C3215": 10, "C3315": 3, "C3415": "", "C3515": "",
      "C3116": "", "C3216": "", "C3316": "", "C3416": 5, "C3516": "",
      "C3117": "", "C3217": "", "C3317": "", "C3417": "", "C3517": 2,
      "C3118": "", "C3218": "", "C3318": "", "C3418": "", "C3518": "",
      "C34ag": 13, "C35ag": 16,
    }

    this.objdata4_nw = {
      "C5100": 100, "C5200": 5, "C5300": 1, "C5400": "", "C5500": "",
      "C5101": "", "C5201": "", "C5301": "", "C5401": 5, "C5501": "",
      "C5102": "", "C5202": "", "C5302": "", "C5402": "", "C5502": 1,
      "C5103": 100, "C5203": 6, "C5303": 3, "C5403": "", "C5503": "",
      "C5104": "", "C5204": "", "C5304": "", "C5404": 6, "C5504": "",
      "C5105": "", "C5205": "", "C5305": "", "C5405": "", "C5505": 3,
      "C5106": 100, "C5206": "", "C5306": 10, "C5406": "", "C5506": "",
      "C5107": "", "C5207": "", "C5307": "", "C5407": "", "C5507": "",
      "C5108": "", "C5208": "", "C5308": "", "C5408": "", "C5508": 10,
      "C5109": "", "C5209": "", "C5309": "", "C5409": "", "C5509": "",
      "C5110": "", "C5210": "", "C5310": "", "C5410": "", "C5510": "",
      "C5111": 100, "C5211": "", "C5311": 9, "C5411": "", "C5511": 9,
      "C5112": "", "C5212": "", "C5312": "", "C5412": "", "C5512": "",
      "C5113": "", "C5213": "", "C5313": "", "C5413": "", "C5513": "",
      "C5114": "", "C5214": "", "C5314": "", "C5414": "", "C5514": "",
      "C5115": 100, "C5215": 10, "C5315": 3, "C5415": "", "C5515": "",
      "C5116": "", "C5216": "", "C5316": "", "C5416": 10, "C5516": "",
      "C5117": "", "C5217": "", "C5317": "", "C5417": "", "C5517": 3,
      "C5118": 100, "C5218": "", "C5318": 1, "C5418": "", "C5518": 1,
      "C54ag": 21, "C55ag": 27,
    }
    this.objdata5_nw = {
      "C5143": 1,


      //             cad     |     usd      |      inr      |         indo      |    malaysia    |   thai         | SINGAPORE DOLLAR   | TAIWAN DOLLAR | SOUTH KOR    |   EAN     |     VIETNAMESE  |  BRAZILIAN |    EURO    |    COLOMB    |   COSTA  | PERUVIAN   | BAHRAINI
      //     =============================================================================================================================================================================================================================================================
      "C5119": 1560, "C5620": 1212.51, "C5188": 96556.53, "C5624": 17876613.24, "C5692": 5377, "C5657": 42993.23, "C5727": "", "C5728": "", "C5729": "", "C5772": "", "C5773": "", "C5774": "", "C5775": "", "C5776": "", "C5219": "",
      "C5120": 300, "C5610": 233.30, "C5189": 18568.56, "C5625": 3437810.24, "C5693": 1034, "C5627": 8267.93, "C5730": "", "C5731": "", "C5732": "", "C5777": "", "C5778": "", "C5779": "", "C5780": "", "C5781": "", "C5220": "",
      "C5142": 750, "C5611": 583.26, "C5612": "",
      "C5221": 450, "C5621": 349.76, "C5190": 27848.41, "C5707": "", "C5708": 1551, "C5709": "",

      "C5160": 4680, "C5613": 3639.54, "C5191": 287675.08, "C5658": 53651754, "C5694": 16129.73, "C5659": 128979.70, "C5733": "", "C5734": "", "C5735": "", "C5782": "", "C5783": "", "C5784": "", "C5785": "", "C5786": "",
      "C5161": 6240, "C5614": 4852.72, "C5619": 386218.15, "C5660": 71535672, "C5695": 21506.31, "C5670": 171789.83, "C5736": "", "C5737": "", "C5738": "", "C5787": "", "C5788": "", "C5789": "", "C5790": "", "C5791": "",

      "C5139": 2600, "C5192": 2021.97, "C5193": 160927.55, "C5628": 29806530, "C5696": 8960.96, "C5671": 71579.09, "C5739": "", "C5740": "", "C5741": "",
      "C5162": 7800, "C5615": 6065.90, "C5618": 482772.68, "C5661": 89419590, "C5697": 26882.88, "C5672": 214737.28, "C5742": "", "C5743": "", "C5746": "",
      "C5163": 10400, "C5616": 8087.87, "C5617": 643696.91, "C5662": 119226120, "C5698": 35843.85, "C5673": 286316.38, "C5744": "", "C5745": "", "C5747": "",

      "C5121": "",
      "C5144": 978, "C5605": 760.57, "C5198": 60533.52, "C5626": 11207261.38, "C5699": 3370.70, "C5674": 26924.75, "C5748": "", "C5749": "", "C5750": "",
      "C5145": 1358, "C5604": 1056.09, "C5199": 84053.70, "C5663": 15568179.90, "C5700": 4680.38, "C5675": 37386.31, "C5751": "", "C5752": "", "C5753": "",
      "C5146": 2437, "C5603": 1895.21, "C5600": 150838.63, "C5664": 27937889.85, "C5701": 8399.18, "C5676": 67091.64, "C5754": "", "C5755": "", "C5756": "",
      "C5147": 3532, "C5601": 2746.77, "C5602": 218613.88, "C5665": 40491024.60, "C5702": 12173.12, "C5677": 97237.45, "C5757": "", "C5758": "", "C5759": "",


      "C5148": 1630, "C5606": 1267.62, "C5194": 100889.19, "C5666": 18686401.50, "C5703": 5617.83, "C5678": 44874.59, "C5760": "", "C5761": "", "C5762": "",
      "C5149": 2266, "C5607": 1762.22, "C5195": 140254.55, "C5667": 25977537.30, "C5704": 7809.82, "C5679": 62383.93, "C5763": "", "C5764": "", "C5765": "",
      "C5150": 4061, "C5608": 3158.16, "C5196": 251356.45, "C5668": 46555507.05, "C5705": 13996.33, "C5680": 111801.04, "C5766": "", "C5767": "", "C5768": "",
      "C5151": 5886, "C5609": 4577.42, "C5197": 364315.21, "C5669": 67477398.30, "C5706": 20286.24, "C5681": 162044.06, "C5769": "", "C5770": "", "C5771": "",

      "C5122": "", "C5222": "",
      "C5123": "", "C5223": "",
      "C5124": "", "C5224": "",

      // --(this below should be as 65/- default for labour cost)--------------
      // indo       |     malaysia
      //============================
      "C5138": 65, "C5710": 224.02,

      "C5125": 76, "C5622": 50.46, "C5623": 4013.07, "C5140": 74, "C5141": 44, "C5152": 29, "C5153": -27, "C5154": -1.3,
      "C5155": 48, "C5156": 27, "C5157": -32, "C5158": -89, "C5159": 76,



      //



      //     IRAQI   | EGYPTIAN  |   KUWAITI  |  QATARI |    SAUDI    |    UAE    |   MEXICAN  | POUND


    }

    this.objdata6_nw = {
      "C5126": 2600.00, "C5138": 159730.65, "C5142": 2019.55, "C5637": 29821038.00, "C5645": 71655.39, "C5711": 8961, "C5225": "",
      "C5127": 575.00, "C5139": 35325.05, "C5144": 446.63, "C5638": 6595037.25, "C5646": 15846.86, "C5712": 1982, "C5226": "",
      "C5128": 1858, "C5140": 114145.98, "C5145": 1443.20, "C5639": 21310572.54, "C5647": 51206.04, "C5713": 6404, "C5227": "",

      "C5129": "", "C5228": 1100, "C5147": 67578.35, "C5146": 854.42, "C5641": 12616593.00, "C5644": 30315.74, "C5714": 3792,

      "C5130": 5033, "C5141": 309201.68, "C5148": 3909.38, "C5640": 57726647.79, "C5648": 138708.29, "C5715": 17346.35, "C5229": 1100, "C5143": 67578.35, "C5149": 854.42, "C5642": 12616593.00, "C5643": 30315.74, "C5716": 3791.18,

      "C5137": 78, "C5164": 78, "C5165": 78, "C5686": 78, "C5687": 78, "C5688": 78,
      "C5230": 1.09, "C5166": 1.09, "C5167": 1.09, "C5689": 1.09, "C5690": 1.09, "C5691": 1.09,
    }

    this.objdata7_nw = {

      //cad       |    usd              | inr            |    indo             |  thai        |    malaysia
      //========================================================================================================
      "C5131": 5574, "C5168": 4329.60, "C5169": 342628.40, "C5629": 63931717.62, "C5649": 153618.13, 'C5717': 19211, "C5231": 3499, "C5170": 2717.85, "C5176": 215080.15, "C5634": 40132235.37, "C5654": 96431.62, "C5723": 12059,
      "C5132": 1406, "C5171": 1092.11, "C5177": 86425.46, "C5630": 16126299.78, "C5650": 38749.03, 'C5718': 4845, "C5232": "",
      "C5133": 1261, "C5172": 979.48, "C5178": 77512.45, "C5631": 14463203.43, "C5651": 34752.86, 'C5719': 4346, "C5233": "",
      "C5134": 1855, "C5173": 1440.87, "C5179": 114025.06, "C5632": 21276163.65, "C5652": 51123.36, 'C5720': 6393, "C5234": "",
      "C5135": "", "C5235": 3080, "C5174": 2392.39, "C5180": 189324.63, "C5635": 35326460.40, "C5655": 84884.07, "C5724": 10616,
      "C5136": 10096, "C5175": 7842.07, "C5181": 620591.38, "C5633": 115797384.48, "C5653": 278243.38, "C5721": 34796.10, "C5236": 6579, "C5182": 5110.24, "C5183": 404404.78, "C5636": 75458695.77, "C5656": 181315.69, "C5722": 22674.68,
      "C5141": 34, "C5184": 34, "C5185": 34, "C5682": 34, "C5683": 34, "C5725": 34,
      "C5237": 1.4, "C5186": 1.4, "C5187": 1.4, "C5684": 1.4, "C5685": 1.4, "C5726": 1.4,
    }
  }
  proselection() {
    this.Valueassign()
    if (this.Projecttype == "newbuilding" && this.project == 'old' && this.cheeck != "") {
      console.log("HECK", this.objdata_nw_1)
      this.objdata = this.objdata_nw_1
      this.objdata1 = this.objdata1_nw_1
      this.objdata2 = this.objdata2_nw_1
      this.objdata3 = this.objdata3_nw_1
      this.objdata4 = this.objdata4_nw_1
      this.objdata5 = this.objdata5_nw_1
      this.objdata6 = this.objdata6_nw_1
      this.objdata7 = this.objdata7_nw_1
    }
    else if (this.Projecttype == "newbuilding" && this.project == 'new' && this.cheeck != "") {
      this.objdata = this.objdata_nw
      this.objdata1 = this.objdata1_nw
      this.objdata2 = this.objdata2_nw
      this.objdata3 = this.objdata3_nw
      this.objdata4 = this.objdata4_nw
      this.objdata5 = this.objdata5_nw
      this.objdata6 = this.objdata6_nw
      this.objdata7 = this.objdata7_nw
    }
    else if (this.Projecttype == "existingbuilding" && this.project == 'old' && this.cheeck != "") {
      this.objdata = this.objdata_old_1
      this.objdata1 = this.objdata1_old_1
      this.objdata2 = this.objdata2_old_1
      this.objdata3 = this.objdata3_nw_1
      this.objdata4 = this.objdata4_nw_1
      this.objdata5 = this.objdata5_nw_1
      this.objdata6 = this.objdata6_nw_1
      this.objdata7 = this.objdata7_nw_1
      console.log("Hit", this.objdata_old_1)
    }
    else if (this.Projecttype == "existingbuilding" && this.project == 'old' && this.cheeck == "") {
      this.objdata = this.objdata_old
      this.objdata1 = this.objdata1_old
      this.objdata2 = this.objdata2_old
      this.objdata3 = this.objdata3_nw
      this.objdata_nw_1 = this.objdata_nw
      this.objdata1_nw_1 = this.objdata1_nw
      this.objdata2_nw_1 = this.objdata2_nw
      this.objdata3_nw_1 = this.objdata3_nw
      this.objdata4_nw_1 = this.objdata4_nw
      this.objdata5_nw_1 = this.objdata5_nw
      this.objdata6_nw_1 = this.objdata6_nw
      this.objdata7_nw_1 = this.objdata7_nw
    }
    else if (this.Projecttype == "newbuilding" && this.project == 'old' && this.cheeck == "") {
      this.objdata = this.objdata_nw
      this.objdata1 = this.objdata1_nw
      this.objdata2 = this.objdata2_nw
      this.objdata3 = this.objdata3_nw
      this.objdata4 = this.objdata4_nw
      this.objdata5 = this.objdata5_nw
      this.objdata6 = this.objdata6_nw
      this.objdata7 = this.objdata7_nw
      this.objdata_old_1 = this.objdata_old
      this.objdata1_old_1 = this.objdata1_old
      this.objdata2_old_1 = this.objdata2_old
    }
    else {
      console.log("nonHit")
      this.objdata = this.objdata_old
      this.objdata1 = this.objdata1_old
      this.objdata2 = this.objdata2_old
      this.objdata3 = this.objdata3_nw

    }
    this.Savior()

  }
  Savior() {
    if (this.objdata2_old_1 === undefined) {
      this.objdata_old_1 = this.objdata_old
      this.objdata1_old_1 = this.objdata1_old
      this.objdata2_old_1 = this.objdata2_old
    }
    if (this.objdata_nw_1 === undefined) {
      console.log("saviorapplied")
      this.objdata_nw_1 = this.objdata_nw
      this.objdata1_nw_1 = this.objdata1_nw
      this.objdata2_nw_1 = this.objdata2_nw
      this.objdata3_nw_1 = this.objdata3_nw
      this.objdata4_nw_1 = this.objdata4_nw
      this.objdata5_nw_1 = this.objdata5_nw
      this.objdata6_nw_1 = this.objdata6_nw
      this.objdata7_nw_1 = this.objdata7_nw
    }
  }

  prject_nw() {
    this.project = 'new'
  }

  country() {
    return [
      {
        id: 1,
        name: "APAC"
      },
      {
        id: 2,
        name: "BRAZIL"
      },
      {
        id: 3,
        name: "CANADA"
      },
      {
        id: 4,
        name: "CE"
      },
      {
        id: 5,
        name: "EU"
      },
      {
        id: 6,
        name: "INDIA"
      },
      {
        id: 7,
        name: "LATAM"
      },
      {
        id: 8,
        name: "MEA"
      },
      {
        id: 9,
        name: "MEXICO",

      },
      {
        id: 10,
        name: "USA"
      },
      {
        id: 11,
        name: "UK"
      },
    ]
  }
  currency() {
    return [
      {
        id: 1,
        name: "CHINESE YUAN",
        symbol: "CHINA-¥",
        sym: '¥'
      },
      {
        id: 1,
        name: "INDONESIA RUPIAH",
        symbol: "INDONESIA-Rp",
        sym: 'Rp'
      }, {
        id: 1,
        apac_id: 2,
        name: "MALAYSIAN RINGGITS",
        symbol: "MALAYSIA-RM",
        sym: 'Rm'
      }, {
        id: 1,
        apac_id: 3,
        name: "THAILAND BAHT",
        symbol: "THAILAND-฿",
        sym: 'B'
      },
      {
        id: 1,
        name: "SINGAPORE DOLLAR",
        symbol: "SINGAPORE-S$",
        sym: 'S$'
      },
      {
        id: 1,
        name: "TAIWAN DOLLAR",
        symbol: "TAIWAN-NT$",
        sym: 'NT$'
      },
      {
        id: 1,
        name: "SOUTH KOREAN WON",
        symbol: "SOUTH KOREA-₩",
        sym: '₩'
      },
      {
        id: 1,
        name: "VIETNAMESE DONG",
        symbol: "VIETNAMESE-₫",
        sym: '₫'
      },
      {
        id: 1,
        name: "USD",
        symbol: "USD-$",
        sym: '$'
      },
      {
        id: 1,
        name: "Select",
        symbol: "Select Currency",
        // sym:'$'
      },
      {
        id: 2,
        name: "BRAZILIAN REAL",
        symbol: "BRAZILIAN-R$",
        sym: 'R$'
      },
      {
        id: 3,
        name: "CAD $",
        symbol: "CAD-C$",
        sym: 'C$'
      },
      {
        id: 4,
        name: "EURO",
        symbol: "EURO-€",
        sym: '€'
      },
      {
        id: 5,
        name: "EURO",
        symbol: "EURO-€",
        sym: '€'
      },
      {
        id: 6,
        name: "RUPEE",
        symbol: "RUPEE-₹",
        sym: '₹'
      },
      {
        id: 7,
        name: "COLOMBIAN PESO",
        symbol: "COLOMBIAN-Col$",
        sym: 'Col$'
      },
      {
        id: 7,
        name: "COSTA RICAN COLON",
        symbol: "COSTARICA-₡",
        sym: '₡'
      },
      {
        id: 7,
        name: "PERUVIAN SOL",
        symbol: "PERUVIAN-S/.",
        sym: 'S/.'
      },
      {
        id: 7,
        name: "USD",
        symbol: "USD-$",
        sym: '$'
      },
      {
        id: 7,
        name: "Select",
        symbol: "Select Currency",
        // sym:'$'
      },
      {
        id: 8,
        name: "BAHRAINI DINAR",
        symbol: "BAHRAINI-د.ب",
        sym: 'د.ب'
      },
      {
        id: 8,
        name: "EGYPTIAN POUND",
        symbol: "EGYPTIAN-E£",
        sym: 'E£'
      },
      {
        id: 8,
        name: "IRAQI DINAR",
        symbol: "IRAQI-IQD",
        sym: 'IQD'
      },
      {
        id: 8,
        name: "KUWAITI DINAR",
        symbol: "KUWAITI-د.ك",
        sym: 'د.ك'
      },
      {
        id: 8,
        name: "OMANI RIAL",
        symbol: "OMANI-ر.ع.",
        sym: 'ر.ع.'
      },
      {
        id: 8,
        name: "QATARI RIYAL",
        symbol: "QATARI-QR",
        sym: 'QR'
      },
      {
        id: 8,
        name: "SAUDI RIYAL",
        symbol: "SAUDI-SAR",
        sym: 'SAR'
      },
      {
        id: 8,
        name: "UNITED ARAB EMIRATES DIRHAM",
        symbol: "UAE-د.إ",
        sym: 'د.إ'
      },
      {
        id: 8,
        name: "USD",
        symbol: "USD-$",
        sym: '$'
      },
      {
        id: 9,
        name: "MEXICAN PESO",
        symbol: "MEXICAN-MX$",
        sym: 'MX$'
      },
      {
        id: 10,
        name: "USD",
        symbol: "USD-$",
        sym: '$'
      },
      {
        id: 11,
        name: "POUND STERLING",
        symbol: "POUND-£",
        sym: '£'
      },

    ]
  }

  symbol() {

    return [
      {
        id: 1,
        apac_id: 1,
        name: "a"
      },
      {
        id: 1,
        apac_id: 2,
        name: "b"
      },
      {
        id: 1,
        apac_id: 3,
        name: "c"
      },
    ]
  }


}

