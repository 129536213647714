import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { ModalService } from '../_modal';
import { ModalTableService } from '../modal-table/modal-table.service';
import { ProcessserviceService } from '../processservice.service';
import { FormSaverService } from '../services/formsaver.service';
import { IApmServiceContractQuoteTable, createApmServiceContractQuoteTable } from './formulas/Tables/ApmServiceContractQuoteTable';
import { ICostCustomerCouldIncurredTable, createCostCustomerCouldIncurredTable } from './formulas/Tables/CostCustomerCouldIncurred';
import { IInputTable, createInputTable } from './formulas/Tables/InputTable';
import { IPrintOutput, createPrintOutputTable } from './formulas/Tables/PrintOutputTable';
import { IResponseTimePremiumTable, createResponseTimePremiumTable } from './formulas/Tables/ResponseTimePremiumTable';
import { IWarrantyPricingTable, createWarrantyPricingTable } from './formulas/Tables/WarrantyPricingTable';
import { currenciesData } from './formulas/currencies';
import { CachedProperties } from './formulas/proxy';


@Component({
  selector: 'app-calculation-view',
  templateUrl: './calculation-view.component.html',
  styleUrls: ['./calculation-view.component.css']
})
export class CalculationViewComponent implements OnInit {
  public Jsondata = { 'Pumpservice': "pumppro", 'Pumpservice1': "pumpess", 'pumps': true, 'smarts': false, 'smartc': false, 'pumpes': false, 'allsel': false }
  public Projecttype: string = "existingbuilding"
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  public fillpage: boolean = false
  public prdetail: boolean = true
  public disablescomm: boolean = true
  public objdata;
  public objdata1;
  public objdata2;
  public objdata3;
  public objdata4;
  public objdata5;
  public objdata6;
  public objdata7;
  public Service1 = false;
  public Service2 = false;
  public Service3 = false
  public Service4 = false;
  public hint1 = false; public hint2 = false; public hint3 = false;
  public disableppro = true; public disablepess = true; public disablessm = false; public disablepupgrad = false; public disablecew = false; public disabletrain = false; public disablescon = false;
  public standard: boolean = false; public configured: boolean = false; public disablestd = true;

  showMe: boolean = true
  showMeHP1: boolean = false
  showMenothing: boolean = false
  public smrtstr: boolean = true;
  showMemore: boolean = false;
  CEWtable1: boolean = true;
  CEWtable2: boolean = true;
  CEWtable3: boolean = false;
  selectedValued: Number = 1;
  selectedValues: Number = 1;
  selectedValuetrn: Number = 0;
  selectedValuecnvt: Number = 0;
  selectedValuecrncy: Number = 0;

  inputTable: IInputTable;
  printOutputTable: IPrintOutput;
  warrantyPricingTable: IWarrantyPricingTable;
  responseTimePremiumTable: IResponseTimePremiumTable
  apmServiceContractQuoteTable: IApmServiceContractQuoteTable
  costCustomerCouldIncurredTable: ICostCustomerCouldIncurredTable;

  yearsForActivePerformanceManagement = [1, 2, 3];
  hoursForResponseTime = [4, 8, 24];

  _values1: any = ['CAD', 'CHINESE YUAN', 'USD', 'IND', 'INDONESIA RUPIAH', 'MALAYSIAN RINGGITS',
    'THAILAND BAHT',
    'SINGAPORE DOLLAR',
    'TAIWAN DOLLAR',
    'SOUTH KOREAN WON',
    'VIETNAMESE DONG',
    'BRAZILIAN REAL',
    'EURO',
    'COLOMBIAN PESO',
    'COSTA RICAN COLON',
    'PERUVIAN SOL',
    'BAHRAINI DINAR',
    'EGYPTIAN POUND',
    'IRAQI DINAR',
    'KUWAITI DINAR',
    'OMANI RIAL',
    'QATARI RIYAL',
    'SAUDI RIYAL',
    'UAE DIRHAM',
    'MEXICAN PESO',
    'POUND STERLING',
  ];

  _values2: any = ['0-10 HP (0-7.5 kW)',
    '11-20 HP (8-15 kW)',
    '21-50 HP (16-37 kW)',
    '51-75 HP (38-55 kW)'];

  labourCost;
  pumpsCount;

  currentPrice;
  currentPrice1;
  partsPrice;
  currentHpPrice;
  currentHpPrice1;
  currentHpPrice2;
  currentHpPrice3;
  currentHpPrice4;
  currentHpPrice5;
  partsHpprice;


  serviceCost;
  opportunityCost;
  englossCost;
  trainingCost;
  total;
  serviceTraining;
  agrTrainSave;
  trainPayback;

  energy1;
  energy2;
  planMaint;
  unplanMaint;
  downtime;
  serContract;
  contStatusQuo;
  serviceCont;

  exchangeRate;
  exchangeSymbol;
  selectedValue;
  selectedHp: Number = 1;
  selectedExtendedWarrantyEntitlement: "YES" | "NO" = "YES"

  product = {
    cewLabourCost: 1560,
    cewLabourCost5: 2600,
    partsCost: 300,


    servicecontractorCost: 2600,
    invopportunityCost: 575,
    energylossCost: 1858,
    srvicTraningcost: 1100,
    trainingStatusquo: 5033,
    armstrongserviceTraining: 1100,

    contEnergy1: (1750 + (1750 * (1 / 100 + 1) * (5 / 100 + 1)) + (1750 * (1 / 100 + 1) * (5 / 100 + 1)) * (1 / 100 + 1) * (5 / 100 + 1)),
    contEnergy2: (1750 - (0.9 * (420 + 300)) + ((1750 - (0.9 * (420 + 300))) * (1 + 0.7 / 100) * (1 + 0 / 100) * (1 + 5 / 100)) + (((1750 - (0.9 * (420 + 300))) * (1 + 0.7 / 100) * (1 + 0 / 100) * (1 + 5 / 100)) * (1 + 0.7 / 100) * (1 + 0 / 100) * (1 + 5 / 100))),
    plannedMaint: ((((13 / 100 * ((10000 + 10000) / 0.2)) / 20) * (1 - 30 / 100)) + ((((13 / 100 * ((10000 + 10000) / 0.2)) / 20) * (1 - 30 / 100)) * (1 + 3 / 100)) + (((((13 / 100 * ((10000 + 10000) / 0.2)) / 20) * (1 - 30 / 100)) * (1 + 3 / 100)) * (1 + 3 / 100))),
    unplannedMaint: ((3 / 100 * 10000 + (8 * 135) * 10 / 100) + ((3 / 100 * 10000 + (8 * 135) * 10 / 100) * (1 + 3 / 100)) + (((3 / 100 * 10000 + (8 * 135) * 10 / 100) * (1 + 3 / 100)) * (1 + 3 / 100))),
    contDowntime: (((3 / 100 * ((10000 + 10000) / 0.2)) / 20) + 9 / 100 * ((10000 + 10000) / 0.2) / 20) + ((((3 / 100 * ((10000 + 10000) / 0.2)) / 20) + 9 / 100 * ((10000 + 10000) / 0.2) / 20) * (1 + 3 / 100)) + (((((3 / 100 * ((10000 + 10000) / 0.2)) / 20) + 9 / 100 * ((10000 + 10000) / 0.2) / 20) * (1 + 3 / 100)) * (1 + 3 / 100)),
    serviceContract: (((150 + 60 + 87 + 65 + 65) * 3 + (0 + 260)) / 0.5),
    contractStatusQuo: 10096,
    ArmServiceCont: 6579,


    _values1: 1,
  };

  crncy = {};

  constructor(
    private modalService: ModalService,
    private router: Router,
    private processsservice: ProcessserviceService,
    private cdRef: ChangeDetectorRef,
    public formSaver: FormSaverService,
    private modalTableService: ModalTableService,
  ) { }

  ngOnInit(): void {
    this.display();
    this.fillpage = true
    this.Projecttype = this.processsservice.userinfo['Projecttype']
    this.disablescomm = this.processsservice.costinfo['smartc']
    this.disablessm = this.processsservice.costinfo['smarts']
    this.disablepess = this.processsservice.costinfo['pumpes']
    this.disablepupgrad = this.processsservice.costinfo['perfup']
    this.disablecew = this.processsservice.costinfo['cew']
    this.disabletrain = this.processsservice.costinfo['train']
    this.disablescon = this.processsservice.costinfo['scont']
    if (this.processsservice.costinfo['pumps'] == true && this.processsservice.costinfo['Pumpservice'] == 'pumppro') { this.disableppro = this.processsservice.costinfo['pumps'] } else { this.disableppro = false }
    if (this.processsservice.costinfo['pumpes'] == true && this.processsservice.costinfo['Pumpservice1'] == 'pumpess') {
      this.disablepess = this.processsservice.costinfo['pumpes']
    } else { this.disablepess = false }
    //if(this.processsservice.costinfo['perfup'] == true && this.processsservice.costinfo['Pumpservice'] == 'perfupg'){
    // this.disablepupgrad = this.processsservice.costinfo['perfup']}else{this.disablepupgrad = false}
    if (this.processsservice.costinfo['pumps'] == true || this.processsservice.costinfo['pumpes'] == true) { this.Jsondata['pumps'] = true } else { this.Jsondata['pumps'] = false }

    if (this.processsservice.costinfo['smarts'] == true) { this.Jsondata['smarts'] = true } else { this.Jsondata['smarts'] = false }

    if (this.processsservice.costinfo['smartc'] == true) { this.Jsondata['smartc'] = true } else { this.Jsondata['smartc'] = false }

    if (this.processsservice.costinfo['perfup'] == true) { this.Jsondata['perfup'] = true } else { this.Jsondata['perfup'] = false }

    // if(this.processsservice.costinfo['smarts'] == true){
    //   this.smrtstr = true
    // }
    var select_designatfirst = $('#select_designatfirst'),
      empSearch = $('#empSearch');

    select_designatfirst.on('change', function () {
      empSearch.attr('placeholder', 'Search ' + select_designatfirst.find(':selected').text());
    });

    this.smrtstr = this.processsservice.costinfo['smarts']

    this.processsservice.proselection()
    this.objdata = this.processsservice.objdata
    this.objdata1 = this.processsservice.objdata1
    this.objdata2 = this.processsservice.objdata2
    this.objdata3 = this.processsservice.objdata3
    this.objdata4 = this.processsservice.objdata4
    this.objdata5 = this.processsservice.objdata5
    this.objdata6 = this.processsservice.objdata6
    this.objdata7 = this.processsservice.objdata7

    this.processsservice.cheeck = "checked"

    this.initPrintOutput();

    this.formSaver.formsData$.subscribe((data) => {
      this.restoreFormData(data.calculationViewInfo);
    });

  }

  curncy: any;

  display() {
    let dropvalue = localStorage.getItem('curncy');
    //alert(dropvalue);
    this.curncy = JSON.parse(dropvalue);
    console.log(this, dropvalue);

    if (this.curncy == "C$") {
      this.selectedValuecrncy = 0,
        this.selectedValuetrn = 0,
        this.selectedValuecnvt = 0,
        this.selectedValue = "CAD"
      this.currentPrice = 1560;
      this.currentPrice1 = 2600;
      this.partsPrice = 300;
      this.currentHpPrice = 1560;
      this.currentHpPrice1 = 4680;
      this.currentHpPrice2 = 6240;
      this.currentHpPrice3 = 2600;
      this.currentHpPrice4 = 7800;
      this.currentHpPrice5 = 10400;

      this.serviceCost = 2600;
      this.opportunityCost = 575;
      this.englossCost = 1858;
      this.trainingCost = 1100;
      this.total = 5033;
      this.serviceTraining = 1100;
      //   this.trainPayback =1.09;

      this.energy1 = 5574;
      this.energy2 = 3499;
      this.planMaint = 1406;
      this.unplanMaint = 1261;
      this.downtime = 1854;
      this.serContract = 3080;
      this.contStatusQuo = 10096;
      this.serviceCont = 6579;
    }

    if (this.curncy == "$") {
      this.selectedValuecrncy = 1,
        this.selectedValuetrn = 1,
        this.selectedValuecnvt = 1,
        this.selectedValue = "USD"
      this.currentPrice = 1123.2;
      this.partsPrice = 216;

      this.serviceCost = 1872;
      this.opportunityCost = 414;
      this.englossCost = 1337.76;
      this.trainingCost = 792;
      this.total = 3623;
      this.serviceTraining = 792;

      this.energy1 = 4329;
      this.energy2 = 2717;
      this.planMaint = 1092;
      this.unplanMaint = 979;
      this.downtime = 1440;
      this.serContract = 2392;
      this.contStatusQuo = 7842;
      this.serviceCont = 5110;
    }
    if (this.curncy == "¥") {
      this.selectedValuecrncy = 1,
        this.selectedValuetrn = 1,
        this.selectedValuecnvt = 1,
        this.selectedValue = "USD"
      this.currentPrice = 1123.2;
      this.partsPrice = 216;

      this.serviceCost = 13338;
      this.opportunityCost = 2950;
      this.englossCost = 9532;
      this.trainingCost = 5643;
      this.total = 25820;
      this.serviceTraining = 5643;

      this.energy1 = 28595;
      this.energy2 = 17951;
      this.planMaint = 7215;
      this.unplanMaint = 6469;
      this.downtime = 9514;
      this.serContract = 15811;
      this.contStatusQuo = 51793;
      this.serviceCont = 33762;
    }

    if (this.curncy == "₹") {
      this.selectedValuecrncy = 2,
        this.selectedValuetrn = 2,
        this.selectedValuecnvt = 2
      this.selectedValue = "IND"
      this.currentPrice = 106080;
      this.partsPrice = 20400;

      this.serviceCost = 176800;
      this.opportunityCost = 39100;
      this.englossCost = 126344;
      this.trainingCost = 74800;
      this.total = 342244;
      this.serviceTraining = 74800;

      this.energy1 = 379034;
      this.energy2 = 237947;
      this.planMaint = 95632;
      this.unplanMaint = 85753;
      this.downtime = 126108;
      this.serContract = 209576;
      this.contStatusQuo = 686529;
      this.serviceCont = 447523;
    }
    if (this.curncy == "Rp") {
      this.selectedValuecrncy = 3,
        this.selectedValuetrn = 3,
        this.selectedValuecnvt = 3
      this.selectedValue = "INDONESIA RUPIAH"
      this.currentPrice = 17997298.8;
      this.partsPrice = 3461019;

      this.serviceCost = 29995498;
      this.opportunityCost = 6633619;
      this.englossCost = 21435244;
      this.trainingCost = 12690403;
      this.total = 58064362;
      this.serviceTraining = 12690403;

      this.energy1 = 64306084;
      this.energy2 = 40369597;
      this.planMaint = 16224789;
      this.unplanMaint = 14548822;
      this.downtime = 21395327;
      this.serContract = 35556201;
      this.contStatusQuo = 116475023;
      this.serviceCont = 75925799;
    }
    if (this.curncy == "RM") {
      this.selectedValuecrncy = 4,
        this.selectedValuetrn = 4,
        this.selectedValuecnvt = 4
      this.selectedValue = "MALAYSIAN RINGGITS"
      this.currentPrice = 5116.79;
      this.partsPrice = 983.99;

      this.serviceCost = 8528;
      this.opportunityCost = 1886;
      this.englossCost = 6094;
      this.trainingCost = 3608;
      this.total = 16508;
      this.serviceTraining = 3608;

      this.energy1 = 18282;
      this.energy2 = 11477;
      this.planMaint = 4612;
      this.unplanMaint = 4136;
      this.downtime = 6082;
      this.serContract = 10108;
      this.contStatusQuo = 33114;
      this.serviceCont = 21586;
    }
    if (this.curncy == "฿") {
      this.selectedValuecrncy = 5,
        this.selectedValuetrn = 5,
        this.selectedValuecnvt = 5
      this.selectedValue = "THAILAND BAHT"
      this.currentPrice = 40622.4;
      this.partsPrice = 7812;

      this.serviceCost = 67704;
      this.opportunityCost = 14973;
      this.englossCost = 48382;
      this.trainingCost = 28644;
      this.total = 131059;
      this.serviceTraining = 28644;

      this.energy1 = 145147;
      this.energy2 = 91119;
      this.planMaint = 36621;
      this.unplanMaint = 32838;
      this.downtime = 48292;
      this.serContract = 80255;
      this.contStatusQuo = 262900;
      this.serviceCont = 171375;
    }
    if (this.curncy == "S$") {
      this.selectedValuecrncy = 6,
        this.selectedValuetrn = 6,
        this.selectedValuecnvt = 6
      this.selectedValue = "SINGAPORE DOLLAR"
      this.currentPrice = 1591.2;
      this.partsPrice = 306;

      this.serviceCost = 2652;
      this.opportunityCost = 586;
      this.englossCost = 1895;
      this.trainingCost = 1122;
      this.total = 5133;
      this.serviceTraining = 1122;

      this.energy1 = 5685;
      this.energy2 = 3569.;
      this.planMaint = 1434;
      this.unplanMaint = 1286;
      this.downtime = 1891;
      this.serContract = 3143;
      this.contStatusQuo = 10297;
      this.serviceCont = 6712;
    }
    if (this.curncy == "NT$") {
      this.selectedValuecrncy = 7,
        this.selectedValuetrn = 7,
        this.selectedValuecnvt = 7
      this.selectedValue = "TAIWAN DOLLAR"
      this.currentPrice = 35755.2;
      this.partsPrice = 6876;

      this.serviceCost = 59592;
      this.opportunityCost = 13179;
      this.englossCost = 42585;
      this.trainingCost = 25212;
      this.total = 115356;
      this.serviceTraining = 25212;

      this.energy1 = 127756;
      this.energy2 = 80202;
      this.planMaint = 32233;
      this.unplanMaint = 28904;
      this.downtime = 42506;
      this.serContract = 70639;
      this.contStatusQuo = 231400;
      this.serviceCont = 150841;
    }
    if (this.curncy == "₩") {
      this.selectedValuecrncy = 8,
        this.selectedValuetrn = 8,
        this.selectedValuecnvt = 8,
        this.selectedValue = "SOUTH KOREAN WON",
        this.currentPrice = 1517412;
      this.partsPrice = 291810;

      this.serviceCost = 2529020;
      this.opportunityCost = 559302;
      this.englossCost = 1807276;
      this.trainingCost = 1069970;
      this.total = 4895599;
      this.serviceTraining = 1069970;

      this.energy1 = 5421859;
      this.energy2 = 3403694;
      this.planMaint = 1367965;
      this.unplanMaint = 1226659;
      this.downtime = 1803911;
      this.serContract = 2997861;
      this.contStatusQuo = 9820395;
      this.serviceCont = 6401556;
    }
    if (this.curncy == "₫") {
      this.selectedValuecrncy = 9,
        this.selectedValuetrn = 9,
        this.selectedValuecnvt = 9,
        this.selectedValue = "VIETNAMESE DONG"
      this.currentPrice = 28477488;
      this.partsPrice = 5476440;

      this.serviceCost = 47462480;
      this.opportunityCost = 10496510;
      this.englossCost = 33917418;
      this.trainingCost = 20080280;
      this.total = 91876408;
      this.serviceTraining = 20080280;

      this.energy1 = 101752810;
      this.energy2 = 63877627;
      this.planMaint = 25672811;
      this.unplanMaint = 23020894;
      this.downtime = 33854256;
      this.serContract = 56261293;
      this.contStatusQuo = 184300773;
      this.serviceCont = 120138920;
    }
    if (this.curncy == "R$") {
      this.selectedValuecrncy = 10,
        this.selectedValuetrn = 10,
        this.selectedValuecnvt = 10,
        this.selectedValue = "BRAZILIAN REAL"
      this.currentPrice = 6021.59;
      this.partsPrice = 1158;

      this.serviceCost = 10036;
      this.opportunityCost = 2219;
      this.englossCost = 7171;
      this.trainingCost = 4246;
      this.total = 19427;
      this.serviceTraining = 4246;

      this.energy1 = 21515;
      this.energy2 = 13507;
      this.planMaint = 5428;
      this.unplanMaint = 4867;
      this.downtime = 7158;
      this.serContract = 11896;
      this.contStatusQuo = 38970;
      this.serviceCont = 25403;
    }
    if (this.curncy == "€") {
      this.selectedValuecrncy = 11,
        this.selectedValuetrn = 11,
        this.selectedValuecnvt = 11,
        this.selectedValue = "EURO"
      this.currentPrice = 1107.6;
      this.partsPrice = 213;

      this.serviceCost = 1846;
      this.opportunityCost = 408;
      this.englossCost = 1319;
      this.trainingCost = 781;
      this.total = 3573;
      this.serviceTraining = 781;

      this.energy1 = 3957;
      this.energy2 = 2484;
      this.planMaint = 998;
      this.unplanMaint = 895;
      this.downtime = 1316;
      this.serContract = 2188;
      this.contStatusQuo = 7168;
      this.serviceCont = 4672;
    }
    if (this.curncy == "Col$") {
      this.selectedValuecrncy = 12,
        this.selectedValuetrn = 12,
        this.selectedValuecnvt = 12
      this.selectedValue = "COLOMBIAN PESO"
      this.currentPrice = 5611429.2;
      this.partsPrice = 1079121;

      this.serviceCost = 9352382;
      this.opportunityCost = 2068315;
      this.englossCost = 6683356;
      this.trainingCost = 3956777;
      this.total = 18104053;
      this.serviceTraining = 3956777;

      this.energy1 = 20050177;
      this.energy2 = 12586952;
      this.planMaint = 5058773;
      this.unplanMaint = 4536218;
      this.downtime = 6670910;
      this.serContract = 11086169;
      this.contStatusQuo = 36316080;
      this.serviceCont = 23673121;
    }
    if (this.curncy == "₡") {
      this.selectedValuecrncy = 13,
        this.selectedValuetrn = 13,
        this.selectedValuecnvt = 13
      this.selectedValue = "COSTA RICAN COLON"
      this.currentPrice = 688864.79;
      this.partsPrice = 132474;

      this.serviceCost = 1148108;
      this.opportunityCost = 253908.5;
      this.englossCost = 820455.64;
      this.trainingCost = 485738;
      this.total = 2222472.14;
      this.serviceTraining = 485738;

      this.energy1 = 2461380;
      this.energy2 = 1545187;
      this.planMaint = 621020;
      this.unplanMaint = 556870;
      this.downtime = 818927;
      this.serContract = 1360949;
      this.contStatusQuo = 4458199;
      this.serviceCont = 2906136;
    }
    if (this.curncy == "S/.") {
      this.selectedValuecrncy = 14,
        this.selectedValuetrn = 14,
        this.selectedValuecnvt = 14
      this.selectedValue = "PERUVIAN SOL"
      this.currentPrice = 4446;
      this.partsPrice = 855;

      this.serviceCost = 7410;
      this.opportunityCost = 1638;
      this.englossCost = 5295;
      this.trainingCost = 3135;
      this.total = 14344;
      this.serviceTraining = 3135;

      this.energy1 = 15885;
      this.energy2 = 9972;
      this.planMaint = 4008;
      this.unplanMaint = 3594;
      this.downtime = 5285;
      this.serContract = 8783;
      this.contStatusQuo = 28773;
      this.serviceCont = 18756;
    }
    if (this.curncy == "د.ب") {
      this.selectedValuecrncy = 15,
        this.selectedValuetrn = 15,
        this.selectedValuecnvt = 15
      this.selectedValue = "BAHRAINI DINAR"
      this.currentPrice = 436.80;
      this.partsPrice = 84;

      this.serviceCost = 728;
      this.opportunityCost = 161;
      this.englossCost = 520;
      this.trainingCost = 308;
      this.total = 1409;
      this.serviceTraining = 308;

      this.energy1 = 1560;
      this.energy2 = 979;
      this.planMaint = 393;
      this.unplanMaint = 353;
      this.downtime = 519;
      this.serContract = 862;
      this.contStatusQuo = 2826;
      this.serviceCont = 1842;
    }

    if (this.curncy == "E£") {
      this.selectedValuecrncy = 16,
        this.selectedValuetrn = 16,
        this.selectedValuecnvt = 16
      this.selectedValue = "EGYPTIAN POUND"
      this.currentPrice = 28563.6;
      this.partsPrice = 5493;

      this.serviceCost = 47606;
      this.opportunityCost = 10528.25;
      this.englossCost = 34019.979999999996;
      this.trainingCost = 20141;
      this.total = 92154;
      this.serviceTraining = 20141;

      this.energy1 = 102060;
      this.energy2 = 64070;
      this.planMaint = 25750;
      this.unplanMaint = 23090;
      this.downtime = 33956;
      this.serContract = 56431;
      this.contStatusQuo = 184858;
      this.serviceCont = 120502;
    }
    if (this.curncy == "IQD") {
      this.selectedValuecrncy = 17,
        this.selectedValuetrn = 17,
        this.selectedValuecnvt = 17
      this.selectedValue = "IRAQI DINAR"
      this.currentPrice = 1684581.5999999999;
      this.partsPrice = 323957.99;

      this.serviceCost = 2807635;
      this.opportunityCost = 620919;
      this.englossCost = 2006379;
      this.trainingCost = 1187846;
      this.total = 5434935;
      this.serviceTraining = 1187846;

      this.energy1 = 6019172;
      this.energy2 = 3778671;
      this.planMaint = 1518671;
      this.unplanMaint = 1361797;
      this.downtime = 2002643;
      this.serContract = 3328128;
      this.contStatusQuo = 10902285;
      this.serviceCont = 7106800;
    }
    if (this.curncy == "د.ك") {
      this.selectedValuecrncy = 18,
        this.selectedValuetrn = 18,
        this.selectedValuecnvt = 18
      this.selectedValue = "KUWAITI DINAR"
      this.currentPrice = 358.8;
      this.partsPrice = 69;

      this.serviceCost = 598;
      this.opportunityCost = 132;
      this.englossCost = 427;
      this.trainingCost = 253;
      this.total = 1157;
      this.serviceTraining = 253;

      this.energy1 = 1282;
      this.energy2 = 804;
      this.planMaint = 323;
      this.unplanMaint = 290;
      this.downtime = 426;
      this.serContract = 708;
      this.contStatusQuo = 2322;
      this.serviceCont = 1513;
    }
    if (this.curncy == "ر.ع") {
      this.selectedValuecrncy = 19,
        this.selectedValuetrn = 19,
        this.selectedValuecnvt = 19
      this.selectedValue = "OMANI RIAL"
      this.currentPrice = 452.4;
      this.partsPrice = 87;

      this.serviceCost = 754;
      this.opportunityCost = 166;
      this.englossCost = 538;
      this.trainingCost = 319;
      this.total = 1459;
      this.serviceTraining = 319;

      this.energy1 = 1616;
      this.energy2 = 1014;
      this.planMaint = 407;
      this.unplanMaint = 365;
      this.downtime = 537;
      this.serContract = 893;
      this.contStatusQuo = 2927;
      this.serviceCont = 1908;
    }
    if (this.curncy == "QR") {
      this.selectedValuecrncy = 20,
        this.selectedValuetrn = 20,
        this.selectedValuecnvt = 20
      this.selectedValue = "QATARI RIYAL"
      this.currentPrice = 4227.6;
      this.partsPrice = 813;

      this.serviceCost = 7046;
      this.opportunityCost = 1558;
      this.englossCost = 5035;
      this.trainingCost = 2981;
      this.total = 13639;
      this.serviceTraining = 2981;

      this.energy1 = 15105;
      this.energy2 = 9482;
      this.planMaint = 3811;
      this.unplanMaint = 3417;
      this.downtime = 5025;
      this.serContract = 8352;
      this.contStatusQuo = 27360;
      this.serviceCont = 17835;
    }
    if (this.curncy == "SAR") {
      this.selectedValuecrncy = 21,
        this.selectedValuetrn = 21,
        this.selectedValuecnvt = 21
      this.selectedValue = "SAUDI RIYAL"
      this.currentPrice = 4368;
      this.partsPrice = 840;

      this.serviceCost = 7279;
      this.opportunityCost = 1610;
      this.englossCost = 5202;
      this.trainingCost = 3080;
      this.total = 14092;
      this.serviceTraining = 3080;

      this.energy1 = 15607;
      this.energy2 = 9797;
      this.planMaint = 3937;
      this.unplanMaint = 3531;
      this.downtime = 5192;
      this.serContract = 8629;
      this.contStatusQuo = 28268;
      this.serviceCont = 18427;
    }
    if (this.curncy == "د.إ") {
      this.selectedValuecrncy = 22,
        this.selectedValuetrn = 22,
        this.selectedValuecnvt = 22
      this.selectedValue = "UAE DIRHAM"
      this.currentPrice = 4274;
      this.partsPrice = 822;

      this.serviceCost = 7124;
      this.opportunityCost = 1575;
      this.englossCost = 5090;
      this.trainingCost = 3014;
      this.total = 13790;
      this.serviceTraining = 3014;

      this.energy1 = 15272;
      this.energy2 = 9587;
      this.planMaint = 3853;
      this.unplanMaint = 3455;
      this.downtime = 5081;
      this.serContract = 8444.68;
      this.contStatusQuo = 27663;
      this.serviceCont = 18032;
    }
    if (this.curncy == "MX$") {
      this.selectedValuecrncy = 23,
        this.selectedValuetrn = 23,
        this.selectedValuecnvt = 23
      this.selectedValue = "MEXICAN PESO"
      this.currentPrice = 22354.8;
      this.partsPrice = 4299;

      this.serviceCost = 37258;
      this.opportunityCost = 8239;
      this.englossCost = 26625;
      this.trainingCost = 15763;
      this.total = 72122;
      this.serviceTraining = 15763;

      this.energy1 = 79875;
      this.energy2 = 50143;
      this.planMaint = 20153;
      this.unplanMaint = 18071;
      this.downtime = 26575;
      this.serContract = 44165;
      this.contStatusQuo = 1444675;
      this.serviceCont = 94308;
    }
    if (this.curncy == "£") {
      this.selectedValuecrncy = 24,
        this.selectedValuetrn = 24,
        this.selectedValuecnvt = 24
      this.selectedValue = "POUND STERLING"
      this.currentPrice = 951.6;
      this.partsPrice = 183;

      this.serviceCost = 1586;
      this.opportunityCost = 350;
      this.englossCost = 1133;
      this.trainingCost = 671;
      this.total = 3069;
      this.serviceTraining = 671;

      this.energy1 = 3400;
      this.energy2 = 2134;
      this.planMaint = 857;
      this.unplanMaint = 769;
      this.downtime = 1131;
      this.serContract = 1880;
      this.contStatusQuo = 6157;
      this.serviceCont = 4014;
    }

  }

  dropDownChanged() {
    console.log(this.selectedValue);
    console.log(getExchangeRate(this.selectedValue));
    this.exchangeRate = Number(getExchangeRate(this.selectedValue));
    console.log(getCcySymbol(this.selectedValue));
    this.exchangeSymbol = String(getCcySymbol(this.selectedValue));

    this.currentPrice = (this.product.cewLabourCost * this.labourCost * this.exchangeRate);
    this.currentPrice1 = (this.product.cewLabourCost5 * this.labourCost * this.exchangeRate);
    console.log(this.pumpsCount);
    console.log(this.product.cewLabourCost);
    console.log(this.product.cewLabourCost * this.pumpsCount);
    this.partsPrice = this.product.partsCost * this.exchangeRate;
    this.currentHpPrice = this.currentPrice
    this.currentHpPrice1 = this.currentPrice * 3
    this.currentHpPrice2 = this.currentPrice * 1.3333
    this.currentHpPrice3 = this.currentPrice1 * 1.666666667
    this.currentHpPrice4 = this.currentPrice * 5
    this.currentHpPrice5 = 2080 * 5

    this.serviceCost = (this.product.servicecontractorCost * this.exchangeRate).toFixed(0);
    this.opportunityCost = (this.product.invopportunityCost * this.exchangeRate).toFixed(0);
    this.englossCost = (this.product.energylossCost * this.exchangeRate).toFixed(0);
    this.trainingCost = (this.product.srvicTraningcost * this.exchangeRate).toFixed(0);
    this.total = (+this.serviceCost + +this.opportunityCost + +this.englossCost).toFixed(0);
    this.serviceTraining = this.trainingCost;
    this.agrTrainSave = (((this.total - this.serviceTraining) / this.total) * 100).toFixed(0);
    this.trainPayback = (this.serviceTraining / (this.total / 5)).toFixed(2);

    if (this.dropDownChanged) {
      this.curncy = JSON.stringify(this.exchangeSymbol)
      //  localStorage.setItem('curncy',JSON.stringify(this.exchangeSymbol));

    }

    // this.energy1 = (this.product.contEnergy1 * this.exchangeRate).toFixed(0);
    // this.energy2 = (this.product.contEnergy2 * this.exchangeRate).toFixed(0);
    // this.planMaint = (this.product.plannedMaint * this.exchangeRate).toFixed(0);
    // this.unplanMaint = (this.product.unplannedMaint * this.exchangeRate).toFixed(0);
    // this.downtime = (this.product.contDowntime * this.exchangeRate).toFixed(0);
    // this.serContract = (this.product.serviceContract * this.exchangeRate).toFixed(0);

    this.inputTable.energyCost_StatusQuo = this.product.contEnergy1 * this.exchangeRate;
    this.inputTable.energyCost_Armstrong = this.product.contEnergy2 * this.exchangeRate;
    this.inputTable.plannedMaintenance_StatusQuo = this.product.plannedMaint * this.exchangeRate;
    this.inputTable.unplannedMaintenance_StatusQuo = this.product.unplannedMaint * this.exchangeRate;
    this.inputTable.trainingDowntime_StatusQuo = this.product.contDowntime * this.exchangeRate;

    this.inputTable.currencyCode = currenciesData.find(x => x.ccyIndex === this.selectedValue).currencyCode;

    // this.contStatusQuo = ((this.product.contEnergy1 + this.product.plannedMaint + this.product.unplannedMaint + this.product.contDowntime) * this.exchangeRate).toFixed(0);
    // this.serviceCont = ((this.product.serviceContract + this.product.contEnergy2) * this.exchangeRate).toFixed(0);
    // this.agrContSave = (((this.contStatusQuo - this.serviceCont)/this.contStatusQuo)).toFixed(0);
    // this.agrContSave = (((this.contStatusQuo - this.serviceCont) / this.contStatusQuo) * 100).toFixed(0);

    console.log('partsPrice:', this.partsPrice);
  }

  initPrintOutput() {
    //Inquiry Compliant First Cost
    const inquiryCompliantFirstCost: number = Number(this.processsservice.costinfo["inqcst1"]);

    this.inputTable = createInputTable({}, {
      energyCost_StatusQuo: this.energy1,
      energyCost_Armstrong: this.energy2,
      plannedMaintenance_StatusQuo: this.planMaint,
      unplannedMaintenance_StatusQuo: this.unplanMaint,
      trainingDowntime_StatusQuo: this.downtime,
      currencyCode: currenciesData.find(x => x.ccyIndex === this.selectedValue).currencyCode,
      totalPurchaseValueNetOfEquipment: inquiryCompliantFirstCost,
    });

    this.warrantyPricingTable = createWarrantyPricingTable({
      input: this.inputTable,
    });

    this.responseTimePremiumTable = createResponseTimePremiumTable({
      input: this.inputTable,
    })

    this.apmServiceContractQuoteTable = createApmServiceContractQuoteTable({
      input: this.inputTable,
      warrantyPricingTable: this.warrantyPricingTable,
      responseTimePremiumTable: this.responseTimePremiumTable,
    });

    this.costCustomerCouldIncurredTable = createCostCustomerCouldIncurredTable({
      input: this.inputTable,
    });


    this.printOutputTable = createPrintOutputTable({
      input: this.inputTable,
      apmServiceContractQuoteTable: this.apmServiceContractQuoteTable,
      costCustomerCouldIncurredTable: this.costCustomerCouldIncurredTable,
    });

  }

  isDebugTables() {
    return (window as any).isDebugTables;
  }

  dropDownChanges() {
    console.log(this.selectedValue);
    this.currentHpPrice = this.currentPrice * 3;
    console.log(this.currentHpPrice);
  }


  add() {
    console.log(this.total)
    this.total = +this.serviceCost + +this.opportunityCost + +this.englossCost
    this.serviceTraining = +this.trainingCost
    this.agrTrainSave = (((this.total - this.serviceTraining) / this.total) * 100).toFixed(0);
    this.trainPayback = (this.serviceTraining / (this.total / 5)).toFixed(2);
  }

  Projectselec() {
    if (this.Projecttype == "existingbuilding" && this.processsservice.cheeck != "old") {
      this.processsservice.Projecttype = "existingbuilding"

      this.prdetail = true
      this.disablescomm = false
      this.disablepupgrad = true
      this.processsservice.objdata_nw_1 = this.objdata
      this.processsservice.objdata1_nw_1 = this.objdata1
      this.processsservice.objdata2_nw_1 = this.objdata2
      this.processsservice.objdata3_nw_1 = this.objdata3
      this.processsservice.objdata4_nw_1 = this.objdata4
      this.processsservice.objdata5_nw_1 = this.objdata5
      this.processsservice.objdata6_nw_1 = this.objdata6
      this.processsservice.objdata7_nw_1 = this.objdata7
      this.processsservice.project = "old"
      //alert("kICK")
    }
    else if (this.Projecttype == "newbuilding" && this.processsservice.cheeck != "old") {
      this.processsservice.Projecttype = "newbuilding"
      this.prdetail = true
      this.processsservice.objdata_old_1 = this.objdata
      this.processsservice.objdata1_old_1 = this.objdata1
      this.processsservice.objdata2_old_1 = this.objdata2
      this.disablescomm = true
      this.disablepupgrad = false
      this.processsservice.project = "old"
    }
    else { }
    this.processsservice.proselection()
    this.objdata = this.processsservice.objdata
    this.objdata1 = this.processsservice.objdata1
    this.objdata2 = this.processsservice.objdata2
    this.objdata3 = this.processsservice.objdata3
    this.objdata4 = this.processsservice.objdata4
    this.objdata5 = this.processsservice.objdata5
    this.objdata6 = this.processsservice.objdata6
    this.objdata7 = this.processsservice.objdata7
    //   this.assignvalue()
  }

  reset() {
    this.processsservice.cheeck = ""
    this.Projectselec()

  }


  assignvalue() {





  }

  Calcvalue() {
    this.objdata["C1401"] = ((Number(this.objdata["C1100"]) * Number(this.objdata["C1200"])) / 100).toFixed(2)
    this.objdata["C1502"] = ((Number(this.objdata["C1100"]) * Number(this.objdata["C1300"])) / 100).toFixed(2)

    this.objdata["C1408"] = ((Number(this.objdata["C1107"]) * Number(this.objdata["C1207"])) / 100).toFixed(2)
    this.objdata["C1509"] = ((Number(this.objdata["C1107"]) * Number(this.objdata["C1307"])) / 100).toFixed(2)

    this.objdata["C1412"] = ((Number(this.objdata["C1111"]) * Number(this.objdata["C1211"])) / 100).toFixed(2)
    this.objdata["C1513"] = ((Number(this.objdata["C1111"]) * Number(this.objdata["C1311"])) / 100).toFixed(2)

    this.objdata["C1417"] = ((Number(this.objdata["C1116"]) * Number(this.objdata["C1216"])) / 100).toFixed(2)
    this.objdata["C1518"] = ((Number(this.objdata["C1116"]) * Number(this.objdata["C1316"])) / 100).toFixed(2)

    this.objdata["C1420"] = ((Number(this.objdata["C1119"]) * Number(this.objdata["C1219"])) / 100).toFixed(2)
    this.objdata["C1521"] = ((Number(this.objdata["C1119"]) * Number(this.objdata["C1319"])) / 100).toFixed(2)

    this.objdata["C1423"] = ((Number(this.objdata["C1122"]) * Number(this.objdata["C1222"])) / 100).toFixed(2)
    this.objdata["C1524"] = ((Number(this.objdata["C1122"]) * Number(this.objdata["C1322"])) / 100).toFixed(2)

    this.objdata["C1426"] = ((Number(this.objdata["C1125"]) * Number(this.objdata["C1225"])) / 100).toFixed(2)
    this.objdata["C1527"] = ((Number(this.objdata["C1125"]) * Number(this.objdata["C1325"])) / 100).toFixed(2)

    this.objdata["C1429"] = ((Number(this.objdata["C1128"]) * Number(this.objdata["C1228"])) / 100).toFixed(2)
    this.objdata["C1530"] = ((Number(this.objdata["C1128"]) * Number(this.objdata["C1328"])) / 100).toFixed(2)

    this.objdata["C1432"] = ((Number(this.objdata["C1131"]) * Number(this.objdata["C1231"])) / 100).toFixed(2)
    this.objdata["C1533"] = ((Number(this.objdata["C1131"]) * Number(this.objdata["C1331"])) / 100).toFixed(2)

    this.objdata["C14ag"] = ((Number(this.objdata["C1401"]) + Number(this.objdata["C1408"]) +
      Number(this.objdata["C1417"]) + Number(this.objdata["C1420"]) +
      Number(this.objdata["C1423"]) + Number(this.objdata["C1426"]) + Number(this.objdata["C1429"]) +
      Number(this.objdata["C1432"]))).toFixed(0)
    this.objdata["C15ag"] = ((Number(this.objdata["C1502"]) + Number(this.objdata["C1509"]) +
      Number(this.objdata["C1518"]) + Number(this.objdata["C1521"]) +
      Number(this.objdata["C1524"]) + Number(this.objdata["C1527"]) + Number(this.objdata["C1530"]) +
      Number(this.objdata["C1533"]))).toFixed(0)
  }
  Calcvalue1() {
    this.objdata1["C2401"] = ((Number(this.objdata1["C2100"]) * Number(this.objdata1["C2200"])) / 100).toFixed(2)
    this.objdata1["C2502"] = ((Number(this.objdata1["C2100"]) * Number(this.objdata1["C2300"])) / 100).toFixed(2)
    this.objdata1["C24ag"] = this.objdata1["C2401"]
    this.objdata1["C25ag"] = this.objdata1["C2502"]
  }

  Calcvalue2() {
    this.objdata2["C3401"] = ((Number(this.objdata2["C3100"]) * Number(this.objdata2["C3200"])) / 100).toFixed(2)
    this.objdata2["C3502"] = ((Number(this.objdata2["C3100"]) * Number(this.objdata2["C3300"])) / 100).toFixed(2)

    this.objdata2["C3404"] = ((Number(this.objdata2["C3103"]) * Number(this.objdata2["C3203"])) / 100).toFixed(2)
    this.objdata2["C3505"] = ((Number(this.objdata2["C3103"]) * Number(this.objdata2["C3303"])) / 100).toFixed(2)

    this.objdata2["C3407"] = ((Number(this.objdata2["C3106"]) * Number(this.objdata2["C3206"])) / 100).toFixed(2)
    this.objdata2["C3508"] = ((Number(this.objdata2["C3106"]) * Number(this.objdata2["C3306"])) / 100).toFixed(2)

    this.objdata2["C3410"] = ((Number(this.objdata2["C3109"]) * Number(this.objdata2["C3209"])) / 100).toFixed(2)
    this.objdata2["C3511"] = ((Number(this.objdata2["C3109"]) * Number(this.objdata2["C3309"])) / 100).toFixed(2)

    this.objdata2["C3413"] = ((Number(this.objdata2["C3112"]) * Number(this.objdata2["C3212"])) / 100).toFixed(2)
    this.objdata2["C3514"] = ((Number(this.objdata2["C3112"]) * Number(this.objdata2["C3312"])) / 100).toFixed(2)

    this.objdata2["C3416"] = ((Number(this.objdata2["C3115"]) * Number(this.objdata2["C3215"])) / 100).toFixed(2)
    this.objdata2["C3517"] = ((Number(this.objdata2["C3115"]) * Number(this.objdata2["C3315"])) / 100).toFixed(2)

    this.objdata2["C3418"] = ((Number(this.objdata2["C3118"]) * Number(this.objdata2["C3218"])) / 100).toFixed(2)
    this.objdata2["C3518"] = ((Number(this.objdata2["C3118"]) * Number(this.objdata2["C3318"])) / 100).toFixed(2)

    this.objdata2["C34ag"] = this.objdata2["C3501"]
    this.objdata2["C35ag"] = this.objdata2["C3502"]

    this.objdata2["C34ag"] = ((Number(this.objdata2["C3401"]) + Number(this.objdata2["C3404"]) +
      Number(this.objdata2["C3407"]) + Number(this.objdata2["C3410"]) + Number(this.objdata2["C3413"]) +
      Number(this.objdata2["C3416"]))).toFixed(0)
    this.objdata2["C35ag"] = ((Number(this.objdata2["C3502"]) + Number(this.objdata2["C3505"]) +
      Number(this.objdata2["C3508"]) + Number(this.objdata2["C3511"]) + Number(this.objdata2["C3514"]) +
      Number(this.objdata2["C3517"]) + Number(this.objdata2["C3518"]))).toFixed(0)
  }

  Calcvalue3() {
    this.objdata3["C4401"] = ((Number(this.objdata3["C4100"]) * Number(this.objdata3["C4200"])) / 100).toFixed(2)
    this.objdata3["C4502"] = ((Number(this.objdata3["C4100"]) * Number(this.objdata3["C4300"])) / 100).toFixed(2)

    this.objdata3["C4404"] = ((Number(this.objdata3["C4103"]) * Number(this.objdata3["C4203"])) / 100).toFixed(2)
    this.objdata3["C4505"] = ((Number(this.objdata3["C4103"]) * Number(this.objdata3["C4303"])) / 100).toFixed(2)

    this.objdata3["C4407"] = ((Number(this.objdata3["C4106"]) * Number(this.objdata3["C4206"])) / 100).toFixed(2)
    this.objdata3["C4508"] = ((Number(this.objdata3["C4106"]) * Number(this.objdata3["C4306"])) / 100).toFixed(2)

    this.objdata3["C4410"] = ((Number(this.objdata3["C4109"]) * Number(this.objdata3["C4209"])) / 100).toFixed(2)
    this.objdata3["C4511"] = ((Number(this.objdata3["C4109"]) * Number(this.objdata3["C4309"])) / 100).toFixed(2)

    this.objdata3["C4413"] = ((Number(this.objdata3["C4112"]) * Number(this.objdata3["C4212"])) / 100).toFixed(2)
    this.objdata3["C4514"] = ((Number(this.objdata3["C4112"]) * Number(this.objdata3["C4312"])) / 100).toFixed(2)

    this.objdata3["C4416"] = ((Number(this.objdata3["C4115"]) * Number(this.objdata3["C4215"])) / 100).toFixed(2)
    this.objdata3["C4517"] = ((Number(this.objdata3["C4115"]) * Number(this.objdata3["C4315"])) / 100).toFixed(2)

    this.objdata3["C4418"] = ((Number(this.objdata3["C4118"]) * Number(this.objdata3["C4218"])) / 100).toFixed(2)
    this.objdata3["C4518"] = ((Number(this.objdata3["C4118"]) * Number(this.objdata3["C4318"])) / 100).toFixed(2)

    this.objdata3["C44ag"] = this.objdata3["C4501"]
    this.objdata3["C45ag"] = this.objdata3["C4502"]

    this.objdata3["C44ag"] = ((Number(this.objdata3["C4401"]) + Number(this.objdata3["C4404"]) +
      Number(this.objdata3["C4407"]) + Number(this.objdata3["C4410"]) + Number(this.objdata3["C4413"]) +
      Number(this.objdata3["C4416"]))).toFixed(0)
    this.objdata3["C45ag"] = ((Number(this.objdata3["C4502"]) + Number(this.objdata3["C4505"]) +
      Number(this.objdata3["C4508"]) + Number(this.objdata3["C4511"]) + Number(this.objdata3["C4514"]) +
      Number(this.objdata3["C4517"]) + Number(this.objdata3["C4518"]))).toFixed(0)
  }

  Calcvalue4() {
    this.objdata4["C5100"] = ((Number(this.objdata4["C5100"]) * Number(this.objdata4["C5200"])) / 100).toFixed(2)
    this.objdata4["C5502"] = ((Number(this.objdata4["C5100"]) * Number(this.objdata4["C5300"])) / 100).toFixed(2)

    this.objdata4["C5404"] = ((Number(this.objdata4["C5103"]) * Number(this.objdata4["C5203"])) / 100).toFixed(2)
    this.objdata4["C5505"] = ((Number(this.objdata4["C5103"]) * Number(this.objdata4["C5303"])) / 100).toFixed(2)

    this.objdata4["C5407"] = ((Number(this.objdata4["C5106"]) * Number(this.objdata4["C5206"])) / 100).toFixed(2)
    this.objdata4["C5508"] = ((Number(this.objdata4["C5106"]) * Number(this.objdata4["C5306"])) / 100).toFixed(2)

    this.objdata4["C5410"] = ((Number(this.objdata4["C5109"]) * Number(this.objdata4["C5209"])) / 100).toFixed(2)
    this.objdata4["C5511"] = ((Number(this.objdata4["C5109"]) * Number(this.objdata4["C5309"])) / 100).toFixed(2)

    this.objdata4["C5413"] = ((Number(this.objdata4["C5112"]) * Number(this.objdata4["C5212"])) / 100).toFixed(2)
    this.objdata4["C5514"] = ((Number(this.objdata4["C5112"]) * Number(this.objdata4["C5312"])) / 100).toFixed(2)

    this.objdata4["C5416"] = ((Number(this.objdata4["C5115"]) * Number(this.objdata4["C5215"])) / 100).toFixed(2)
    this.objdata4["C5517"] = ((Number(this.objdata4["C5115"]) * Number(this.objdata4["C5315"])) / 100).toFixed(2)

    this.objdata4["C5418"] = ((Number(this.objdata4["C5118"]) * Number(this.objdata4["C5218"])) / 100).toFixed(2)
    this.objdata4["C5518"] = ((Number(this.objdata4["C5118"]) * Number(this.objdata4["C5318"])) / 100).toFixed(2)

    this.objdata4["C54ag"] = this.objdata4["C5501"]
    this.objdata4["C55ag"] = this.objdata4["C5502"]

    this.objdata4["C54ag"] = ((Number(this.objdata4["C5401"]) + Number(this.objdata4["C5404"]) +
      Number(this.objdata4["C5407"]) + Number(this.objdata4["C5410"]) + Number(this.objdata4["C5413"]) +
      Number(this.objdata4["C5416"]))).toFixed(0)
    this.objdata4["C55ag"] = ((Number(this.objdata4["C5502"]) + Number(this.objdata4["C5505"]) +
      Number(this.objdata4["C5508"]) + Number(this.objdata4["C5511"]) + Number(this.objdata4["C5514"]) +
      Number(this.objdata4["C5517"]) + Number(this.objdata4["C5518"]))).toFixed(0)
  }

  Calcvalue5() {

    //CAD :
    this.objdata5["C5119"] = ((Number(this.objdata5["C5138"]) * Number(8) * Number(3) * Number(1) * Number(this.objdata5["C5143"])))
    this.objdata5["C5160"] = ((Number(this.objdata5["C5138"]) * Number(12) * Number(3) * Number(2) * Number(this.objdata5["C5143"])))
    this.objdata5["C5161"] = ((Number(this.objdata5["C5138"]) * Number(16) * Number(3) * Number(2) * Number(this.objdata5["C5143"])))

    //USD :
    //this.objdata5["C5620"]=((Number(this.objdata5["C5138"])*Number(8)*Number(3)*Number(1)*Number(this.objdata5["C5143"])))
    //this.objdata5["C5613"]=((Number(this.objdata5["C5138"])*Number(12)*Number(3)*Number(2)*Number(this.objdata5["C5143"])))
    //this.objdata5["C5614"]=((Number(this.objdata5["C5138"])*Number(16)*Number(3)*Number(2)*Number(this.objdata5["C5143"])))
    this.objdata5["C5620"] = (Number(this.objdata5["C5119"]) * Number(0.74))
    this.objdata5["C5613"] = (Number(this.objdata5["C5160"]) * Number(0.74))
    this.objdata5["C5614"] = (Number(this.objdata5["C5161"]) * Number(0.74))

    //INR :
    // this.objdata5["C5188"]=((Number(this.objdata5["C5138"])*Number(8)*Number(3)*Number(1)*Number(this.objdata5["C5143"])))
    // this.objdata5["C5191"]=((Number(this.objdata5["C5138"])*Number(12)*Number(3)*Number(2)*Number(this.objdata5["C5143"])))
    // this.objdata5["C5619"]=((Number(this.objdata5["C5138"])*Number(16)*Number(3)*Number(2)*Number(this.objdata5["C5143"])))
    this.objdata5["C5188"] = (Number(this.objdata5["C5119"]) * Number(60.80))
    this.objdata5["C5191"] = (Number(this.objdata5["C5160"]) * Number(60.80))
    this.objdata5["C5619"] = (Number(this.objdata5["C5161"]) * Number(60.80))

    //INDO :
    this.objdata5["C5624"] = (Number(this.objdata5["C5119"]) * Number(11704.14))
    this.objdata5["C5658"] = (Number(this.objdata5["C5160"]) * Number(11704.14))
    this.objdata5["C5660"] = (Number(this.objdata5["C5161"]) * Number(11704.14))


    //malaysia :
    this.objdata5["C5692"] = (Number(this.objdata5["C5119"]) * Number(3.34))
    this.objdata5["C5694"] = (Number(this.objdata5["C5160"]) * Number(3.34))
    this.objdata5["C5695"] = (Number(this.objdata5["C5161"]) * Number(3.34))

    //THAI :
    this.objdata5["C5657"] = (Number(this.objdata5["C5119"]) * Number(26.51))
    this.objdata5["C5659"] = (Number(this.objdata5["C5160"]) * Number(26.51))
    this.objdata5["C5670"] = (Number(this.objdata5["C5161"]) * Number(26.51))

    //SINGAPORE :
    this.objdata5["C5727"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5733"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5736"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //TAIWAN :
    this.objdata5["C5728"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5734"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5737"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //SOUTH KOR :
    this.objdata5["C5729"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5735"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5738"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //EAN :
    this.objdata5["C5772"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5782"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5787"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //VIETNAMESE :
    this.objdata5["C5773"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5783"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5788"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //BRAZILIAN :
    this.objdata5["C5774"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5733"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5736"] = (Number(this.objdata5["C5161"]) * Number(1.02))

    //EURO :
    this.objdata5["C5775"] = (Number(this.objdata5["C5119"]) * Number(1.02))
    this.objdata5["C5733"] = (Number(this.objdata5["C5160"]) * Number(1.02))
    this.objdata5["C5736"] = (Number(this.objdata5["C5161"]) * Number(1.02))


    this.objdata5["C5120"] = (150 * 2 * Number(this.objdata5["C5143"]))

    this.objdata5["C5221"] = (150 * 3 * Number(this.objdata5["C5143"]))

    this.objdata5["C5142"] = (150 * 3 * Number(this.objdata5["C5143"]))

    this.objdata5["C5147"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5146"] = (Number(2437)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5145"] = (Number(1358)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5144"] = (Number(978)) * (Number(this.objdata5["C5143"]))

    this.objdata5["C5601"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5603"] = (Number(2437)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5604"] = (Number(1358)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5605"] = (Number(978)) * (Number(this.objdata5["C5143"]))

    this.objdata5["C5602"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5600"] = (Number(2437)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5199"] = (Number(1358)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5198"] = (Number(978)) * (Number(this.objdata5["C5143"]))

    this.objdata5["C5125"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"]) - Number(this.objdata5["C5221"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"])) * 100).toFixed(0)

    this.objdata5["C5155"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"]) - Number(this.objdata5["C5144"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"])) * 100).toFixed(0)
    this.objdata5["C5156"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"]) - Number(this.objdata5["C5145"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5119"])) * 100).toFixed(0)
    this.objdata5["C5157"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5160"]) - Number(this.objdata5["C5146"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5160"])) * 100).toFixed(0)
    this.objdata5["C5158"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5161"]) - Number(this.objdata5["C5147"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5161"])) * 100).toFixed(0)


  }

  Calcvalue6() {

    this.objdata6['C5130'] = ((Number(this.objdata6['C5126']) + Number(this.objdata6['C5127']) + Number(this.objdata6['C5128'])))
    this.objdata6['C5141'] = ((Number(this.objdata6['C5138']) + Number(this.objdata6['C5139']) + Number(this.objdata6['C5140'])))
    this.objdata6['C5148'] = ((Number(this.objdata6['C5142']) + Number(this.objdata6['C5144']) + Number(this.objdata6['C5145'])))
    this.objdata6['C5640'] = ((Number(this.objdata6['C5637']) + Number(this.objdata6['C5638']) + Number(this.objdata6['C5639'])))
    this.objdata6['C5648'] = ((Number(this.objdata6['C5645']) + Number(this.objdata6['C5646']) + Number(this.objdata6['C5647'])))
    this.objdata6['C5715'] = ((Number(this.objdata6['C5711']) + Number(this.objdata6['C5712']) + Number(this.objdata6['C5713'])))

    this.objdata6['C5229'] = (Number(this.objdata6['C5228']))
    this.objdata6['C5143'] = (Number(this.objdata6['C5147']))
    this.objdata6['C5149'] = (Number(this.objdata6['C5146']))
    this.objdata6['C5642'] = (Number(this.objdata6['C5641']))
    this.objdata6['C5643'] = (Number(this.objdata6['C5644']))
    this.objdata6['C5716'] = (Number(this.objdata6['C5714']))

    this.objdata6['C5137'] = (((Number(this.objdata6['C5130']) - Number(this.objdata6['C5229'])) / Number(this.objdata6['C5130'])) * 100).toFixed(0)
    this.objdata6['C5165'] = (((Number(this.objdata6['C5141']) - Number(this.objdata6['C5143'])) / Number(this.objdata6['C5141'])) * 100).toFixed(0)
    this.objdata6['C5164'] = (((Number(this.objdata6['C5148']) - Number(this.objdata6['C5149'])) / Number(this.objdata6['C5148'])) * 100).toFixed(0)
    this.objdata6['C5686'] = (((Number(this.objdata6['C5640']) - Number(this.objdata6['C5642'])) / Number(this.objdata6['C5640'])) * 100).toFixed(0)
    this.objdata6['C5687'] = (((Number(this.objdata6['C5648']) - Number(this.objdata6['C5643'])) / Number(this.objdata6['C5648'])) * 100).toFixed(0)
    this.objdata6['C5688'] = (((Number(this.objdata6['C5715']) - Number(this.objdata6['C5716'])) / Number(this.objdata6['C5715'])) * 100).toFixed(0)

    this.objdata6['C5230'] = ((Number(this.objdata6['C5229']) / (Number(this.objdata6['C5130']) / Number(5)))).toFixed(2)
    this.objdata6['C5166'] = ((Number(this.objdata6['C5149']) / (Number(this.objdata6['C5148']) / Number(5)))).toFixed(2)
    this.objdata6['C5167'] = ((Number(this.objdata6['C5143']) / (Number(this.objdata6['C5141']) / Number(5)))).toFixed(2)
    this.objdata6['C5689'] = ((Number(this.objdata6['C5642']) / (Number(this.objdata6['C5640']) / Number(5)))).toFixed(2)
    this.objdata6['C5690'] = ((Number(this.objdata6['C5643']) / (Number(this.objdata6['C5648']) / Number(5)))).toFixed(2)
    this.objdata6['C5691'] = ((Number(this.objdata6['C5716']) / (Number(this.objdata6['C5715']) / Number(5)))).toFixed(2)
  }

  Calcvalue7() {

    this.objdata7['C5136'] = (Number(this.objdata7['C5131']) + Number(this.objdata7['C5132']) + Number(this.objdata7['C5133']) + Number(this.objdata7['C5134']))
    this.objdata7['C5175'] = (Number(this.objdata7['C5168']) + Number(this.objdata7['C5171']) + Number(this.objdata7['C5172']) + Number(this.objdata7['C5173']))
    this.objdata7['C5181'] = (Number(this.objdata7['C5169']) + Number(this.objdata7['C5177']) + Number(this.objdata7['C5178']) + Number(this.objdata7['C5179']))
    this.objdata7['C5633'] = (Number(this.objdata7['C5629']) + Number(this.objdata7['C5630']) + Number(this.objdata7['C5631']) + Number(this.objdata7['C5632']))
    this.objdata7['C5653'] = (Number(this.objdata7['C5649']) + Number(this.objdata7['C5650']) + Number(this.objdata7['C5651']) + Number(this.objdata7['C5652']))
    this.objdata7['C5721'] = (Number(this.objdata7['C5717']) + Number(this.objdata7['C5718']) + Number(this.objdata7['C5719']) + Number(this.objdata7['C5720']))

    this.objdata7['C5236'] = (Number(this.objdata7['C5235']) + Number(this.objdata7['C5231']))
    this.objdata7['C5182'] = (Number(this.objdata7['C5174']) + Number(this.objdata7['C5170']))
    this.objdata7['C5183'] = (Number(this.objdata7['C5180']) + Number(this.objdata7['C5176']))
    this.objdata7['C5636'] = (Number(this.objdata7['C5634']) + Number(this.objdata7['C5635']))
    this.objdata7['C5656'] = (Number(this.objdata7['C5654']) + Number(this.objdata7['C5655']))
    this.objdata7['C5722'] = (Number(this.objdata7['C5723']) + Number(this.objdata7['C5724']))


    this.objdata7['C5141'] = (((Number(this.objdata7['C5136']) - Number(this.objdata7['C5236'])) / Number(this.objdata7['C5136'])) * 100).toFixed(0)
    this.objdata7['C5184'] = (((Number(this.objdata7['C5175']) - Number(this.objdata7['C5182'])) / Number(this.objdata7['C5175'])) * 100).toFixed(0)
    this.objdata7['C5185'] = (((Number(this.objdata7['C5181']) - Number(this.objdata7['C5183'])) / Number(this.objdata7['C5181'])) * 100).toFixed(0)
    this.objdata7['C5682'] = (((Number(this.objdata7['C5633']) - Number(this.objdata7['C5636'])) / Number(this.objdata7['C5633'])) * 100).toFixed(0)
    this.objdata7['C5683'] = (((Number(this.objdata7['C5653']) - Number(this.objdata7['C5656'])) / Number(this.objdata7['C5653'])) * 100).toFixed(0)
    this.objdata7['C5725'] = (((Number(this.objdata7['C5721']) - Number(this.objdata7['C5722'])) / Number(this.objdata7['C5721'])) * 100).toFixed(0)

    this.objdata7['C5237'] = (Number(this.objdata7['C5235']) / (((Number(this.objdata7['C5131']) - Number(this.objdata7['C5231'])) + Number(this.objdata7['C5132']) + Number(this.objdata7['C5133']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)
    this.objdata7['C5186'] = (Number(this.objdata7['C5174']) / (((Number(this.objdata7['C5168']) - Number(this.objdata7['C5170'])) + Number(this.objdata7['C5171']) + Number(this.objdata7['C5172']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)
    this.objdata7['C5187'] = (Number(this.objdata7['C5180']) / (((Number(this.objdata7['C5169']) - Number(this.objdata7['C5176'])) + Number(this.objdata7['C5177']) + Number(this.objdata7['C5178']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)
    this.objdata7['C5684'] = (Number(this.objdata7['C5635']) / (((Number(this.objdata7['C5629']) - Number(this.objdata7['C5634'])) + Number(this.objdata7['C5630']) + Number(this.objdata7['C5631']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)
    this.objdata7['C5685'] = (Number(this.objdata7['C5655']) / (((Number(this.objdata7['C5649']) - Number(this.objdata7['C5654'])) + Number(this.objdata7['C5650']) + Number(this.objdata7['C5651']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)
    this.objdata7['C5726'] = (Number(this.objdata7['C5724']) / (((Number(this.objdata7['C5717']) - Number(this.objdata7['C5723'])) + Number(this.objdata7['C5718']) + Number(this.objdata7['C5719']) + Number(this.objdata7['C5134'])) / Number(3))).toFixed(2)

  }

  Calcvalue8() {

    this.objdata5["C5139"] = ((Number(this.objdata5["C5138"]) * Number(8) * Number(5) * Number(1)) * Number(this.objdata5["C5143"]))
    this.objdata5["C5162"] = ((Number(this.objdata5["C5138"]) * Number(12) * Number(5) * Number(2) * Number(this.objdata5["C5143"])))
    this.objdata5["C5163"] = ((Number(this.objdata5["C5138"]) * Number(16) * Number(5) * Number(2) * Number(this.objdata5["C5143"])))

    this.objdata5["C5192"] = ((Number(this.objdata5["C5138"]) * Number(8) * Number(5) * Number(1)) * Number(this.objdata5["C5143"]))
    this.objdata5["C5193"] = ((Number(this.objdata5["C5138"]) * Number(8) * Number(5) * Number(1)) * Number(this.objdata5["C5143"]))

    this.objdata5["C5140"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5221"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)

    this.objdata5["C5141"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5148"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)
    this.objdata5["C5152"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5149"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)
    this.objdata5["C5153"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5150"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)
    this.objdata5["C5154"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5151"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)


    this.objdata5["C5120"] = (150 * 2 * Number(this.objdata5["C5143"]))
    this.objdata5["C5610"] = (150 * 2 * Number(this.objdata5["C5143"]))
    this.objdata5["C5189"] = (150 * 2 * Number(this.objdata5["C5143"]))

    this.objdata5["C5148"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5149"] = (Number(2266)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5150"] = (Number(4061)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5151"] = (Number(5886)) * (Number(this.objdata5["C5143"]))

    this.objdata5["C5606"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5607"] = (Number(2266)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5608"] = (Number(4061)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5609"] = (Number(5886)) * (Number(this.objdata5["C5143"]))

    this.objdata5["C5194"] = (Number(1630)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5195"] = (Number(2266)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5196"] = (Number(4061)) * (Number(this.objdata5["C5143"]))
    this.objdata5["C5197"] = (Number(5886)) * (Number(this.objdata5["C5143"]))
  }

  Calcvalue9() {

    this.objdata5["C5139"] = ((Number(this.objdata5["C5138"]) * Number(8) * Number(5)))

    this.objdata5["C5140"] = (((Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"]) - Number(this.objdata5["C5144"]))) / (Number(this.objdata5["C5120"]) + Number(this.objdata5["C5139"])) * 100).toFixed(0)
  }

  text() {
    //  document.getElementById("mycode").style.display="block";
    //  document.getElementById("mycode").style.display="none";
    // return;

    this.showMe = !this.showMe
    this.showMenothing = false

  }
  doToggle() {
    this.showMe = false
    this.showMenothing = !this.showMenothing
  }
  resulthp1() {
    this.showMeHP1 = true
  }

  toggle() {
    var cont = document.getElementById('cont');
    if (cont.style.display == 'block') {
      cont.style.display = 'none';
    }
    else {
      cont.style.display = 'block';
    }
  }

  dropsel1() {
    //this.showMenothing= false
    this.showMemore = true
    this.CEWtable1 = false
    this.CEWtable2 = true
  }
  dropsel2() {
    //this.showMemore= false
    this.showMenothing = true
    this.CEWtable2 = false
    this.CEWtable1 = true
  }
  dropsel3() {
    this.showMeHP1 = true
    this.CEWtable3 = true
  }

  dropsel4() {
    this.showMenothing = true
    this.CEWtable3 = false
  }

  dropsel5() {
    this.Jsondata['C5138'] = (Number(30))
  }

  keysToSave: string[] = [
    "Jsondata",
    "Projecttype",
    "fillpage",
    "prdetail",
    "disablescomm",
    "Service1",
    "Service2",
    "Service3",
    "Service4",
    "hint1",
    "hint2",
    "hint3",
    "disableppro",
    "disablepess",
    "disablessm",
    "disablepupgrad",
    "disablecew",
    "disabletrain",
    "disablescon",
    "standard",
    "configured",
    "disablestd",
    "showMe",
    "showMeHP1",
    "showMenothing",
    "smrtstr",
    "showMemore",
    "CEWtable1",
    "CEWtable2",
    "CEWtable3",
    "selectedValued",
    "selectedValues",
    "selectedValuetrn",
    "selectedValuecnvt",
    "selectedValuecrncy",
    "yearsForActivePerformanceManagement",
    "hoursForResponseTime",
    "_values1",
    "_values2",
    "selectedHp",
    "product",
    "crncy",
    "curncy",
    "selectedValue",
    "currentPrice",
    "currentPrice1",
    "partsPrice",
    "currentHpPrice",
    "currentHpPrice1",
    "currentHpPrice2",
    "currentHpPrice3",
    "currentHpPrice4",
    "currentHpPrice5",
    "serviceCost",
    "opportunityCost",
    "englossCost",
    "trainingCost",
    "total",
    "serviceTraining",
    "energy1",
    "energy2",
    "planMaint",
    "unplanMaint",
    "downtime",
    "serContract",
    "contStatusQuo",
    "serviceCont",
    "objdata",
    "objdata1",
    "objdata2",
    "objdata3",
    "objdata4",
    "objdata5",
    "objdata6",
    "objdata7",
    "widgetsContent",
    "selectedExtendedWarrantyEntitlement",

    "inputTable",
    "printOutputTable",
    "warrantyPricingTable",
    "responseTimePremiumTable",
    "apmServiceContractQuoteTable",
    "costCustomerCouldIncurredTable",
  ];

  saveAll() {
    this.processForm();
    this.formSaver.saveAll();
  }

  restoreFormData(formData: object) {
    if (formData) {
      for (const key of this.keysToSave) {
        if (!formData.hasOwnProperty(key))
          continue;

        const objToSave = this[key];
        if (objToSave instanceof CachedProperties) {
          objToSave.self.overridedValues = formData[key];
          objToSave.self.clearCache();
        } else {
          this[key] = formData[key];
        }
      }

      this.processForm();
      this.cdRef.markForCheck();
    }
  }

  saveDataToProcesssService() {
    const fieldsToSave = {};

    for (const key of this.keysToSave) {
      const objToSave = this[key];
      if (objToSave instanceof CachedProperties) {
        fieldsToSave[key] = objToSave.self.overridedValues
      } else {
        fieldsToSave[key] = objToSave;
      }
    }

    this.processsservice.calculationViewInfo = fieldsToSave;
  }

  processForm() {
    this.savedata();
    this.Calcvalue();
    this.Calcvalue2();
    this.Calcvalue3();

    this.saveDataToProcesssService();

    localStorage.setItem('training1', JSON.stringify(this.serviceCost));
    localStorage.setItem('training2', JSON.stringify(this.opportunityCost));
    localStorage.setItem('training3', JSON.stringify(this.englossCost));
    localStorage.setItem('training4', JSON.stringify(this.trainingCost));

    localStorage.setItem('contract1', JSON.stringify(this.printOutputTable.energyCost_StatusQuo));
    localStorage.setItem('contract2', JSON.stringify(this.printOutputTable.energyCost_Armstrong));
    localStorage.setItem('contract3', JSON.stringify(this.printOutputTable.plannedMaintenance_StatusQuo));
    localStorage.setItem('contract4', JSON.stringify(this.printOutputTable.unplannedMaintenance_StatusQuo));
    localStorage.setItem('contract5', JSON.stringify(this.printOutputTable.trainingDowntime_StatusQuo));
    localStorage.setItem('contract6', JSON.stringify(this.printOutputTable.apmServiceContract_Armstrong));

    localStorage.setItem('cew1', JSON.stringify(this.currentPrice));
    localStorage.setItem('cew2', JSON.stringify(this.partsPrice));
  }


  back() {
    this.fillpage = false
    this.router.navigate(['../costeval'])
  }

  rout() {
    this.processForm();
    this.router.navigate(['../report'])
  }

  savedata() {
    if (this.Projecttype == "existingbuilding") {
      this.processsservice.Projecttype = "existingbuilding"
      this.processsservice.objdata_old_1 = this.objdata
      this.processsservice.objdata1_old_1 = this.objdata1
      this.processsservice.objdata2_old_1 = this.objdata2
      this.processsservice.objdata3_nw_1 = this.objdata3
    }
    else if (this.Projecttype == "newbuilding") {
      this.processsservice.objdata_nw_1 = this.objdata
      this.processsservice.objdata1_nw_1 = this.objdata1
      this.processsservice.objdata2_nw_1 = this.objdata2
      this.processsservice.objdata3_nw_1 = this.objdata3
    }
    else { }
    console.log("See", this.processsservice.objdata_nw_1)
    if (Object.keys(this.processsservice.objdata_nw_1).length === 0) {
      console.log("check", this.processsservice.objdata_nw_1)
      this.processsservice.objdata_nw_1 = this.processsservice.objdata_nw
      this.processsservice.objdata1_nw_1 = this.processsservice.objdata1_nw
      this.processsservice.objdata2_nw_1 = this.processsservice.objdata2_nw
      this.processsservice.objdata3_nw_1 = this.processsservice.objdata3_nw
      console.log("check2", this.processsservice.objdata_nw_1)
    }
  }

  openModalTable() {
    this.modalTableService.openModal();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  User_guide() {

    this.router.navigate(['../User_guide'])
  }

  commselec1() {

  }


}
// function getExchangeRate(selectedValue: any): any {
//   throw new Error('Function not implemented.');
// }

// function getCcySymbol(selectedValue: any): any {
//   throw new Error('Function not implemented.');
// }


function getExchangeRate(selectCCY: string) {
  return currenciesData.filter((d) => d.ccyIndex === selectCCY).map((res) => res.rate);
}

function getCcySymbol(selectCCY: string) {
  return currenciesData.filter((d) => d.ccyIndex === selectCCY).map((res) => res.symbol);
}

