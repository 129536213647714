<!-- <head><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head> -->

  <div  class="top-bar">
    <button class="blue-button1" (click)="openModalTable()">Open Report</button>
    <button class="blue-button1" (click)="saveAll()">Save Report</button>
    <a class="blue-button1" [routerLink]="['/User_guide']" target="_blank">User-Guide</a>
  </div>
  <div *ngIf="fillpage" class='calcontainer'>
    <br> <br>

    <div class="bdy">
      <div class="btn-div" style="display: none;">
        <label style="display: none;">Select Project Table</label><br>
        <select style="display: none;" class="detailtab" (change)="Projectselec()" [(ngModel)]="Projecttype">
          <option selected value="existingbuilding">Existing Building</option>
          <option value="newbuilding">New Building</option>
        </select>

      </div>
      <button style="display: inline-block;" class="blue-button buttongen2" (click)="reset()">Reset</button>
      <h5 style="display: inline-block;">Fault/Degradation Definitions</h5>
      <br><br>
      <mat-tab-group mat-align-tabs="left">

        <mat-tab *ngIf="disableppro" label="Pump Manager Professional">
          <jw-modal id="custom-modal-1">
            <table>
              <tr>
                <td>
                  <h3>Possible Impact</h3>
                </td>
                <td style="align-content: flex-end;">
                </td>
              </tr>
              <tr>
                <td>&bull; Off-BEP Operation</td>
                <td>&bull; Degradation of Bearings</td>
              </tr>
              <tr>
                <td>&bull; Degradation of Seals</td>
                <td>&bull; Degradation of Casings</td>
              </tr>
            </table>
          </jw-modal>
          <jw-modal id="custom-modal-2">
            <table>
              <tr>
                <td>
                  <h3>Possible Impact</h3>
                </td>
                <td style="align-content: flex-end;">
                </td>
              </tr>
              <tr>
                <td>&bull; Degradation of Seals</td>
                <td>&bull; Oversized Equipment</td>
              </tr>
            </table>
          </jw-modal>
          <jw-modal id="custom-modal-3">
            <table>
              <tr>
                <td>
                  <h3>Possible Impact</h3>
                </td>
                <td style="align-content: flex-end;">
                </td>
              </tr>
              <tr>
                <td>&bull; Degradation of Bearnings</td>
                <td>&bull; Degradation of Seals</td>
              </tr>
            </table>
          </jw-modal>
          <br>
          <label class="not">Note: Enter the details of expected or observed instances for below fault types</label>
          <br><br>
          <table class="paramtable" (change)="Calcvalue()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation<i>(%)</i></td>
              <td class="head2">Frequency of Occurence / Causing Energy Waste<i>(%)</i></td>
              <td class="head2">Energy Saved (Annualized life time savings)<i>(%)</i></td>
              <td class="head2">Maintenance Saved (As a % of pump cost)<i>(%)</i></td>
              <td class="head2">Persistent Energy Savings<i>(%)</i></td>
              <td class="head2">Persistent Maintenance Savings<i>(%)</i></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Pump in Hand</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1100']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1200']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1300']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1400']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1500']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1101']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1201']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1301']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1401']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1501']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
                <i class='iconcls' (click)="openModal('custom-modal-1')" *ngIf="!hint1" [tooltip]="HtmlContent"
                  content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
                  class="fa">&#xf05a;</i>
                <i class='iconcls' (click)="hint1 = false" *ngIf="hint1" [tooltip]="HtmlContent2"
                  content-type="template" placement="top" showDelay="500" style="font-size:12px; color:red"
                  class="fa">&#xf2d1;</i>
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1102']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1202']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1302']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1402']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1502']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Dead Heading</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1107']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1207']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1307']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1407']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1507']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1108']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1208']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1308']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1408']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1508']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
                <i class='iconcls' (click)="openModal('custom-modal-2')" *ngIf="!hint1" [tooltip]="HtmlContent"
                  content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
                  class="fa">&#xf05a;</i>
                <i class='iconcls' (click)="hint2 = false" *ngIf="hint2" [tooltip]="HtmlContent2"
                  content-type="template" placement="top" showDelay="500" style="font-size:12px; color:red"
                  class="fa">&#xf2d1;</i>

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1109']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1209']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1309']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1409']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1509']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Over Sized Equipment</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1125']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1225']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1325']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1425']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1525']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1126']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1226']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1326']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1426']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1526']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
                <i class='iconcls' (click)="openModal('custom-modal-3')" *ngIf="!hint1" [tooltip]="HtmlContent"
                  content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
                  class="fa">&#xf05a;</i>
                <i class='iconcls' (click)="hint3 = false" *ngIf="hint3" [tooltip]="HtmlContent2"
                  content-type="template" placement="top" showDelay="500" style="font-size:12px; color:red"
                  class="fa">&#xf2d1;</i>

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1127']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1227']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1327']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1427']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1527']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">PSPC Mode</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1116']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1216']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1316']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1416']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1516']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1117']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1217']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1317']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1417']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1517']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1118']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1218']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1318']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1418']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1518']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Auto-flow Balancing</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1119']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1219']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1319']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1419']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1519']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1120']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1220']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1320']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1420']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1520']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1121']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1221']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1321']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1421']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1521']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">System Resistance</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1122']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1222']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1322']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1422']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1522']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1123']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1223']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1323']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1423']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1523']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1124']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1224']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1324']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1424']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1524']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Maximum Flow Control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1128']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1228']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1328']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1428']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1528']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1129']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1229']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1329']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1429']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1529']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1130']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1230']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1330']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1430']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1530']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Minimum Flow Control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1131']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1231']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C1331']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1431']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1531']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1132']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1232']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1332']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1432']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1532']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1133']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1233']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1333']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C1433']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C1533']"></td>
            </tr>
            <tr>
              <td class="tabbottom" colspan="4">Aggregated Savings(%)
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata['C14ag']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata['C15ag']"></td>
            </tr>


          </table>
        </mat-tab>
        <mat-tab *ngIf="disablepess" label="Pump Manager Essential">
          <label style="width: 50%; margin-left: 50%;padding-left: 5%;font-weight: bolder;">Note: All Fields with an
            asterisk(*) are Mandatory</label>
          <br>
          <table class="paramtable" (change)="Calcvalue1()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation</td>
              <td class="head2">Frequency of Occurence / Causing Energy Waste</td>
              <td class="head2">Energy Saved (Annualized life time savings)</td>
              <td class="head2">Maintenance Saved (As a % of pump cost)</td>
              <td class="head2">Persistent Energy Savings</td>
              <td class="head2">Persistent Maintenance Savings</td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Pump in Hand</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2100']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2200']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2300']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2400']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2500']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2101']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2201']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2301']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata1['C2401']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2501']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2102']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2202']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2302']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2402']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata1['C2502']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Dead Heading</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2103']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2203']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata1['C2303']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2403']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2503']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2104']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2204']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2304']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata1['C2404']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C25041508']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2105']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2205']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2305']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata1['C2405']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata1['C2505']"></td>
            </tr>
            <tr>
              <td class="tabbottom" colspan="4">Aggregated Savings(%)
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata1['C24ag']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata1['C25ag']"></td>
            </tr>
          </table>
        </mat-tab>
        <mat-tab *ngIf="disablessm" label="Smart Startup">
          <br>
          <label class="not">Note: Enter the details of expected or observed instances for below fault types</label>
          <br><br>
          <table class="paramtable" (change)="Calcvalue2()">
            <tr class="tabheader">
              <td class="head2 ">Operation Fault / Degradation</td>
              <td class="head2">Frequency of Occurence / Causing Energy Waste</td>
              <td class="head2">Energy Saved (Annualized life time savings)</td>
              <td class="head2">Maintenance Saved (As a % of pump cost)</td>
              <td class="head2">Persistent Energy Savings</td>
              <td class="head2">Persistent Maintenance Savings</td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Pump in Hand</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C5520']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C5530']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C5540']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5550']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5560']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5521']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5531']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5541']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C5551']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5561']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5522']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5532']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5542']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C5552']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C5562']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Dead Heading</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2107']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2207']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2307']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2407']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2507']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2108']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2208']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2308']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C2408']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2508']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
                <i class='iconcls' (click)="openModal('custom-modal-2')" *ngIf="!hint1" [tooltip]="HtmlContent"
                  content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
                  class="fa">&#xf05a;</i>
                <i class='iconcls' (click)="hint2 = false" *ngIf="hint2" [tooltip]="HtmlContent2"
                  content-type="template" placement="top" showDelay="500" style="font-size:12px; color:red"
                  class="fa">&#xf2d1;</i>

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2109']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2209']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2309']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2409']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C2509']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Over Sized Equipment</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2125']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2225']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata['C2325']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2425']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2525']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2126']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2226']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2326']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C2426']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2526']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
                <i class='iconcls' (click)="openModal('custom-modal-3')" *ngIf="!hint1" [tooltip]="HtmlContent"
                  content-type="template" placement="top" showDelay="500" style="font-size:17px; color:black"
                  class="fa">&#xf05a;</i>
                <i class='iconcls' (click)="hint3 = false" *ngIf="hint3" [tooltip]="HtmlContent2"
                  content-type="template" placement="top" showDelay="500" style="font-size:12px; color:red"
                  class="fa">&#xf2d1;</i>

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2127']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2227']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2327']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata['C2427']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata['C2527']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">PSPC Bundle</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3115']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3215']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3315']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3415']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3515']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3116']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3216']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3316']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3416']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3516']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3117']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3217']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3317']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3417']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3517']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Autoflow balancing</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3100']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3200']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3300']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3400']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3500']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3101']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3201']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3301']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3401']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3501']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3102']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3202']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3302']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3402']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3502']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Maximum flow control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3103']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3203']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3303']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3403']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3503']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3104']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3204']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3304']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3404']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3504']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3105']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3205']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3305']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3405']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3505']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Minimum flow control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3106']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3206']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3306']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3406']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3506']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3107']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3207']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3307']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3407']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3507']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3108']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3208']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3308']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3408']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3508']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Dual Season Setup</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3109']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3209']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3309']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3409']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3509']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3110']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3210']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3310']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3410']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3510']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3111']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3211']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3311']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata2['C3411']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3511']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Asset Life Implication</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3118']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3218']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata2['C3318']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3418']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata2['C3518']"></td>
            </tr>
            <tr>
              <td class="tabbottom" colspan="4">Aggregated Savings(%)
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata2['C34ag']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata2['C35ag']"></td>
            </tr>


          </table>
        </mat-tab>
        <mat-tab *ngIf="disablescomm" class="labdis" label="Smart Commissioning">
          <br>
          <label class="not">Note: Enter the details of expected or observed instances for below fault types</label>
          <br><br>
          <table class="paramtable" (change)="Calcvalue3()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation</td>
              <td class="head2">Frequency of Occurence / Causing Energy Waste</td>
              <td class="head2">Energy Saved (Annualized life time savings)</td>
              <td class="head2">Maintenance Saved (As a % of pump cost)</td>
              <td class="head2">Persistent Energy Savings</td>
              <td class="head2">Persistent Maintenance Savings</td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">PSPC Bundle</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4115']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4215']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4315']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4415']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4515']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4116']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4216']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4316']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4416']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4516']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4117']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4217']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4317']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4417']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4517']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Autoflow balancing</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4100']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4200']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4300']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4400']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4500']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4101']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4201']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4301']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4401']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4501']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4102']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4202']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4302']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4402']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4502']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Maximum flow control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4103']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4203']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4303']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4403']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4503']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4104']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4204']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4304']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4404']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4504']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4105']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4205']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4305']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4405']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4505']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Minimum flow control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4106']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4206']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4306']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4406']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4506']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4107']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4207']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4307']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4407']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4507']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4108']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4208']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4308']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4408']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4508']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Dual Season Setup</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4109']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4209']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4309']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4409']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4509']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4110']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4210']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4310']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4410']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4510']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4111']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4211']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4311']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata3['C4411']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4511']"></td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Asset Life Implication</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4118']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4218']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata3['C4318']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4418']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata3['C4518']"></td>
            </tr>
            <tr>
              <td class="tabbottom" colspan="4">Aggregated Savings(%)
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata3['C44ag']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata3['C45ag']"></td>
            </tr>


          </table>
        </mat-tab>
        <mat-tab *ngIf="disablepupgrad" label="Performance Upgrade">
          <!-- <label style="width: 50%; margin-left: 50%;padding-left: 5%;font-weight: bolder;">any title</label> -->
          <br>
          <table class="paramtable" (change)="Calcvalue4()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation</td>
              <td class="head2">The probability that this feature is working to its potential (building is ready)</td>
              <td class="head2">Energy Saved (Annualized life time savings)</td>
              <td class="head2">Maintenance Saved (As a % of pump cost)</td>
              <td class="head2">Persistent Energy Savings</td>
              <td class="head2">Persistent Maintenance Savings</td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Auto-flow Balancing</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5100']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5200']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5300']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5400']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5500']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5101']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5201']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5301']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5401']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5501']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication

              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5102']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5202']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5302']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5402']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5502']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Maximum Flow Control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5103']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5203']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5303']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5403']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5503']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5104']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5204']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5304']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5404']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5504']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5105']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5205']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5305']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5405']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5505']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Minimum Flow Control</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5106']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5206']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5306']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5406']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5506']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5107']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5207']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5307']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5407']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5507']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5108']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5208']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5308']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5408']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5508']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Dual Season Setup</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5109']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5209']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5309']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5409']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5509']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5110']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5210']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5310']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5410']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5510']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5111']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5211']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5311']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5411']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5511']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Protection Bundle</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5112']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5212']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5312']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5412']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5512']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5113']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5213']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5313']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5413']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5513']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5114']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5214']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5314']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5413']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5514']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">PSPC Bundle</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5115']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5215']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5315']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5415']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5515']"></td>
            </tr>
            <tr>
              <td>Energy Implication</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5116']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5216']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5316']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5416']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5516']"></td>
            </tr>
            <tr>
              <td>Maintenance Implication
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5117']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5217']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5317']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5417']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5517']"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Asset Life Implications</td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5118']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5218']"></td>
              <td><input min="0.0" max="100" type="number" class="inputvalue" [(ngModel)]="objdata4['C5318']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata4['C5418']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue calcvalue"
                  [(ngModel)]="objdata4['C5518']"></td>
            </tr>

            <tr>
              <td class="tabbottom" colspan="4">Aggregated Savings(%)
              </td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata4['C54ag']"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="objdata4['C55ag']"></td>
            </tr>
          </table>
        </mat-tab>
        <mat-tab *ngIf="disablecew" label="Extended Warranty">
          <!-- <label style="width: 50%; margin-left: 50%;padding-left: 5%;font-weight: bolder;">choose Region first</label>  -->
          <!-- <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option selected>USA</option>
                        <option value="1">CANADA</option>
                        <option value="2">USA</option>
                        <option value="3">INDIA</option>
                        <option value="4">UK</option>
                        <option value="5">CE</option>
                        <option value="6">EU</option>
                        <option value="7">MEA (DUBAI)</option>
                        <option value="8">LATAM (BRAZIL)</option>
                        <option value="8">MEXICO</option>
                      </select> -->


          <br><br>

          <div *ngIf="disablecew" class="radiooptn">Extended Warranty Entitlement:

            <input class="radbtn" type="radio" name="selc" value="YES" (click)="text()" [(ngModel)]="selectedExtendedWarrantyEntitlement" />Standard

            <input class="radbtn" type="radio" name="selc" value="NO" (click)="doToggle()" [(ngModel)]="selectedExtendedWarrantyEntitlement" />Configured

          </div>
          <br><br>


          <div *ngIf="showMemore"><label class="not"></label>

          </div>

          <table *ngIf="CEWtable2" id="mycode" class="paramtable" (change)="Calcvalue8()">
            <table class="issueheadrow1" *ngIf="showMenothing">
              <td class="issuehead">Total CEW Contract Periods:</td>
              <td> <select (change)="dropsel2()" class="issuehead" id="yeardata" aria-label=".form-select-sm example">
                  <option value="3">3 years</option>
                  <option selected value="5">5 years</option>
                </select></td>

              <td class="issuehead">Put Your country labour cost:</td>
              <td><input style="background-color:white ;text-align: center;" min="50" max="1000" type="number"
                  class="inputvalue" [(ngModel)]="objdata5['C5138']" placeholder="4022.55"></td>

            </table><br><br>

            <table class="paramtable1">
              <tr class="issueheadrow1">
                <td class="issuehead">Number of pumps:</td>
                <td><input style="background-color:white ;text-align: center;" min="1" max="50" type="number"
                    class="inputvalue" [(ngModel)]="objdata5['C5143']" placeholder="1"></td>
              </tr>

              <tr class="issueheadrow1">
                <td class="issuehead">Average HP range:</td>
                <td> <select [(ngModel)]="selectedValue" class="issuehead" id="yeardata"
                    aria-label=".form-select-sm example">
                    <option selected value="0">0-10 HP (0-7.5 kW)</option>
                    <option value="1">11-20 HP (8-15 kW)</option>
                    <option value="2">21-50 HP (16-37 kW)</option>
                    <option value="3">51-75 HP (38-55 kW)</option>
                  </select></td>

                <td> <select [(ngModel)]="selectedValuecrncy" class="issuehead" id="yeardata"
                    aria-label=".form-select-sm example">
                    <option selected value="0">CAD</option>
                    <option value="1">USD</option>
                    <option value="2">INR</option>
                    <option value="4">MALAYSIAN RINGGITS</option>
                    <option value="3">INDONESIA RUPIAH</option>
                    <option value="5">THAILAND BAHT</option>
                    <option value="6">SINGAPORE DOLLAR</option>
                    <option value="7">TAIWAN DOLLAR</option>
                    <option value="8">SOUTH KOREAN WON</option>
                    <option value="9">VIETNAMESE DONG</option>
                    <option value="10">BRAZILIAN REAL</option>
                    <option value="11">EURO</option>
                    <option value="12">COLOMBIAN PESO</option>
                    <option value="13">COSTA RICAN COLON</option>
                    <option value="14">PERUVIAN SOL</option>
                    <option value="15">BAHRAINI DINAR</option>
                    <option value="16">EGYPTIAN POUND</option>
                    <option value="17">IRAQI DINAR</option>
                    <option value="18">KUWAITI DINAR</option>
                    <option value="19">OMANI RIAL</option>
                    <option value="20">QATARI RIYAL</option>
                    <option value="21">SAUDI RIYAL</option>
                    <option value="22">UAE DIRHAM</option>
                    <option value="23">MEXICAN PESO</option>
                    <option value="24">POUND STERLING</option>
                  </select></td>
                <!-- <select class="browser-default custom-select"  [(ngModel)]="selectedValue">
                <option value="0" selected>single</option>
                <option value="1">Weekly</option>
                <option value="2">Monthly</option>
                <option value="3">Yearly</option>
              </select> -->


              </tr>


              <!-- <tr class="issueheadrow1">


                                </tr> -->
            </table><br><br>
            <!-- <div class="paramtable" *ngIf="selectedValue == 0"><tr class="tabheader">
                                    <td class="head2">Operation Fault / Degradation</td>
                                    <td class="head2">Status Quo
                                    <td class="head2">Armstrong's CEW</td>


                                </tr>
                                <tr class="issueheadrow">
                                    <td class="issuehead">Labour cost over CEW Period</td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td>

                                </tr>
                                <tr >
                                    <td class="issuehead">Parts cost 1-year post normal warranty</td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5120']"></td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td>

                                </tr>


                                    <tr class="issueheadrow">
                                        <td class="issuehead">CEW Cost to cover all CEW Price</td>
                                        <td><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td>
                                        <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5148']"></td>
                                     </tr>

                                 <td class="tabbottom" colspan="2">Aggregated Savings(%)
                                </td>
                                 <td><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5140']" ></td>
            </div>


            <div class="paramtable" *ngIf="selectedValue == 1"><tr class="tabheader">
                                    <td class="head2">Operation Fault / Degradation</td>
                                    <td class="head2">Status Quo
                                    <td class="head2">Armstrong's CEW</td>


                                </tr>
                                <tr class="issueheadrow">
                                    <td class="issuehead">Labour cost over CEW Period</td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td>

                                </tr>
                                <tr >
                                    <td class="issuehead">Parts cost 1-year post normal warranty</td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5120']"></td>
                                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td>

                                </tr>
                                    <tr class="issueheadrow">
                                        <td class="issuehead">CEW Cost to cover all CEW Price</td>
                                        <td><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td>
                                        <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5149']"></td>
                                     </tr>

                              <td class="tabbottom" colspan="2">Aggregated Savings(%)
                              </td>
                              <td><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5140']" ></td>
             </div>


             <div class="paramtable" *ngIf="selectedValue == 2"><tr class="tabheader">
                                <td class="head2">Operation Fault / Degradation</td>
                                <td class="head2">Status Quo
                                <td class="head2">Armstrong's CEW</td>


                              </tr>
                              <tr class="issueheadrow">
                <td class="issuehead">Labour cost over CEW Period</td>
                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td>
                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td>

                              </tr>
                              <tr >
                <td class="issuehead">Parts cost 1-year post normal warranty</td>
                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5120']"></td>
                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td>

                              </tr>
                            <tr class="issueheadrow">
                    <td class="issuehead">CEW Cost to cover all CEW Price</td>
                    <td><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td>
                    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5149']"></td>
                             </tr>

                              <td class="tabbottom" colspan="2">Aggregated Savings(%)
                             </td>
                             <td><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5140']" ></td>
             </div>


             <div class="yearly" *ngIf="selectedValue == 3">yearly working fine</div> -->


            <tr class="paramtable">
              <td class="head2">Cost Contribution Metric</td>
              <td class="head2">Cost Estimate
                <!-- <td class="head2">Armstrong's CEW</td> -->


            </tr>

            <tr *ngIf="selectedValuecrncy == 0" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5162']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5163']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 1 " class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5192']"></td> -->
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5615']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5616']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 2" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5193']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5618']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5617']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 3" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5624']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 4" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5628']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 5" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5671']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>

            <tr *ngIf="selectedValuecrncy == 6" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5162']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5163']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 7 " class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5192']"></td> -->
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5615']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5616']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 8" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5193']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5618']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5617']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 9" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5624']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 10" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5628']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 11" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5671']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 12" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5162']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5163']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 13 " class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5192']"></td> -->
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5615']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5616']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 14" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5193']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5618']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5617']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 15" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5624']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 16" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5628']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 17" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5671']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 18" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5139']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5162']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5163']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 19 " class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <!-- <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5192']"></td> -->
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5192']"></td>
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5615']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5616']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 20" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValue || selectedValue == 0 || selectedValue == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5193']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5618']"></td>
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5617']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 21" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5624']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 22" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5628']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 23" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5671']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>
            <tr *ngIf="selectedValuecrncy == 24" class="issueheadrow">
              <td class="issuehead">Labour cost over CEW Period</td>
              <td *ngIf="!selectedValues || selectedValues == 0 || selectedValues == 1">{{curncy}}<input min="0.0"
                  max="1000" type="number" class="inputvalue" [(ngModel)]="objdata5['C5671']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td> -->
              <td *ngIf="selectedValues == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5191']"></td>
              <td *ngIf="selectedValues == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5619']"></td>
            </tr>




            <tr *ngIf="selectedValuecrncy == 0">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5120']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 1">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5610']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 2">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 3">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 4">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 5">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>

            <tr *ngIf="selectedValuecrncy == 6">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 7">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 8">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 9">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 10">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 11">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 12">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 13">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 14">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 15">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 16">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 17">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 18">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 19">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 20">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 21">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 22">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 23">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>
            <tr *ngIf="selectedValuecrncy == 24">
              <td class="issuehead">Parts cost 1-year post normal warranty</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5189']"></td>
              <!-- <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td> -->

            </tr>


            <br><br>


            <tr class="paramtable">
              <td class="head2">Cost Contribution Metric</td>
              <td class="head2">Cost Estimate
                <!-- <td class="head2">Armstrong's CEW</td> -->


            </tr>


            <tr *ngIf="selectedValuecrncy == 0" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5151']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5150']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5149']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5148']"></td>

            </tr>

            <tr *ngIf="selectedValuecrncy == 1" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5609']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5608']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5607']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5606']"></td>

            </tr>

            <tr *ngIf="selectedValuecrncy == 2" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 3" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 4" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 5" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>

            <tr *ngIf="selectedValuecrncy == 6" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 7" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 8" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 9" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 10" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 11" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 12" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 13" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 14" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 15" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 16" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 17" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 18" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 19" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 20" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 21" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 22" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 23" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>
            <tr *ngIf="selectedValuecrncy == 24" class="issueheadrow">
              <td class="issuehead">CEW Cost to cover all CEW Price</td>
              <!-- <td *ngIf="!selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <!-- <td *ngIf="!selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5142']"></td> -->

              <!-- <td class="issuehead" *ngIf="selectedValue == 3">CEW Cost to cover all CEW Price</td> -->
              <!-- <td *ngIf="selectedValue == 3"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 3">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5197']"></td>

              <!-- <td *ngIf="selectedValue == 2"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 2">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5196']"></td>

              <!-- <td *ngIf="selectedValue == 1"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 1">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5195']"></td>

              <!-- <td *ngIf="selectedValue == 0"><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td> -->
              <td *ngIf="selectedValue == 0">{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="objdata5['C5194']"></td>

            </tr>


            <!-- <div class="yearly" *ngIf="selectedValue == 3">
                                </div> -->


            <!-- <tr >
                                        <td class="issuehead">Downtime cost</td>
                                    </tr>
                                        <tr class="issueheadrow">
                                            <td class="issuehead">Impact of using non-genuine parts on Asset life</td>
                                            <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5122']"></td>
                                            <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5222']"></td>
        </tr>
                                            <tr >
                                                <td class="issuehead">Occupant comfort due to fast response</td>
                                                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5123']"></td>
                                                <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5223']"></td>

                    </tr> -->
            <td class="tabbottom" colspan="1">Aggregated Savings(%)
            </td>
            <!-- <td ><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5140']" ></td> -->
            <td *ngIf="selectedValue == 0"><input min="0.0" max="100" type="number" disabled="true"
                class="inputvalue finvalue" [(ngModel)]="objdata5['C5141']"></td>
            <td *ngIf="selectedValue == 1"><input min="0.0" max="100" type="number" disabled="true"
                class="inputvalue finvalue" [(ngModel)]="objdata5['C5152']"></td>
            <td *ngIf="selectedValue == 2"><input min="0.0" max="100" type="number" disabled="true"
                class="inputvalue finvalue" [(ngModel)]="objdata5['C5153']"></td>
            <td *ngIf="selectedValue == 3"><input min="0.0" max="100" type="number" disabled="true"
                class="inputvalue finvalue" [(ngModel)]="objdata5['C5154']"></td>
            <!-- <td *ngIf="selectedValuecrncy == 0"><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5141']" ></td>
                <td *ngIf="selectedValuecrncy == 1"><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5152']" ></td>
                <td *ngIf="selectedValuecrncy == 2"><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5153']" ></td>
               -->
          </table>


          <table *ngIf="showMe" id="mycode" class="paramtab">
            <tr class="tabheader">
              <td rowspan="2" class="head2"></td>
              <td rowspan="2" class="head2">Std Pump Warranty(months)</td>
              <td colspan="5" class="head2">incremental Warranty Entitlement with Armstrong Service Offering
                Purchase(months)</td>
            </tr>
            <tr class="tabheader">
              <!-- <td class="head2"></td> -->
              <td class="head2">Online Warranty Registration or Pump Manager (Essential/Professional)</td>
              <!-- <td class="head2">Pump Manager(Ess./Pro.)</td> -->
              <td class="head2">SMART Start-Up</td>
              <td class="head2">SMART Commissioning</td>
              <td class="head2">Performance Upgrade</td>
              <!-- <td class="head2"></td> -->

            </tr>
            <tr class="issueheadrow">
              <input type="hidden" #selection>
              <td class="issuehead">Offer Entitlement Level</td>

              <td> <input type="checkbox" [disabled]="true" name="pumpess" checked
                  (click)="selection.value = 'stdpw'"><label style="margin-left: 5px;" class="labl1"
                  for="pumpess"></label>
                <input min="0.0" max="100" type="number" class="inputvalue finvalue">18-Dec
              </td>

              <td> <input type="checkbox" [disabled]="disablepess" name="pumpess"
                  (click)="selection.value = 'onlwr'"><label style="margin-left: 5px;" class="labl1"
                  for="pumpess"></label>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">6
              </td>

              <!-- this works.. <td> <input  type="checkbox" [disabled]="true" [(ngModel)]= "Jsondata['pumps']" name="pumpprof" (click)="selection.value = 'prof'" ><label style="margin-left: 5px;" class="labl1" for="pumpprof"></label><br>
                <input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" >6

            </td> -->

              <td> <input type="checkbox" [disabled]="true" [(ngModel)]="Jsondata['smarts']" name="Startup"
                  (click)="selection.value = 'smarts'"><label style="margin-left: 5px;" class="labl1"
                  for="Startup"></label><br>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">6

              </td>

              <td> <input type="checkbox" [disabled]="true" [(ngModel)]="Jsondata['smartc']" name="Commiss"
                  (click)="selection.value = 'commis'">
                <label style="margin-left: 5px;" class="labl1" for="Commiss"> </label>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">6
              </td>

              <td> <input type="checkbox" [disabled]="true" [(ngModel)]="Jsondata['perfup']" name="perfupg"
                  (click)="selection.value = 'perfup'"><label style="margin-left: 5px;" class="labl1"
                  for="perfupg"></label><br>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">+12***

              </td>
            </tr>

            <tr class="issueheadrow">

              <td class="issuehead">Total Effective Warranty</td>

              <td>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">12/18
              </td>
              <td>
                <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">18/24*
              </td>
              <td> <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">18/24*
              </td>

              <td> <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">24/30

              </td>

              <td> <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">24/30
              </td>

              <td> <input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue">NA***

              </td>
            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Offer Entitlement Description</td>

              <td colspan="6" disabled="true" class="inputvalue finvalue">incremental Comprehensive (Parts + Labour)
                warranty offered when purchasing Armstrong Service offerings along with pump.<br><br>

                <label class="not">Note:
                </label>
                <p>* Avail either of 6months incremental warranty for Online Warranty Registration (applicable for
                  non-DE pumps also) OR Pump Manager activation (applicable only for DE Pumps). These incremental
                  warranties are not added together.</p>

                <p>** No incremental warranty entitlement for SMART Commissioning. However, SMART Startup having a 6
                  months' warranty entitlement is a component of SMART Commissioning. </p>

                <p>*** 12 months' additional warranty entitlement for Performance Upgrade comes into effect from the
                  time of field execution of Performance Upgrade contract, after standard warranty of Pump.</p>
              </td>


          </table><br>

          <!-- <div *ngIf="showMeHP1"><label class = "not"></label>

   </div>

   <table *ngIf="CEWtable3" id="mycode" class="paramtable" (change) = "Calcvalue9()">
    <table class="issueheadrow1" *ngIf="showMenothing">
        <td class="issuehead">Total CEW Contract Periods:</td>
        <td>  <select (change)="dropsel2()" class= "issuehead" id="yeardata"  aria-label=".form-select-sm example">
              <option selected>Total CEW Contract Periods:</option>
              <option selected value="3" >3 years</option>
              <option  value="5">5 years</option>
              </select></td>
          <td class="issuehead">Put Your country labour cost:</td>
          <td><input min="50" max="1000" type="number"  class="inputvalue" [(ngModel)]= "objdata5['C5138']" placeholder="65"   ></td>

      </table><br><br>

      <table>
        <tr class="issueheadrow1" >
        <td class="issuehead" >Number of pumps:</td>
        <td><input min="1" max="50" type="number"  class="inputvalue"  [(ngModel)]= "objdata5['C5143']" placeholder="1"   ></td>

    </tr>

    </table><br><br>

    <tr class="tabheader">
    <td class="head2">Operation Fault / Degradation</td>
    <td class="head2">Status Quo
    <td class="head2">Armstrong's CEW</td>


</tr>
<tr class="issueheadrow">
    <td class="issuehead">Labour cost over CEW Period</td>
    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5139']"></td>
    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5219']"></td>

</tr>
<tr >
    <td class="issuehead">Parts cost 1-year post normal warranty</td>
    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5120']"></td>
    <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5220']"></td>

</tr>
    <tr class="issueheadrow">
        <td class="issuehead" >CEW Cost to cover all CEW Price</td>
        <td><input min="0.0" max="1000" type="number"  class="inputvalue" disabled = "true"    [(ngModel)]= "objdata5['C5121']"></td>
        <td><input min="0.0" max="1000" type="number"  class="inputvalue"    [(ngModel)]= "objdata5['C5144']"></td>
     </tr>




<td class="tabbottom" colspan="2">Aggregated Savings(%)
</td>
<td><input min="0.0" max="100" type="number"  disabled = "true" class="inputvalue finvalue" [(ngModel)]="objdata5['C5140']" ></td>



</table> -->
        </mat-tab>
        <mat-tab *ngIf="disabletrain" label="Armstrong Training">
          <!-- <label style="width: 50%; margin-left: 50%;padding-left: 5%;font-weight: bolder;">choose Region first</label> -->

          <select [disabled]="true" class="issueheaddrp" (change)="dropDownChanged()" id="yeardata"
            [(ngModel)]="selectedValue">
            <option *ngFor='let v of _values1' [ngValue]="v">{{ v }}</option>
          </select>

          <br><br>

          <table class="paramtable" (change)="Calcvalue6()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation</td>
              <td class="head2">Status Quo
              <td class="head2">Armstrong Service Training</td>


            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Cost of engaging service contractors to resolve basic issues</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue" (change)="add()"
                  [(ngModel)]="serviceCost"></td>

              <td><input min="0.0" max="1000" type="number" class="inputvalue" [(ngModel)]="objdata6['C5225']"></td>

            </tr>
            <tr>
              <td class="issuehead">Investment opportunity cost due to shortend asset life</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue" (change)="add()"
                  [(ngModel)]="opportunityCost"></td>

              <td><input min="0.0" max="1000" type="number" class="inputvalue" [(ngModel)]="objdata6['C5226']"></td>

            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Cost due to 20% Energy loss</td>
              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue" (change)="add()"
                  [(ngModel)]="englossCost"></td>

              <td><input min="0.0" max="1000" type="number" class="inputvalue" [(ngModel)]="objdata6['C5227']"></td>
            </tr>
            <tr>
              <td class="issuehead">Cost of Service training</td>
              <td><input min="0.0" max="1000" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="objdata6['C5129']"></td>

              <td>{{curncy}}<input min="0.0" max="1000" type="number" class="inputvalue" (change)="add()"
                  [(ngModel)]="trainingCost"></td>

            </tr>


            <tr class="issueheadrow">
              <td class="tabbottom" colspan="1">Total({{curncy}})</td>
              <td><input min="0.0" max="1000000" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="total"></td>


              <td><input min="0.0" max="1000000" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="serviceTraining"></td>

            </tr>

            <tr class="issueheadrow">
              <td class="tabbottom" colspan="2">Aggregated Savings(%) </td>
              <td><input min="0.0" max="1000000" type="number" disabled="true" class="inputvalue finvalue"
                  placeholder="78" [(ngModel)]="agrTrainSave"></td>

            </tr><br><br><br>

            <tr class="issueheadrow">
              <td class="tabbottom" colspan="2">Payback : </td>
              <td><input min="0.0" max="1000000" type="number" disabled="true" class="inputvalue finvalue"
                  placeholder="1.09" [(ngModel)]="trainPayback"></td>

            </tr>

          </table>

        </mat-tab>
        <mat-tab *ngIf="disablescon" label="Service Contracts">
          <!-- <label style="width: 50%; margin-left: 50%;padding-left: 5%;font-weight: bolder;">choose Region first</label>  -->
          <select [disabled]="true" class="issueheaddrp" (change)="dropDownChanged()" id="yeardata"
            [(ngModel)]="selectedValue">
            <option *ngFor='let v of _values1' [ngValue]="v">{{ v }}</option>
          </select>

          <div class="top-inputs">
            <div class="field-classic">
              <label class="label-classic" for="yearsSelect">Years:</label>
              <select class="selector-classic" id="yearsSelect"
                [ngModel]="inputTable.years" (ngModelChange)="inputTable.years = +$event"
              >
                <option *ngFor="let year of yearsForActivePerformanceManagement" [value]="year">{{year}}</option>
              </select>
            </div>

            <div class="field-classic">
              <label class="label-classic" for="responseTimeSelect">Response Time (Hrs):</label>
              <select class="selector-classic" id="responseTimeSelect"
                [ngModel]="inputTable.responseTimeInHours" (ngModelChange)="inputTable.responseTimeInHours = +$event"
              >
                <option *ngFor="let hourRespTime of hoursForResponseTime" [value]="hourRespTime">{{hourRespTime}}</option>
              </select>
            </div>

            <div class="field-classic">
              <label class="label-classic" for="partsIncludedSelect">Parts Included:</label>
              <select class="selector-classic" id="partsIncludedSelect"
                [ngModel]="inputTable.isWarrantyIncluded" (ngModelChange)="inputTable.isWarrantyIncluded = $event === 'true'"
              >
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>
          </div>

          <br><br>
          <table class="paramtable" (change)="Calcvalue7()">
            <tr class="tabheader">
              <td class="head2">Operation Fault / Degradation</td>
              <td class="head2">Status Quo
              <td class="head2">Armstrong Service Contract</td>


            </tr>
            <tr class="issueheadrow">
              <td class="issuehead">Energy</td>
              <td>{{curncy}}<input min="0.0" type="number" class="inputvalue"
                  [(ngModel)]="printOutputTable.energyCost_StatusQuo"></td>
              <td>{{curncy}}<input min="0.0" type="number" disabled="true" class="inputvalue"
                  [(ngModel)]="printOutputTable.energyCost_Armstrong"></td>
            </tr>
            <tr>
              <td class="issuehead">Planned Maintenance</td>
              <td>{{curncy}}<input min="0.0" type="number" class="inputvalue"
                  [(ngModel)]="printOutputTable.plannedMaintenance_StatusQuo"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Unplanned Maintenance1</td>
              <td>{{curncy}}<input min="0.0" type="number" class="inputvalue"
                  [(ngModel)]="printOutputTable.unplannedMaintenance_StatusQuo"></td>
              <td></td>
            </tr>

            <tr>
              <td class="issuehead">Downtime</td>
              <td>{{curncy}}<input min="0.0" type="number" class="inputvalue"
                  [(ngModel)]="printOutputTable.trainingDowntime_StatusQuo"></td>
              <td></td>
            </tr>

            <tr class="issueheadrow">
              <td class="issuehead">Service Contract</td>
              <td></td>
              <td>{{curncy}}
                <input min="0.0" max="1000" type="number" class="inputvalue"
                  [(ngModel)]="printOutputTable.apmServiceContract_Armstrong">
              </td>
            </tr>

            <tr class="issueheadrow">
              <td class="tabbottom" colspan="1">Total({{curncy}})</td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="printOutputTable.total_StatusQuo"></td>
              <td><input min="0.0" max="100" type="number" disabled="true" class="inputvalue finvalue"
                  [(ngModel)]="printOutputTable.total_Armstrong"></td>
            </tr>

            <tr class="issueheadrow">
              <td class="tabbottom" colspan="2">Aggregated Savings(%) </td>
              <td><input min="0.0" type="number" disabled="true" class="inputvalue finvalue"
                  [ngModel]="printOutputTable.operatingSavingsPercent | number:'1.2-2'" /></td>
            </tr><br><br><br>

            <tr class="issueheadrow">
              <td class="tabbottom" colspan="2">Return Of Investment({{curncy}}):</td>
              <td>
                  <div><strong>{{printOutputTable.operatingSavings | number:'1.0-0'}}</strong></div>
              </td>
            </tr>

          </table>

          <div *ngIf="isDebugTables()">
            <app-display-formula-table [tableObject]="inputTable" name="inputTable"></app-display-formula-table>
            <app-display-formula-table [tableObject]="printOutputTable" name="printOutputTable"></app-display-formula-table>
            <app-display-formula-table [tableObject]="warrantyPricingTable" name="warrantyPricingTable"></app-display-formula-table>
            <app-display-formula-table [tableObject]="responseTimePremiumTable" name="responseTimePremiumTable"></app-display-formula-table>
            <app-display-formula-table [tableObject]="apmServiceContractQuoteTable" name="apmServiceContractQuoteTable"></app-display-formula-table>
            <app-display-formula-table [tableObject]="costCustomerCouldIncurredTable" name="costCustomerCouldIncurredTable"></app-display-formula-table>

          </div>
        </mat-tab>
      </mat-tab-group>


      <div
        *ngIf="!disableppro && !disablessm && !disablescomm && !disablepupgrad && !disablepess && !disablecew && !disabletrain && !disablescon">
        <br>
        <label class="not">No Service offerings have been selected. Please go back to previous page and
          select one or more service offerings, or proceed to the generate report page.</label>

      </div>

      <br><br>
      <button style="display: inline-block;" class="blue-button buttongen2" (click)="back()">Back</button>
      <button style="display: inline-block;" class="blue-button buttongen" (click)="rout()">Generate Report</button>
      <br><br>

    </div>



  </div>
  <ng-template #HtmlContent2>
    <div>Minimize Content</div>
  </ng-template>
  <ng-template #HtmlContent>
    <div>Click to View <b>Possible Impact</b></div>
  </ng-template>


<router-outlet></router-outlet>
