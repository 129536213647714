import { PlatformLocation } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessserviceService } from './processservice.service';
// import 'rxjs/add/operator/filter';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})




export class AppComponent {
  title = 'Deva-Services';
  credid: string = "@armstrongfluidtechnology.com"
  credpass: string = ""
  signUpLink: string = environment.baseUrl + "/sign-up";

  isLoggedIn$: Observable<boolean>;
  errorMessage: string = ''
  message: string;
  //message: string

  visible: boolean = true;
  changetype: boolean = true;

  paramValue: string;
  code: any;

  constructor(
    private_MatTabsModule: MatTabsModule,
    location: PlatformLocation,
    private _Processservice: ProcessserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    location.onPopState(() => {
      console.log('pressed back!');
      this.pagerefresh();
    });

  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isAuthenticated$;
  }

  pagerefresh() {
    this.router.navigateByUrl("/")
  }

  credentialverify() {
    if (!this.credid.includes('@')) {
      this.credid = this.credid + "@armstrongfluidtechnology.com"
    }
    if (this.credid.includes('@armstrongfluidtechnology.com')) {
      this._Processservice.authenticate(this.credid, this.credpass).subscribe(response => {
        console.log("Authenticate Response")
        console.log(response['url'])
        console.log(Object.keys(response))
        if (response['url']) {

          window.location.href = response['url']

        }
        //      // if(Object.keys(response).includes("user_id") == true){
        //       console.log("You have access")
        //       this.loginbox = false
        //       const Toast = Swal.mixin({
        //         toast: true,
        //         position: 'top-end',
        //         showConfirmButton: false,
        //         timer: 3000,
        //         timerProgressBar: true,
        //         didOpen: (toast) => {
        //           toast.addEventListener('mouseenter', Swal.stopTimer)
        //           toast.addEventListener('mouseleave', Swal.resumeTimer)
        //         }
        //       })

        //       Toast.fire({
        //         icon: 'success',
        //         title: 'Signed in successfully'
        //       })
        //       this.new_entry()
        // }
        // else{
        //   console.log("Credential Not Allowed")

        // }
      })
    }
    else {
      console.log("nothing##")
      this.message = "Only Armstrong domin user are allowed to login."

      throw new Error('Method not implemented.');
      // this._Processservice.Login(entity).subscribe(
      //   data => {
      //     let token = data.access_token;
      //     setTimeout(() => {
      //       if (token != null && token != "") {
      //         let values = {
      //           emailId: this.credid,
      //           userId: data.user_id,
      //           applicationName: "ECOPULSE"
      //         }
      //         debugger;
      //         throw new Error('Method not implemented.');
      //         // this._Processservice.verifyUserIdentity(values).subscribe(
      //         //   response => {
      //         //     if (response.status == true) {
      //         //       localStorage.setItem('access_token', token);
      //         //       localStorage.setItem('username', this.credid);
      //         //       this.postUserDetails();
      //         //     } else {
      //         //       if (this.credid.toLocaleLowerCase() == "salesdemo@armstrongfluidtechnology.com") {
      //         //         // this.verifying = false;
      //         //         localStorage.setItem('access_token', token);
      //         //         localStorage.setItem('username', this.credid);
      //         //         this.postUserDetails();

      //         //       } else {
      //         //         // this.verifying = false;
      //         //         console.log("12345")
      //         //         // this.toastr.warning('User has not added in the group', 'Warning');
      //         //       }

      //         //     }
      //         //   },
      //         //   err => {
      //         //     console.log('Invalid username or password', 'Error');
      //         //   }
      //         // )
      //       }
      //     }, 0);
      //   },
      //   err => {
      //     //this.verifying = false;
      //     console.log('Something went wrong, please try again after sometime.', 'Error');
      //   }
      // )
    }


  }

  postUserDetails() {
    let entity: any = {
      userID: localStorage.getItem('username')
    }
    throw new Error('Method not implemented.');
    // this._Processservice.postUserDetails("/postUserDetails", entity).subscribe(
    //   res => {
    //     // this.verifying = false;
    //     this.router.navigate(['/dashboard']);
    //     res.forEach((e: any) => {
    //       if (e.siteStatus == 1) {
    //         localStorage.setItem("activeSiteId", e.siteID);
    //         //this.setActiveDashboardTable(e.siteID);
    //         //this.sharedService.update_SiteConfig(e.siteConfig);
    //         //this.verifying = false;
    //         this.router.navigate(['/dashboard']);
    //       }
    //     });
    //   }
    // );
  }

  new_entry() {

    //this.router.navigateByUrl('/UserDetails');
  }

  logout() {
    //this.router.navigateByUrl('/User_guide');
    //this.credentialverify(),
    this.authService.logout();
    //this.;
  }
  contus() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: 'Please Enter Valid Credentials'
    })
  }

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
}
