<div class="row respondendid-section" style="margin-top:90px;">
  <div style="margin-left:95px;" class="logboxhead">
    <label>Account Login</label>
  </div>
  <div style="width: 90%; height: fit-content;">
    <label>User-id: &nbsp;</label>
    <input type="text" [(ngModel)]="credid" class="log-input" id="user_id">
    <br>
  </div>


  <div class="password-container">
    <label>Password: &nbsp;</label>
    <input [type]="changetype?'password':'text'" [(ngModel)]="credpass" class="log-input" id="password"
      (keyup.enter)="credentialverify()">
    <span class="eyeicon" (click)="viewpass()"><i [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"
        style="margin-left:-30px ; border: 1px solid #339CFF;"></i> </span>
  </div>

  <div class="options-btn">
    <button class="blue-button" (click)="credentialverify()">Login</button>
    <span class="nothing"></span>
    <a [href]="signUpLink"><i class="href2" aria-hidden="true"
        style="margin-right: 5px;  color: blue; font-size: 12px; font-weight:lighter ;">forgot password</i></a>

  </div>

</div>
