import { CalculationViewComponent } from './calculation-view/calculation-view.component';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UserDetailsComponent } from './user-details/user-details.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportComponent } from './report/report.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from './_modal';
import { CostEvaluationComponent } from './cost-evaluation/cost-evaluation.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DisplayFormulaTableComponent } from './calculation-view/display-formula-table/display-formula-table.component';
import { LoginComponent } from './login/login.component';
import { ModalTableComponent } from './modal-table/modal-table.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserGuideComponent } from './user-guide/user-guide.component';

@NgModule({
  declarations: [
    DisplayFormulaTableComponent,
    CalculationViewComponent,
    UserDetailsComponent,
    AppComponent,
    ReportComponent,
    CostEvaluationComponent,
    UserGuideComponent,
    SignUpComponent,
    LoginComponent,
    ModalTableComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ChartsModule,
    ModalModule,
    TooltipModule.forRoot(),
    MatProgressBarModule,
    PdfViewerModule,
    MatStepperModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
