import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticatedSource = new BehaviorSubject<boolean>(this.checkIsAuthenticated());
  isAuthenticated$ = this.isAuthenticatedSource.asObservable();

  constructor(private http: HttpClient, private router: Router) { }

  authByADCode(code: string) {
    // this method uses for AD login only
    console.log('AD code', code);

    let applicationType = "AZURE.REAT.LOCAL";
    let redirectUrl = environment.baseUrl + "/login";

    this.ADAuthorization(code, applicationType, redirectUrl)
      .subscribe(Response => {
        console.log('LoginComponent response on setcode', Response);

        if (Response.access_token) {
          this.auth(Response.access_token, Response.email_id);
          this.UserLog(Response.email_id);
          this.router.navigate(['/UserDetails']);
        }
      });
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  logout(): void {
    localStorage.removeItem('access_token');
    this.isAuthenticatedSource.next(false);
    this.router.navigate(['/login']);
  }

  private auth(token: string, username: string): void {
    localStorage.setItem('access_token', token);
    localStorage.setItem('username', username);
    this.isAuthenticatedSource.next(true);
  }

  private checkIsAuthenticated(): boolean {
    return !!this.getToken();
  }

  private ADAuthorization(code: string, parameter: string, url: string): Observable<any> {
    console.log('ADAuthorization: ', code, parameter, url);

    const tokenEndpoint = "https://qa.pumpmanager.io/api/user/ad/authorize";

    var headers_object = new HttpHeaders();
    headers_object.append("Access-Control-Allow-Headers", "Content-Type");
    headers_object.append("Access-Control-Allow-Origin", '*');
    headers_object.append("Accept", "application/json");
    headers_object.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers_object
    };

    return this.http.get(`${tokenEndpoint}?code=${code}&applicationType=${parameter}&redirectUrl=${url}`, httpOptions);
  }

  private UserLog(user_id: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let payload = { email: user_id, deviceId: "", deviceType: "chrome", platform: "Web browser", applicationType: "DEVA" };
    this.http.post('https://qa.pumpmanager.io/api/user/lastLoggedIn', payload, { headers: headers })
      .subscribe((response) => {
        console.log(response);
      });
  }
}
