import { forEachPropInObject } from "./utils";

interface Observer {
  update(subject: CachedProperties<any>): void;
}

export type CachedPropertyMethods<T> = {
  [K in keyof T]: (this: CachedProperties<T>, proxy: T) => T[K];
};

export class CachedProperties<T> {
  private cache: Partial<T> = {};
  private observers: Set<Observer> = new Set();
  public self: this;

  public subscribe(observer: Observer) {
    this.self.observers.add(observer);
  }

  public unsubscribe(observer: Observer) {
    this.observers.delete(observer);
  }

  notifyObservers() {
    this.observers.forEach(observer => observer.update(this));
  }

  update(subject: CachedProperties<any>) {
    this.clearCache();
  }

  public clearCache() {
    this.cache = {};
  }

  constructor(
    public methods: CachedPropertyMethods<T>,
    private predefinedValues: Partial<T> = {},
    public overridedValues: Partial<T> = {}
  ) {
    this.self = this;

    forEachPropInObject(predefinedValues, (key, value) => {
      if (value instanceof CachedProperties) {
        value.self.subscribe(this);
      }
    });

    return new Proxy(this, {
      get: (target, property: string | symbol, receiver: any) => {
        // Return value from cache if it exists
        if (target.cache[property] !== undefined) {
          return target.cache[property];
        }

        if (property === 'self')
          return target;

        // Return value from overrided values if it exists
        if (target.overridedValues[property] !== undefined) {
          target.cache[property] = target.overridedValues[property];
          return target.overridedValues[property];
        }

        // If value is predefined, use it and cache it
        if (target.predefinedValues[property] !== undefined) {
          target.cache[property] = target.predefinedValues[property];
          return target.cache[property];
        }

        // Get value from formula and cache it
        const formula = target.methods[property];
        if (formula) {
          const value = formula.call(target, receiver);
          target.cache[property] = value;
          if (value instanceof CachedProperties) {
            value.subscribe(this);
          }
          return value;
        }

        // If no formula is found, return undefined
        return undefined;
      },
      set: (target, property: string | symbol, value: any) => {
        target.overridedValues[property] = value;
        this.clearCache();

        if (value instanceof CachedProperties) {
          value.subscribe(this);
        }

        target.notifyObservers();

        return true;
      }
    });
  }
}

export function createCachedPropertiesWithFormula<T>(
  methods: CachedPropertyMethods<T>,
  predefinedValues: Partial<T> = {},
  overridedValues: Partial<T> = {}
): T {
  return new CachedProperties<T>(methods, predefinedValues, overridedValues) as unknown as T;
}
