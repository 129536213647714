import { getRateSmCommFromPumpsCount_GBP, getWorkRateFromPumpsCountAndCurrency } from "../currencies";
import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";



// | Type of Armstrong Pump(s)                         | Number        |
// | ------------------------------------------------- | ------------- |
// | Armstrong DE Gen 5 Pumps                          | 0             |
// |  |
// | Armstrong Gen 3.1 DE Pumps<br>(converted to Gen5) | 0             |
// |  |
// | Armstrong Constant Speed Equipment - Comm/Fire    | 8             |
// |  |
// | Total Number of Pumps - Armstrong                 | \=E13+E15+E17 |
// |  |


// | Other Equipment - NON APM                             | Number |
// | ----------------------------------------------------- | ------ |
// | Armstrong Controls Products<br>( IPS, IPC, IVS, etc.) | 0      |
// |  |
// | Other make equipment<br>(Non-Armstrong Pumps)<br>     | 0      |
// |  |

// Active Performance Management Service Contract
// | Years | Response Time (Hrs) | Comp. Warranty (Include Parts) | Scheduled Site Visits/Year | No. of Days/ Scheduled Visit
// | ----- | ------------------- | ------------------------------ | -------------------------- | -------------------------------------------------------------------------------------------------------
// | 1     | 4                   | Yes                            | 2                          | \=IF(H33>0, ROUNDDOWN((VLOOKUP((E36+E57),Data!$AA$6:$AF$56,4,0))/Data!AC3,1),0) // simplified to ceil from pamps count / 8

// | Emergency Visits /year | Probability - Site Emergency Calls | ="Additional Expenses per Visit<br>[Travel, Stay, Others]   ("& $M$12&")" | Additional Connex. Kits QTY (If required) |
// | ---------------------- | ---------------------------------- | -------------------------------------------------------------------------- | ----------------------------------------- |
// | 2                      | 0.2                                | 0                                                                          | 0                                         |



export interface IInputTable {
  // Type of Armstrong Pump
  armstrongDeGen5Pumps: number;
  armstrongGen31DePumps: number;
  armstrongConstantSpeedEquipment: number;
  totalNumberOfPumps_Armstrong: number;

  // Other Equipment - NON APM
  otherArmstrongControlsProducts: number;
  otherNonArmstrongPumps: number;

  // Active Performance Management Service Contract
  years: number,
  responseTimeInHours: number,
  isWarrantyIncluded: boolean,

  scheduledSiteVisitsPerYear: number;
  numberOfDaysPerScheduledVisit: number;
  emergencyVisitsPerYear: number;
  probabilityOfSiteEmergencyCalls: number;
  additionalExpensesPerVisit: number;
  additionalConnexKitsQty: number;

  totalPurchaseValueNetOfEquipment: number;

  currencyCode: string;

  energyCost_StatusQuo: number; // energy1
  plannedMaintenance_StatusQuo: number; // planMaint
  unplannedMaintenance_StatusQuo: number; // unplanMaint
  trainingDowntime_StatusQuo: number; // downtime

  energyCost_Armstrong: number; // energy2


  ratesFromPumpsCount_GBP_SmComm: number; // Commission
  ratesFromPumpsCount_CurrentCurrency: number; // Service (24Hr
}

const inputFormulas: CachedPropertyMethods<IInputTable> = {
  armstrongDeGen5Pumps: p => 0,
  armstrongGen31DePumps: p => 0,
  armstrongConstantSpeedEquipment: p => 8,
  totalNumberOfPumps_Armstrong: p => p.armstrongDeGen5Pumps + p.armstrongGen31DePumps + p.armstrongConstantSpeedEquipment,

  otherArmstrongControlsProducts: p => 0,
  otherNonArmstrongPumps: p => 0,

  years: p => 1,
  responseTimeInHours: p => 24,
  isWarrantyIncluded: p => false,

  scheduledSiteVisitsPerYear: p => 2,
  numberOfDaysPerScheduledVisit: p => Math.ceil((p.totalNumberOfPumps_Armstrong + p.otherArmstrongControlsProducts + p.otherNonArmstrongPumps) / 8),
  emergencyVisitsPerYear: p => 2,
  probabilityOfSiteEmergencyCalls: p => 0.2,
  additionalExpensesPerVisit: p => 0,
  additionalConnexKitsQty: p => 0,


  totalPurchaseValueNetOfEquipment: p => 57337,

  currencyCode: p => 'CAD',

  energyCost_StatusQuo: p => 0,
  plannedMaintenance_StatusQuo: p => 0,
  unplannedMaintenance_StatusQuo: p => 0,
  trainingDowntime_StatusQuo: p => 0,
  energyCost_Armstrong: p => 0,

  ratesFromPumpsCount_GBP_SmComm: p => getRateSmCommFromPumpsCount_GBP(p.totalNumberOfPumps_Armstrong),
  ratesFromPumpsCount_CurrentCurrency: p => getWorkRateFromPumpsCountAndCurrency(p.totalNumberOfPumps_Armstrong, p.currencyCode),
}

export const createInputTable = (predefinedValues: Partial<IInputTable> = {}, overridedValues: Partial<IInputTable> = {}) =>
  createCachedPropertiesWithFormula(inputFormulas, predefinedValues, overridedValues);


