import { getWorkRateFromPumpsCountAndCurrency } from "../currencies";
import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";
import { IInputTable, createInputTable } from "./InputTable";
import { IResponseTimePremiumTable, createResponseTimePremiumTable } from "./ResponseTimePremiumTable";
import { IWarrantyPricingTable, createWarrantyPricingTable } from "./WarrantyPricingTable";

// | ="Total Value of <br/>Pumps<br/> (nearest " & $M$14&" 500)" |  | Total Number of Pumps & Armstrong equipment | Number of DEPC Retrofit(s) | ="Comprehensive Parts Only<br/> ("& (G37)&")"                                                                                                                                                                                                                                                                                                                        |
// |-------------------------------------------------------------|--|---------------------------------------------|----------------------------|----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
// | =E28                                                        |  | =E19+E23                                    | =E15                       | =IF((E19+E23)>0,(IF(H33>0,IF(G33="Yes",IF(E33=1,((Data!I18*(Input!E28*((E13+E15+E23)/(E19+E23))))+(Data!L18*(Input!E28*(E17/(E19+E23))))),IF(Input!E33=2,((Data!J18*(Input!E28*(E13+E15+E23)/(E19+E23))+(Data!M18*(Input!E28*(E17)/(E19+E23))))),IF(Input!E33=3,((Data!K18*(Input!E28*(E13+E15+E23)/(E19+E23))+(Data!N18*(Input!E28*(E17)/(E19+E23)))))))),0),0)),0) |


// | =" Site visits [Scheduled + Emergency] (Total) <br/>("& $L$18&")"                                                                                                                         | ="Response Time Premium  <br/>("& $L$18&")"              |
// |-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|----------------------------------------------------------|
// | =IF((E19+E23)<>0,((VLOOKUP((E36+E57),Data!$AA$6:$AF$56,4,0)*Input!E33*H33))+(VLOOKUP((E36+E57),Data!$AA$6:$AF$56,4,0)*(Input!E33*J33*(K33)*0.7))+((C36/E36)*0.003*E33*(H33+(J33*K33))),0) | =(IF(F33=4,Data!Y35*E33,IF(Input!F33=8,Data!Y34*E33,0))) |



export interface IApmServiceContractQuoteTable {
  input: IInputTable;
  warrantyPricingTable: IWarrantyPricingTable;
  responseTimePremiumTable: IResponseTimePremiumTable;

  totalValueOfPumps: number;
  totalNumberOfPumps_ArmstrongEquipment: number;
  numberOfDEPCRetrofit: number;
  comprehensivePartsOnly: number;
  siteVisitsScheduledEmergencyTotal: number;
  responseTimePremium: number;
  totalAPMServiceContractQuoteValue: number;

  finalQuotePriceDiscountedAndAdjusted: number;
}

const apmServiceContractQuoteFunctions: CachedPropertyMethods<IApmServiceContractQuoteTable> = {
  input: p => createInputTable(),
  warrantyPricingTable: p => createWarrantyPricingTable(),
  responseTimePremiumTable: p => createResponseTimePremiumTable(),

  // "Total Value of Pumps (nearest $ 500)"
  totalValueOfPumps: p => p.input.totalPurchaseValueNetOfEquipment,

  totalNumberOfPumps_ArmstrongEquipment: p => p.input.totalNumberOfPumps_Armstrong + p.input.otherArmstrongControlsProducts,

  numberOfDEPCRetrofit: p => p.input.armstrongGen31DePumps,

  comprehensivePartsOnly: p => p.input.isWarrantyIncluded
    ? p.warrantyPricingTable.nonDe_Total * p.input.totalPurchaseValueNetOfEquipment
    : 0,

  siteVisitsScheduledEmergencyTotal: p => {
    // E36+E25
    const totalPumps = p.totalNumberOfPumps_ArmstrongEquipment + p.input.otherNonArmstrongPumps;
    // VLOOKUP((E36+E25),Data!$AA$6:$AF$56,4,0)
    const workRate = getWorkRateFromPumpsCountAndCurrency(totalPumps, p.input.currencyCode);
    const coeffi = p.totalValueOfPumps / p.totalNumberOfPumps_ArmstrongEquipment * 0.003;

    const result = p.input.years * (
      workRate * (p.input.scheduledSiteVisitsPerYear + p.input.emergencyVisitsPerYear * p.input.probabilityOfSiteEmergencyCalls * 0.7)
      + coeffi * (p.input.scheduledSiteVisitsPerYear + p.input.emergencyVisitsPerYear * p.input.probabilityOfSiteEmergencyCalls)
    );

    return result
  },

  responseTimePremium: (p) => {
    switch (p.input.responseTimeInHours) {
      case 4: return p.responseTimePremiumTable.responsePremium_4hr * p.input.years;
      case 8: return p.responseTimePremiumTable.responsePremium_8hr * p.input.years;
      case 24: return 0;
      default: throw new Error(`Invalid responseTimeInHours: ${p.input.responseTimeInHours}`);
    }
  },

  totalAPMServiceContractQuoteValue: p => p.comprehensivePartsOnly + p.siteVisitsScheduledEmergencyTotal + p.responseTimePremium,

  finalQuotePriceDiscountedAndAdjusted: p => Math.ceil(p.totalAPMServiceContractQuoteValue),
}

export const createApmServiceContractQuoteTable = (predefinedValues: Partial<IApmServiceContractQuoteTable> = {}) =>
  createCachedPropertiesWithFormula<IApmServiceContractQuoteTable>(apmServiceContractQuoteFunctions, predefinedValues);


