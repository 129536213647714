import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormSaverService } from '../services/formsaver.service';

@Component({
  selector: 'app-modal-table',
  templateUrl: './modal-table.component.html',
  styleUrls: ['./modal-table.component.css']
})
export class ModalTableComponent implements OnInit {

  reports: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalTableComponent>,
    private formSaver: FormSaverService,
  ) { }

  ngOnInit(): void {
    this.loadReports();
  }

  closeModal() {
    this.dialogRef.close();
  }

  async viewReport(id: number) {
    this.dialogRef.close();

    const reportData = await this.getReport(id);
    this.formSaver.restoreAll(reportData.formData);
  }

  async getReport(id: number) {
    const url = `${environment.backendUrl}/api/forms/get/${id}`;

    const report = await fetch(url)
      .then((res) => res.json())
      .then((data) => data.result);

    return report;
  }

  async loadReports() {
    const projectName = "DEVA-ServicesAssessmentTool"
    const username = localStorage.getItem('username');

    const url = `${environment.backendUrl}/api/forms/get?projectName=${projectName}&username=${username}`;

    const reports = await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const reports = data.result;
        reports.sort((a, b) => b.id - a.id);
        return reports;
      });

    this.reports = reports;
  }

}
