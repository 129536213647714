import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as math from 'mathjs';
import { ModalTableService } from '../modal-table/modal-table.service';
import { ProcessserviceService } from '../processservice.service';
import { FormSaverService } from '../services/formsaver.service';

declare const bar1st: any;
declare const bar2nd: any;
declare const bar3rd: any;
declare const line1st: any;
declare const bar4th: any;
declare const bar5th: any;
declare const bar6th: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})

export class ReportComponent implements OnInit {
  public today
  public data_obj;
  public data
  public Reportpage = true
  public prdetails;
  public costdata
  public objdata
  public objdata1: any = {};
  public objdata2: any = {};
  public objdata3;
  public objdata4;
  public objdata5;
  public objdata6;
  public objdata7;
  public disableppro = true; public disablepess = true; public disablessm = false; public disablescomm: boolean = true; public disablepupgrad = true;
  public alldisable = false; public disablecew = false;
  public ROI1; public ROI2; public ROI3;
  Router: any;

  constructor(
    private router: Router,
    private processsservice: ProcessserviceService,
    public formSaver: FormSaverService,
    private modalTableService: ModalTableService,
  ) { }


  @ViewChild('content', { static: true }) content: ElementRef;

  crncy = {};

  ngOnInit(): void {
    this.formSaver.formsData$.subscribe(_ => {
      this.setData();
    });
  }

  downloadPDF() {
    const innerContents = document.getElementById('content').innerHTML;
    const popupWinindow = window.open('', '_blank', 'width=1200px,height=600px,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write(`
  <!DOCTYPE html>
  <html>
    <head>
      <title>Deva Analytics</title>
      <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>
      <link rel="stylesheet" type="text/css" href="assets/styles.css" />
      <script src="http://code.jquery.com/jquery-2.1.0.min.js"></script>
      <script>
        window.onload = function() {
          setTimeout(() => {
            window.moveTo(0,0);
        window.resizeTo(1400, 860);
            window.print();
            window.close()
          }, 2000);
        };
      </script>
    </head>
    <body style="print-color-adjust:exact; -webkit-print-color-adjust:exact;">
      ${innerContents}
    </body>
  </html>
  `);
    popupWinindow.document.close();
  }

  saveAll() {
    this.formSaver.saveAll();
  }

  openModalTable() {
    this.modalTableService.openModal();
  }


  setData() {
    this.Transferdata();
    this.display();
    this.disablescomm = this.processsservice.costinfo['smartc']
    this.disablessm = this.processsservice.costinfo['smarts']
    this.disablepupgrad = this.processsservice.costinfo['perfup']
    this.disablecew = this.processsservice.costinfo['cew']
    if (this.processsservice.costinfo['pumps'] == true && this.processsservice.costinfo['Pumpservice'] == 'pumppro') {
      this.disableppro = this.processsservice.costinfo['pumps']
    } else {
      this.disableppro = false
    }
    if (this.processsservice.costinfo['pumpes'] == true && this.processsservice.costinfo['Pumpservice1'] == 'pumpess') {
      this.disablepess = this.processsservice.costinfo['pumpes']
    } else {
      this.disablepess = false
    }
    if (this.disableppro == false && this.disablescomm == false && this.disablessm == false && this.disablepess == false) {
      this.alldisable = true
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = today.getMonth(); //January is 0!
    var yyyy = today.getFullYear();
    var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.today = dd + '-' + monthShortNames[mm] + '-' + yyyy;
    var variable = [0.1,
      0.2,
      0.01,
      0.03,
      0.02,
      0.01,
      0.04,
      0.6,
      0.2,
      0.02]
    this.costdata = this.processsservice.costinfo
    this.Graphcalc()
    this.call()
  }

  curncy: any;

  display() {
    let dropvalue = localStorage.getItem('curncy');
    this.curncy = JSON.parse(dropvalue);
  }

  reloadCurrentRoute() {
    this.router.navigateByUrl('/CalculationViewComponent', { skipLocationChange: true });
    this.router.navigate(["ReportComponent"]);
    // });
  }

  reset() {
    this.processsservice.cheeck = ""
    this.Reportpage = false
    this.processsservice.userinfo = {}
    //this.CalcComponent.fillpage = true

  }

  Transferdata() {
    let detail = localStorage.getItem('details');
    this.prdetails = JSON.parse(detail);
    console.log('details', this.prdetails);

    // this.prdetails =  this.processsservice.userinfo
    // console.log("check",this.processsservice.userinfo);

    if (this.processsservice.Projecttype == "existingbuilding") {
      this.objdata = this.processsservice.objdata_old_1
      this.objdata1 = this.processsservice.objdata1_old_1
      this.objdata2 = this.processsservice.objdata2_old_1
      this.objdata3 = this.processsservice.objdata3_nw_1
      this.objdata4 = this.processsservice.objdata4_nw_1
      this.objdata5 = this.processsservice.objdata5_nw_1
      this.objdata6 = this.processsservice.objdata6_nw_1
      this.objdata7 = this.processsservice.objdata7_nw_1
    }
    else {
      this.objdata = this.processsservice.objdata_nw_1
      this.objdata1 = this.processsservice.objdata1_nw_1
      this.objdata2 = this.processsservice.objdata2_nw_1
      this.objdata3 = this.processsservice.objdata3_nw_1
      this.objdata4 = this.processsservice.objdata4_nw_1
      this.objdata5 = this.processsservice.objdata5_nw_1
      this.objdata6 = this.processsservice.objdata6_nw_1
      this.objdata7 = this.processsservice.objdata7_nw_1

    }


  }




  Point = function (valA, valB) {
    this.x = valA;
    this.y = valB;
  };

  lineIntersection(pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH, pointI) {
    var z1 = (pointA.x - pointB.x);
    var z2 = (pointC.x - pointD.x);
    var z3 = (pointA.y - pointB.y);
    var z4 = (pointC.y - pointD.y);
    var dist = z1 * z4 - z3 * z2;
    if (dist == 0) {
      return null;
    }
    var tempA = (pointA.x * pointB.y - pointA.y * pointB.x);
    var tempB = (pointC.x * pointD.y - pointC.y * pointD.x);
    var xCoor = (tempA * z2 - z1 * tempB) / dist;
    var yCoor = (tempA * z4 - z3 * tempB) / dist;

    if (xCoor < Math.min(pointA.x, pointB.x) || xCoor > Math.max(pointA.x, pointB.x) ||
      xCoor < Math.min(pointC.x, pointD.x) || xCoor > Math.max(pointC.x, pointD.x)) {
      return null;
    }
    if (yCoor < Math.min(pointA.y, pointB.y) || yCoor > Math.max(pointA.y, pointB.y) ||
      yCoor < Math.min(pointC.y, pointD.y) || yCoor > Math.max(pointC.y, pointD.y)) {
      return null;
    }

    return this.Point(xCoor, yCoor);
  }

  call() {


  }


  intersectline(array1, array2) {
    for (let i = 1; i < 11; i++) {
      if ((Number(array2[i]) - Number(array1[i])) < 0) {
        return math.intersect([i, Number(array1[i - 1])], [i + 1, Number(array1[i])], [i, Number(array2[i - 1])], [i + 1, Number(array2[i])])
      }

    }

  }


  Graphcalc() {
    var energyinqcost = Number(this.processsservice.costinfo['inqeng1'])
    var energybidcost = Number(this.processsservice.costinfo['inqeng2'])
    var maintinqcost = Number(this.processsservice.costinfo['inqmnt1'])
    var maintbidcost = Number(this.processsservice.costinfo['inqmnt2'])
    var cstinqcost = Number(this.processsservice.costinfo['inqcst1'])
    var cstbidcost = Number(this.processsservice.costinfo['inqcst2'])


    //this below variables are used for CEW graph ------>>>>>>>>


    //if(this.processsservice.selectedValuecrncy[4]){
    var labourcostcp = localStorage.getItem('cew1');
    // }else{var labourcostcp= Number(this.processsservice.objdata5_nw['C5671'])}
    var cewadvstrndran = localStorage.getItem('cew2');
    var cew = Number(this.processsservice.objdata5_nw['C5221']);



    //this below variables are used for training graph ------>>>>>>>>

    var cstservcontract = localStorage.getItem('training1');
    var invstoppocst = localStorage.getItem('training2');
    var cstduetoengloss = localStorage.getItem('training3');
    var servtraining = localStorage.getItem('training4');



    //this below variables are used for contracts graph ------>>>>>>>>

    var contracteng = localStorage.getItem('contract1');
    var contracteng2 = localStorage.getItem('contract2');
    var plannedmaintenance = localStorage.getItem('contract3');
    var unplannedmaintenance = localStorage.getItem('contract4');
    var downtime = localStorage.getItem('contract5');
    var servicecontract = localStorage.getItem('contract6');

    var engpro = 1 - (Number(this.objdata['C14ag']) / 100)
    var mntpro = 1 - (Number(this.objdata['C15ag']) / 100)
    var engess = 1 - (Number(this.objdata1['C24ag']) / 100)
    var mntess = 1 - (Number(this.objdata1['C25ag']) / 100)
    var engsms = 1 - (Number(this.objdata2['C34ag']) / 100)
    var mntsms = 1 - (Number(this.objdata2['C35ag']) / 100)
    var engsmc = 1 - (Number(this.objdata3['C44ag']) / 100)
    var mntsmc = 1 - (Number(this.objdata3['C45ag']) / 100)

    // duplicating for later use for non selected services
    var engprodup = 1 - (Number(this.objdata['C14ag']) / 100)
    var mntprodup = 1 - (Number(this.objdata['C15ag']) / 100)
    var totalprodup = 1 - (Number(this.objdata['C14ag']) * Number(this.objdata['C15ag'])) / 121.67
    var engessdup = 1 - (Number(this.objdata1['C24ag']) / 100)
    var mntessdup = 1 - (Number(this.objdata1['C25ag']) / 100)
    var totalessdup = engessdup + mntessdup
    var engsmsdup = 1 - (Number(this.objdata2['C34ag']) / 100)
    var mntsmsdup = 1 - (Number(this.objdata2['C35ag']) / 100)
    var totalsmsdup = (Number(this.objdata2['C34ag']) + Number(this.objdata2['C35ag'])) / 324.2
    var engsmcdup = 1 - (Number(this.objdata3['C44ag']) / 100)
    var mntsmcdup = 1 - (Number(this.objdata3['C45ag']) / 100)
    var totalsmcdup = 1 - (Number(this.objdata3['C44ag']) + Number(this.objdata3['C45ag'])) / 225

    var disablescomm = this.processsservice.costinfo['smartc']
    var disablessm = this.processsservice.costinfo['smarts']
    if (this.processsservice.costinfo['pumps'] == true && this.processsservice.costinfo['Pumpservice'] == 'pumppro') { var disableppro = this.processsservice.costinfo['pumps'] } else { var disableppro = false }
    if (this.processsservice.costinfo['pumps'] == true && this.processsservice.costinfo['Pumpservice'] == 'pumpess') {
      var disablepess = this.processsservice.costinfo['pumps']
    } else { var disablepess = false }



    var array1 = ["pro", "ess", "ss", "sc"]
    var energy = [energybidcost]
    var mant = [maintbidcost]
    var cumcost = [cstbidcost]
    var procost = 7650
    var esscost = 1790
    var sscost = 720
    var smccost = 720

    var string1 = "DE"
    if (this.alldisable == false) {
      if (disablepess == true) {
        var string2 = string1 + "+PumpEss"

      } else {
        engess = 1; mntess = 1; esscost = 0
        var string2 = string1
      }
      if (disableppro == true) {
        var string3 = string2 + "+PumpPro"

      } else {
        engpro = 1; mntpro = 1; procost = 0
        var string3 = string2
      }
      if (disablessm == true) {
        var string4 = string3 + "+SS"

      } else {
        engsms = 1; mntsms = 1; sscost = 0
        var string4 = string3
      }
      if (disablescomm == true) {
        var string5 = string4 + "+SC"

      } else {
        engsmc = 1; mntsmc = 1; smccost = 0
        var string5 = string4
      }
    }
    else {
      var string5 = "DE+PumpPro+SS+SC"
    }


    var energybid = [energybidcost]
    var mantbid = [maintbidcost]

    var energyinq = [energyinqcost]
    var mantinq = [maintinqcost]
    var cumcostinq = [cstinqcost]

    var energyess = [energybidcost * engess]
    var mantess = [maintbidcost * mntess]
    var energypro = [energyess[0] * engpro]
    var mantpro = [mantess[0] * mntpro]
    var energysms = [energypro[0] * engsms]
    var mantsms = [mantpro[0] * mntsms]
    var energysmc = [energysms[0] * engsmc]
    var mantsmc = [mantsms[0] * mntsmc]

    var Totalengbid = [energy[0]]
    var Totalmntbid = [mant[0]]
    var Totalenginq = [energyinq[0]]
    var Totalmntinq = [mantinq[0]]
    var Totalengess = [energyess[0]]
    var Totalmntess = [mantess[0]]
    var Totalengpro = [energypro[0]]
    var Totalmntpro = [mantpro[0]]
    var Totalengsms = [energysms[0]]
    var Totalmntsms = [mantsms[0]]
    var Totalengsmc = [energysmc[0]]
    var Totalmntsmc = [mantsmc[0]]


    // CEW
    var Lccp = [labourcostcp]
    var Pcpnw = [cewadvstrndran]
    var CEWcost = [cew]
    var cewsavings = [(+labourcostcp + +cewadvstrndran) - +cew]

    //training

    var costofservcontract = [cstservcontract]
    var shortendlife = [invstoppocst]
    var engloss = [cstduetoengloss]
    var servicetraining = [servtraining]
    var trainingsave = [(+cstservcontract + +invstoppocst + +cstduetoengloss) - +servtraining]

    //contract

    var contenergy = [contracteng]
    var contenergy2 = [contracteng2]
    var contplannedmaint = [plannedmaintenance]
    var contunplannedmaint = [unplannedmaintenance]
    var contdwntime = [downtime]
    var servcont = [servicecontract]
    var servcontsave = [((+contracteng + +plannedmaintenance + +unplannedmaintenance + +downtime) - (+contracteng2 + +servicecontract))]


    var cumbidcost = [cstbidcost, cstbidcost + energy[0] + mant[0]]
    var cuminqcost = [cstinqcost, cstinqcost + energyinq[0] + mantinq[0]]
    var cumesscost = [cstbidcost + esscost]
    cumesscost.push(cumesscost[0] + energyess[0] + mantess[0])
    var cumprocost = [cumesscost[0] + procost]
    cumprocost.push(cumprocost[0] + energypro[0] + mantpro[0])
    var cumsmscost = [cumprocost[0] + sscost]
    cumsmscost.push(cumsmscost[0] + energysms[0] + mantsms[0])
    var cumsmccost = [cumsmscost[0] + smccost]
    cumsmccost.push(cumsmccost[0] + energysmc[0] + mantsmc[0])

    // Creating array for cost for 10 yrs with energy, n
    for (let i = 1; i < 10; i++) {

      mant.push(mant[i - 1] * 1.03)
      mantinq.push(mantinq[i - 1] * 1.03)
      mantess.push(mantess[i - 1] * 1.03)
      mantpro.push(mantpro[i - 1] * 1.03)
      mantsms.push(mantsms[i - 1] * 1.03)
      mantsmc.push(mantsmc[i - 1] * 1.03)

      energy.push(energy[i - 1] * 1.03)
      energyinq.push(energyinq[i - 1] * 1.03)
      energyess.push(energyess[i - 1] * 1.03)
      energypro.push(energypro[i - 1] * 1.03)
      energysms.push(energysms[i - 1] * 1.03)
      energysmc.push(energysmc[i - 1] * 1.03)

      cumbidcost.push(cumbidcost[i] + energy[i] + mant[i - 1])
      cuminqcost.push(cuminqcost[i] + energyinq[i] + mantinq[i])
      cumesscost.push(cumesscost[i] + energyess[i] + mantess[i])
      cumprocost.push(cumprocost[i] + energypro[i] + mantpro[i])
      cumsmscost.push(cumsmscost[i] + energysms[i] + mantsms[i])
      cumsmccost.push(cumsmccost[i] + energysmc[i] + mantsmc[i])

      Totalengbid[0] = Totalengbid[0] + energy[i]
      Totalmntbid[0] = Totalmntbid[0] + mant[i]
      Totalenginq[0] = Totalenginq[0] + energyinq[i]
      Totalmntinq[0] = Totalmntinq[0] + mantinq[i]
      Totalengess[0] = Totalengess[0] + energyess[i]
      Totalmntess[0] = Totalmntess[0] + mantess[i]
      Totalengpro[0] = Totalengpro[0] + energypro[i]
      Totalmntpro[0] = Totalmntpro[0] + mantpro[i]
      Totalengsms[0] = Totalengsms[0] + energysms[i]
      Totalmntsms[0] = Totalmntsms[0] + mantsms[i]
      Totalengsmc[0] = Totalengsmc[0] + energysmc[i]
      Totalmntsmc[0] = Totalmntsmc[0] + mantsmc[i]
    }
    Totalengbid[0] = Number(Totalengbid[0].toFixed(0))
    Totalmntbid[0] = Number(Totalmntbid[0].toFixed(0))
    Totalenginq[0] = Number(Totalenginq[0].toFixed(0))
    Totalmntinq[0] = Number(Totalmntinq[0].toFixed(0))
    Totalengess[0] = Number(Totalengess[0].toFixed(0))
    Totalmntess[0] = Number(Totalmntess[0].toFixed(0))
    Totalengpro[0] = Number(Totalengpro[0].toFixed(0))
    Totalmntpro[0] = Number(Totalmntpro[0].toFixed(0))
    Totalengsms[0] = Number(Totalengsms[0].toFixed(0))
    Totalmntsms[0] = Number(Totalmntsms[0].toFixed(0))
    Totalengsmc[0] = Number(Totalengsmc[0].toFixed(0))
    Totalmntsmc[0] = Number(Totalmntsmc[0].toFixed(0))


    var Totalbid = [Totalengbid[0] + Totalmntbid[0]]
    var Totalinq = [Totalenginq[0] + Totalmntinq[0]]
    var Totaless = [Totalengess[0] + Totalmntess[0]]
    var Totalpro = [Totalengpro[0] + Totalmntpro[0]]
    var Totalsms = [Totalengsms[0] + Totalmntsms[0]]
    var Totalsmc = [Totalengsmc[0] + Totalmntsmc[0]]

    var arraytoteng = [
      this.formatWithCurrency(Totalenginq[0]),
      this.formatWithCurrency(Totalengbid[0]),
      this.formatWithCurrency(Totalengess[0]),
      this.formatWithCurrency(Totalengpro[0]),
      this.formatWithCurrency(Totalengsms[0]),
      this.formatWithCurrency(Totalengsmc[0])
    ]

    var arraytotmnt = [
      this.formatWithCurrency(Totalmntinq[0]),
      this.formatWithCurrency(Totalmntbid[0]),
      this.formatWithCurrency(Totalmntess[0]),
      this.formatWithCurrency(Totalmntpro[0]),
      this.formatWithCurrency(Totalmntsms[0]),
      this.formatWithCurrency(Totalmntsmc[0])
    ]

    var arraytotopr = [
      this.formatWithCurrency(Totalinq[0]),
      this.formatWithCurrency(Totalbid[0]),
      this.formatWithCurrency(Totaless[0]),
      this.formatWithCurrency(Totalpro[0]),
      this.formatWithCurrency(Totalsms[0]),
      this.formatWithCurrency(Totalsmc[0])
    ]

    var arraytotcum = [
      this.formatWithCurrency(cuminqcost[0]),
      this.formatWithCurrency(cumbidcost[0]),
      this.formatWithCurrency(cumesscost[0]),
      this.formatWithCurrency(cumprocost[0]),
      this.formatWithCurrency(cumsmscost[0]),
      this.formatWithCurrency(cumsmccost[0])
    ]

    var arraytotcew = [
      this.strArr_formatWithCurrency(Lccp),
      this.strArr_formatWithCurrency(Pcpnw),
      this.numArr_formatWithCurrency(CEWcost),
      this.numArr_formatWithCurrency(cewsavings)
    ]

    var arraytottrain = [
      this.strArr_formatWithCurrency(costofservcontract),
      this.strArr_formatWithCurrency(shortendlife),
      this.strArr_formatWithCurrency(engloss),
      this.strArr_formatWithCurrency(servicetraining),
      this.numArr_formatWithCurrency(trainingsave)
    ]

    var arraytotcont = [
      this.strArr_formatWithCurrency(contenergy),
      this.strArr_formatWithCurrency(contenergy2),
      this.strArr_formatWithCurrency(contplannedmaint),
      this.strArr_formatWithCurrency(contunplannedmaint),
      this.strArr_formatWithCurrency(contdwntime),
      this.strArr_formatWithCurrency(servcont),
      this.numArr_formatWithCurrency(servcontsave)
    ]

    var yval = 0
    var lastservice = 0
    var finalselec = []
    if (disablepess == false) {
      Totaless = []; Totalengess = []; Totalmntess = []; cumesscost = []
    } else {
      finalselec = cumesscost; yval = yval + 1
    }
    if (disableppro == false) {
      Totalpro = []; Totalengpro = []; Totalmntpro = []; cumprocost = []
    } else {
      finalselec = cumprocost; yval = (yval + 1); lastservice = 1
    }
    if (disablessm == false) {
      Totalsms = []; Totalengsms = []; Totalmntsms = []; cumsmscost = []
    } else {
      finalselec = cumsmscost; yval = (yval + 1); lastservice = 2
    }
    if (disablescomm == false && this.alldisable == false) {
      Totalsmc = []; Totalengsmc = []; Totalmntsmc = []; cumsmccost = []
    }
    else if (disablescomm == false && this.alldisable == true) { Totalsmc = []; Totalengsmc = []; Totalmntsmc = []; finalselec = cumsmccost; lastservice = 4 }
    else {
      finalselec = cumsmccost; yval = (yval + 1); lastservice = 3
    }
    var yval2 = 0
    if (yval == 3) { yval2 = 0.04 } else if (yval == 2) { yval2 = 0.15 } else { yval2 = 0.26 }


    // logic to make bar for non selected service
    var laststring = ""
    var lastvalue = 0
    var lastvalue1 = 0
    var lastvalue2 = 0
    if (lastservice == 1) {
      laststring = string3
      lastvalue = energypro[0]
      lastvalue1 = mantpro[0]
      lastvalue2 = Totalpro[0]
    }
    if (lastservice == 2) {
      laststring = string4
      lastvalue = energysms[0]
      lastvalue1 = mantsms[0]
      lastvalue2 = Totalsms[0]
    }
    if (lastservice == 3) {
      laststring = string5
      lastvalue = energysmc[0]
      lastvalue1 = mantsmc[0]
      lastvalue2 = Totalsmc[0]
    }
    if (lastservice == 4) {
      laststring = "DE"
      lastvalue = energyess[0]
      lastvalue1 = mantess[0]
      lastvalue2 = Totaless[0]
    }



    // now create feed values for graph of non selected process
    var notselcost = []
    var notselcost1 = []
    var notselcost2 = []
    var notseltag = []
    var notseltag1 = []
    var notseltag2 = []
    var notseltxt = []
    var notseltxt1 = []
    var notseltxt2 = []
    lastvalue = Number(lastvalue)
    lastvalue1 = Number(lastvalue1)
    lastvalue2 = Number(lastvalue2)

    var ltsvvalue = lastvalue
    var ltsvvalue1 = lastvalue1
    var ltsvvalue2 = lastvalue2

    if (disableppro == false) {
      ltsvvalue = lastvalue * engprodup
      ltsvvalue1 = lastvalue1 * mntprodup
      ltsvvalue2 = lastvalue2 * totalprodup

      lastvalue = this.checkdrop(lastvalue, engprodup);
      notselcost.push(lastvalue.toFixed(0));

      lastvalue1 = this.checkdrop(lastvalue1, mntprodup);
      notselcost1.push(lastvalue1.toFixed(0));

      lastvalue2 = this.checkdrop(lastvalue2, totalprodup);
      notselcost2.push(lastvalue2.toFixed(0));

      laststring = laststring + "+PumpPro";
      notseltag.push(laststring);
      notseltxt.push(this.formatWithCurrency(lastvalue))

      laststring = laststring;
      notseltag1.push(laststring);
      notseltxt1.push(this.formatWithCurrency(lastvalue1))
    }
    else {
      notselcost.push(undefined)
      notselcost1.push(undefined)
      notselcost2.push(undefined)
      notseltag.push(undefined)
      notseltag1.push(undefined)
      notseltag2.push(undefined)
      notseltxt.push(undefined)
      notseltxt1.push(undefined)
      notseltxt2.push(undefined)
    }
    if (disablessm == false) {

      lastvalue = this.checkdrop(ltsvvalue, engsmsdup); notselcost.push(lastvalue.toFixed(0));
      lastvalue1 = this.checkdrop(ltsvvalue1, mntsmsdup); notselcost1.push(lastvalue1.toFixed(0));
      lastvalue2 = this.checkdrop(ltsvvalue2, totalsmsdup); notselcost2.push(lastvalue2.toFixed(0));
      ltsvvalue = ltsvvalue * engsmsdup
      ltsvvalue1 = ltsvvalue1 * mntsmsdup
      ltsvvalue2 = ltsvvalue2 * totalsmsdup

      laststring = laststring + "+SS";
      notseltag.push(laststring);
      notseltxt.push(this.formatWithCurrency(lastvalue))

      laststring = laststring;
      notseltag1.push(laststring);
      notseltxt1.push(this.formatWithCurrency(lastvalue1))
    }


    else {
      notselcost.push(undefined)
      notselcost1.push(undefined)
      notselcost2.push(undefined)
      notseltag.push(undefined)
      notseltag1.push(undefined)
      notseltag2.push(undefined)
      notseltxt.push(undefined)
      notseltxt1.push(undefined)
      notseltxt2.push(undefined)
    }
    if (disablescomm == false) {

      lastvalue = this.checkdrop(ltsvvalue, engsmcdup); notselcost.push(lastvalue.toFixed(0));
      lastvalue1 = this.checkdrop(ltsvvalue1, mntsmcdup); notselcost1.push(lastvalue1.toFixed(0));
      lastvalue2 = this.checkdrop(ltsvvalue2, totalsmcdup); notselcost2.push(lastvalue2.toFixed(0));
      ltsvvalue1 = ltsvvalue1 * mntsmcdup
      ltsvvalue = ltsvvalue * engsmcdup
      ltsvvalue2 = ltsvvalue2 * totalsmcdup

      laststring = laststring + "+SC";
      notseltag.push(laststring);
      notseltxt.push(this.formatWithCurrency(lastvalue))

      laststring = laststring;
      notseltag1.push(laststring);
      notseltxt1.push(this.formatWithCurrency(lastvalue1))
    }


    else {
      notselcost.push(undefined)
      notselcost1.push(undefined)
      notselcost2.push(undefined)
      notseltag.push(undefined)
      notseltag1.push(undefined)
      notseltag2.push(undefined)
      notseltxt.push(undefined)
      notseltxt1.push(undefined)
      notseltxt2.push(undefined)

    }



    bar1st(arraytoteng, Totalenginq, Totalengbid, Totalengess, Totalengpro, Totalengsms, Totalengsmc, string1, string2, string3, string4, string5, yval2, this.alldisable, notselcost, notseltag, notseltxt);
    bar2nd(arraytotmnt, Totalmntinq, Totalmntbid, Totalmntess, Totalmntpro, Totalmntsms, Totalmntsmc, string1, string2, string3, string4, string5, yval2, this.alldisable, notselcost1, notseltag1, notseltxt1);
    bar3rd(arraytotopr, Totalinq, Totalbid, Totaless, Totalpro, Totalsms, Totalsmc, string1, string2, string3, string4, string5, yval2, this.alldisable, notselcost2, notseltag1, notseltxt1);
    line1st(arraytotcum, cuminqcost, cumbidcost, cumesscost, cumprocost, cumsmscost, cumsmccost, string1, string2, string3, string4, string5, yval2, this.intersectline(cuminqcost, cumbidcost), this.intersectline(cumbidcost, finalselec), this.intersectline(cuminqcost, finalselec), this.alldisable);
    // bar5th(arraytotcont,contenergy,contenergy2,contplannedmaint,contunplannedmaint,contdwntime,servcont,servcontsave);
    bar4th(arraytottrain, costofservcontract, shortendlife, engloss, servicetraining, trainingsave);
    // bar6th(arraytotcew, Lccp, Pcpnw, CEWcost, cewsavings);


    this.ROI3 = Math.floor(Number(Number((this.intersectline(cuminqcost, cumbidcost))[0]) - 1))
    this.ROI1 = Math.floor(Number(Number((this.intersectline(cumbidcost, finalselec))[0]) - 1))
    this.ROI2 = Math.floor(Number(Number((this.intersectline(cuminqcost, finalselec))[0]) - 1))


    // Sum whole costing



  }


  back() {
    this.processsservice.cheeck = "old"
    this.Reportpage = false
    //this.CalcComponent.fillpage = true

  }

  checkdrop(leftval, droper) {
    var x = (leftval * droper * (1 + 1.03 + Math.pow(1.03, 2) + Math.pow(1.03, 3) + Math.pow(1.03, 4) + Math.pow(1.03, 5)
      + Math.pow(1.03, 6) + Math.pow(1.03, 7) + Math.pow(1.03, 8) + Math.pow(1.03, 9)))

    return x

  }

  strArr_formatWithCurrency(nums: string[]) {
    return this.curncy + this.customNumFormat(nums.map(Number).reduce((a, b) => a + b, 0));
  }

  numArr_formatWithCurrency(nums: number[]) {
    return this.curncy + this.customNumFormat(nums.reduce((a, b) => a + b, 0));
  }

  formatWithCurrency(num: number) {
    return this.curncy + this.customNumFormat(num);
  }

  customNumFormat(num: number) {
    if (num < 1000) {
      return num.toPrecision(4);
    } else {
      return (num / 1000).toPrecision(4) + 'k';
    }
  }


  User_guide() {
    this.router.navigate(['../User_guide'])
  }
}
