
import { getWorkRateByCurrencyCode } from "../currencies";
import { CachedPropertyMethods, createCachedPropertiesWithFormula } from "../proxy";
import { IInputTable } from "./InputTable";

// | Response Time Premium      |                                                                                          |                |              |
// |----------------------------|------------------------------------------------------------------------------------------|----------------|--------------|
// |                            |                                                                                          | 1.5            | 2            |
// |                            | Standard Rates                                                                           | 8 Hr Response  | 4Hr Response |
// | 1/2 Day Rate               | =IF(Input!M12="GBP",$AG$2,(IF(Input!M12="USD",$AE$2,(IF(Input!M12="CAD",$AF$2,$AD$2))))) | =W26*$X$24     | =W26*$Y$24   |
// | Full Day Rate              | =IF(Input!M12="GBP",$AG$3,(IF(Input!M12="USD",$AE$3,(IF(Input!M12="CAD",$AF$3,$AD$3))))) | =W27*$X$24     | =W27*$Y$24   |
// |                            | =Input!M12                                                                               | =W28           | =X28         |
// | Assumption:                |                                                                                          |                |              |
// | # of emergency site visits | 2                                                                                        |                |              |
// | # of days/visit            | 2                                                                                        |                |              |
// | Total site days/year       | =W30*W31                                                                                 |                |              |
// | Regular Rate spend         | =W32*W27                                                                                 |                |              |
// | 8Hr Response spend         | =W32*X27                                                                                 | 8Hr Premium    | =W34-W33     |
// | 4Hr Reponse spend          | =Y27*W32                                                                                 | 4Hr Premium    | =W35-W33     |


const numberOfEmergencySiteVisits = 2;
const numberOfDaysPerVisit = 2;
const totalSiteDaysPerYear = numberOfEmergencySiteVisits * numberOfDaysPerVisit;
const rate_8hrResponse = 1.5;
const rate_4hrResponse = 2;

export interface IResponseTimePremiumTable {
  input: IInputTable;

  fullDay_standardRates: number;
  fullDay_8hrResponse: number;
  fullDay_4hrResponse: number;

  regularRateSpend: number;
  responseSpend_8hr: number;
  responseSpend_4hr: number;

  responsePremium_8hr: number;
  responsePremium_4hr: number;
}

const responseTimePremiumFormulas: CachedPropertyMethods<IResponseTimePremiumTable> = {
  input: p => p.input,

  fullDay_standardRates: p => getWorkRateByCurrencyCode(p.input.currencyCode).fullDay,
  fullDay_8hrResponse: p => p.fullDay_standardRates * rate_8hrResponse,
  fullDay_4hrResponse: p => p.fullDay_standardRates * rate_4hrResponse,

  regularRateSpend: p => p.fullDay_standardRates * totalSiteDaysPerYear,
  responseSpend_8hr: p => p.fullDay_8hrResponse * totalSiteDaysPerYear,
  responseSpend_4hr: p => p.fullDay_4hrResponse * totalSiteDaysPerYear,

  responsePremium_8hr: p => p.responseSpend_8hr - p.regularRateSpend,
  responsePremium_4hr: p => p.responseSpend_4hr - p.regularRateSpend,
}

export const createResponseTimePremiumTable = (predefinedValues: Partial<IResponseTimePremiumTable> = {}) =>
  createCachedPropertiesWithFormula<IResponseTimePremiumTable>(responseTimePremiumFormulas, predefinedValues);


