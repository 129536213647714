
export const forEachPropInObject = (obj: any, callback: (key: string, value: any) => void) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      callback(key, obj[key]);
    }
  }
}

export const sumArrayToIndex = (arr: number[], index: number) => arr.slice(0, index).reduce((a, b) => a + b, 0);

export const round = (value: number, decimals: number) => Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const createRange = (start: number, end: number) => Array.from({ length: end - start + 1 }, (_, i) => i + start);

declare global {
  interface Array<T> {
    sum(this: Array<T>, func: (item: T) => number): number;
  }
}

interface Array<T> {
  sum(this: Array<T>, func: (item: T) => number): number;
}


Array.prototype.sum = function<T>(this: Array<T>, func: (item: T) => number): number {
  return (this as T[]).reduce((acc, current) => acc + func(current), 0);
};

