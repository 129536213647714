
  <section>
    <div class="container">
      <!-- <form id="my-form"> -->
      <form #simpleForm="ngForm">
        <title>Sign Up Form</title>
        <h1 style="background-color: skyblue ; text-align: center; font-weight: bold; height: 30pxS;"> Sign Up </h1>

        <div class="form-group">
          <label for="firstName"> First Name</label>
          <input type="text" [(ngModel)]="firstname" id="firstName" name="firstName" required>
          <div *ngIf="!firstname" class="alert alert-danger d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
              class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
              aria-label="Warning:">
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              Please fill your first name here !!!
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="latsName">Last Name</label>
          <input type="text" [(ngModel)]="lastname" id="lastName" name="lastName" required>
          <div *ngIf="!lastname" class="alert alert-danger d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
              class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
              aria-label="Warning:">
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              Please fill your last name here !!!
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" [(ngModel)]="emailid" id="email" name="email" required>
          <div *ngIf="!emailid" class="alert alert-danger d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
              class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
              aria-label="Warning:">
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              Please provide your email here !!!
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="massage">Message</label>
          <textarea name="massage" [(ngModel)]="message" id="massage" cols="30" rows="10"
            placeholder="anything you want to share..."></textarea>
        </div>

        <button type="submit" (click)="onsubmit(simpleForm.value)">Submit</button>
      </form>
    </div>
    <div id="status"></div>
  </section>




<script>
  var form = document.getElementById("my-form");

  async function handleSubmit(event) {
    event.preventDefault();
    var status = document.getElementById("my-form-status");
    var data = new FormData(event.target);
    fetch(event.target.action, {
      method: form.method,
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        status.innerHTML = "Thanks for your submission!";
        form.reset()
      } else {
        response.json().then(data => {
          if (Object.hasOwn(data, 'errors')) {
            status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
          } else {
            status.innerHTML = "Oops! There was a problem submitting your form"
          }
        })
      }
    }).catch(error => {
      status.innerHTML = "Oops! There was a problem submitting your form"
    });
  }
  form.addEventListener("submit", handleSubmit)
</script>
