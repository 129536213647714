<head>
  <script src="./report.component.ts"></script>
  <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>

</head>


  <div class="top-bar">
    <button class="blue-button1" (click)="openModalTable()">Open Report</button>
    <button class="blue-button1" (click)="saveAll()">Save Report</button>
    <a class="blue-button1" [routerLink]="['/User_guide']" target="_blank">User-Guide</a>
  </div>

  <br><br><br>
  <div id="body_content">
    <h5 style="display: inline-block;">DEVA service Report</h5>
    <br><br>
    <div id="header" class="btn-div">
      <button style="display: inline-block;" class="blue-button" routerLink="../calculation-view"
        (click)="back()">Back</button>
      <button style="display: inline-block;" id="btnPrint2" class="blue-button" routerLink="../UserDetails"
        (click)="reset()">Create New</button>
      <button style="display: inline-block;" id="btnPrint1" class="blue-button buttongen" (click)="downloadPDF()">
        Export Report
      </button>
    </div>
    <br>
    <div>
      <div id="content" style="border: 2px solid lightgrey;padding: 10px;">
        <div class="page page1">
          <table class="Prdetails">
            <tr>
              <td>Revision: 01</td>
            </tr>
            <tr>
              <td>Quote Reference: {{ this.prdetails['adept'] }}</td>
            </tr>
            <tr>
              <td>Quote Name: {{ this.prdetails['adept'] }}</td>
            </tr>
            <tr>
              <td>Representative: {{ this.prdetails['username'] }}</td>
            </tr>
            <tr>
              <td>Project Name: {{ this.prdetails['prname'] }}</td>
            </tr>
            <tr>
              <td>Date: {{ this.today }}</td>
            </tr>

          </table>
          <table class="headtag" style="margin-top: 20px;">
            <tr>
              <td colspan="4">A | Services & Parts Value and Benefits</td>
              <td></td>
              <td colspan="2"></td>
            </tr>
          </table>
          <div class="logdet">
            <div class="head1">
              <h4>Services & Parts Offering Value Summary</h4>
              <label>
                We are pleased to offer you this value summary of our Building Services Offerings. Armstrong’s portfolio
                of Service and Support
                offerings provide complete solutions for Engineers, Contractors, and Owners. Working collaboratively
                with our network of partners,
                we provide cost-effective support resources to help you get the best possible performance from your
                mechanical systems, at every phase
                in the lifecycle of your building.
              </label>
            </div>
            <br><br>
            <table class="head2" style="table-layout:fixed;">
              <tr>
                <td>Start-up and Commissioning</td>
                <td>Maintenance Repair and Operation</td>
                <td>Retrofit Energy Upgrades</td>
              </tr>
            </table>
            <br><br>
            <table class="head3">
              <tr>
                <td class="thed">START-UP AND COMMISSIONING</td>
                <td></td>
                <td class="thed" colspan="4">MAINTENANCE, REPAIR AND OPERATION</td>
              </tr>
              <tr>
                <td class="thd1">Installation and Services</td>
                <td></td>
                <td class="thd1" colspan="4">Performance Management Services</td>
              </tr>
              <tr>
                <td class="tda">Fully trained, certified and experienced technicians</td>
                <td></td>
                <td class="tda" colspan="2">Performance tracking</td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td class="tda1">Extensive safety training and full PPE</td>
                <td></td>
                <td class="tda" colspan="2">Early diagnostic and warnings</td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td class="thd1">SMART Start-up and Commissioning</td>
                <td></td>
                <td class="tda" colspan="2">Web accessible trends and analysis</td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td class="tda">Detailed performance reports</td>
                <td></td>
                <td class="tda" colspan="2">Data storage and autmoated reports</td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td class="tda">Fully trained, certified and experienced technicians</td>
                <td></td>
                <td class="thd1" colspan="4">Emergency Services and Replacement Parts</td>
              </tr>
              <tr>
                <td class="tda1">Extensive safety training and full PPE</td>
                <td></td>
                <td class="tda" colspan="3">24/7 Response</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="tda" colspan="3">Remote tech support</td>
                <td></td>
              </tr>
              <tr>
                <td class="thed">RETROFIT ENERGY UPGRADES</td>
                <td></td>
                <td class="tda" colspan="3">On-site service</td>
                <td></td>
              </tr>
              <tr>
                <td class="tda">Site Walk-through</td>
                <td></td>
                <td class="tda" colspan="3">Fully trained, certified and experienced technicians</td>
                <td></td>
              </tr>
              <tr>
                <td class="tda">Energy and efficiency analysis</td>
                <td></td>
                <td class="tda1" colspan="3">Extensive safety training and full PPE</td>
                <td></td>
              </tr>
              <tr>
                <td class="tda1">Recommended for an upgrade path</td>
                <td></td>
                <td class="thd1" colspan="4">Service Agreements</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="tda">Flex Agreements</td>
                <td colspan="3"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="tda">Comphrensive Agreements</td>
                <td colspan="3"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="tda1" colspan="4">Annual Maintenace Contracts</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="thd1" colspan="4">Training Opportunities</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td class="thd1">Parts and Service Support</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="page page2">
          <table class="headtag">
            <tr>
              <td colspan="4">B | Project Asessment Selection Details </td>
              <td></td>
              <td colspan="2"></td>
            </tr>
          </table>
          <div class="logdet">
            <h4>Compliant VS Value Added</h4>
            <table class='head4 head4add'>
              <tr>
                <td colspan="3">
                  Value Added Selection
                </td>
              </tr>
              <tr>
                <td>Bid First Cost({{curncy }})</td>
                <td>Bid Annual Energy Cost({{curncy }})</td>
                <td>Bid Annual Maintenance Cost({{curncy }})</td>
              </tr>
              <tr>
                <td>{{curncy }}{{this.costdata["inqcst2"]}}</td>
                <td>{{curncy }}{{this.costdata["inqeng2"]}}</td>
                <td>{{curncy }}{{this.costdata["inqmnt2"]}}</td>
              </tr>
              <tr>
                <td colspan="3">
                  Compliant Selection
                </td>
              </tr>
              <tr>
                <td>Inquiry First Cost({{curncy }})</td>
                <td>Inquiry Annual Energy Cost({{curncy }})</td>
                <td>Inquiry Annual Maintenance Cost({{curncy }})</td>
              </tr>
              <tr>
                <td>{{curncy }}{{this.costdata["inqcst1"]}}</td>
                <td>{{curncy }}{{this.costdata["inqeng1"]}}</td>
                <td>{{curncy }}{{this.costdata["inqmnt1"]}}</td>
              </tr>


            </table>
          </div>
          <br><br>
          <table class="headtag">
            <tr>
              <td colspan="4">C | Project Assessment Selection Value & Benefits</td>
              <td></td>
              <td colspan="2"></td>
            </tr>
          </table>
          <div class="logdet">
            <h4>Design Envelope Saving Summary</h4>
            <table class='head4' *ngIf="disableppro">
              <tr>
                <td colspan="3">
                  Pump Manager Professional Service
                </td>
              </tr>
              <tr>
                <td>Pump Characteristics</td>
                <td>Energy Savings Impact</td>
                <td>Maintenance Savings Impact</td>
              </tr>
              <tr>
                <td>Pump in Hand</td>
                <td>{{this.objdata["C1401"]}}%</td>
                <td>{{this.objdata["C1502"]}}%</td>
              </tr>
              <tr>
                <td class="tablerow">Dead heading</td>
                <td class="tablerow">{{this.objdata["C1408"]}}%</td>
                <td class="tablerow">{{this.objdata["C1509"]}}%</td>
              </tr>
              <tr>
                <td>Over Sized Pumps</td>
                <td>{{this.objdata["C1426"]}}%</td>
                <td>{{this.objdata["C1527"]}}%</td>
              </tr>
              <tr>
                <td>PSPC mode</td>
                <td>{{this.objdata["C1417"]}}%</td>
                <td>{{this.objdata["C1518"]}}%</td>
              </tr>
              <tr>
                <td>Auto-flow balancing</td>
                <td>{{this.objdata["C1420"]}}%</td>
                <td>{{this.objdata["C1521"]}}%</td>
              </tr>
              <tr>
                <td>Sys Resistance Changes</td>
                <td>{{this.objdata["C1423"]}}%</td>
                <td>{{this.objdata["C1524"]}}%</td>
              </tr>
              <tr>
                <td>Maximum flow control </td>
                <td>{{this.objdata["C1429"]}}%</td>
                <td>{{this.objdata["C1530"]}}%</td>
              </tr>
              <tr>
                <td>Minimum flow control</td>
                <td>{{this.objdata["C1432"]}}%</td>
                <td>{{this.objdata["C1533"]}}%</td>
              </tr>

            </table><br *ngIf="disableppro">
            <table class='head4' *ngIf="!disableppro">
              <tr>
                <td colspan="3">
                  Pump Manager Essential Service
                </td>
              </tr>
              <tr>
                <td>Fields of Improvisation</td>
                <td>Persistent Energy Savings</td>
                <td>Maintenance Savings</td>
              </tr>
              <tr>
                <td>Pump in Hand</td>
                <td>{{this.objdata1["C2401"]}}%</td>
                <td>{{this.objdata1["C2502"]}}%</td>
              </tr>
              <tr>
                <td class="tablerow">Dead Heading</td>
                <td class="tablerow">{{this.objdata1["C2404"]}}%</td>
                <td class="tablerow">{{this.objdata1["C2505"]}}%</td>
              </tr>
            </table><br *ngIf="disablepess">
            <table class='head4' *ngIf="disablessm">
              <tr>
                <td colspan="3">
                  SMART Start-up
                </td>
              </tr>
              <tr>
                <td>Pump Characteristics</td>
                <td>Energy Savings Impact</td>
                <td>Maintenance Savings Impact</td>
              </tr>
              <tr>
                <td>PSPC mode</td>
                <td>{{this.objdata2["C3416"]}}%</td>
                <td>{{this.objdata2["C3517"]}}%</td>
              </tr>
              <tr>
                <td class="tablerow">Auto-flow balancing</td>
                <td class="tablerow">{{this.objdata2["C3401"]}}%</td>
                <td class="tablerow">{{this.objdata2["C3502"]}}%</td>
              </tr>
              <tr>
                <td>Maximum flow control </td>
                <td>{{this.objdata2["C3404"]}}%</td>
                <td>{{this.objdata2["C3505"]}}%</td>
              </tr>
              <tr>
                <td>Minimum flow control</td>
                <td>{{this.objdata2["C3407"]}}%</td>
                <td>{{this.objdata2["C3508"]}}%</td>
              </tr>
              <tr>
                <td>Dual Season Setup</td>
                <td>{{this.objdata2["C3410"]}}%</td>
                <td>{{this.objdata2["C3511"]}}%</td>
              </tr>
              <tr>
                <td>Asset Life Implication</td>
                <td>{{this.objdata2["C3418"]}}%</td>
                <td>{{this.objdata2["C3518"]}}%</td>
              </tr>

            </table><br *ngIf="disablessm">

            <table class='head4' *ngIf="disablescomm">

              <tr>
                <td colspan="3">
                  SMART Commissioning Service
                </td>
              </tr>
              <tr>
                <td>Pump Characteristics</td>
                <td>Energy Savings Impact</td>
                <td>Maintenance Savings Impact</td>
              </tr>
              <tr>
                <td>PSPC mode</td>
                <td>{{this.objdata3["C4416"]}}%</td>
                <td>{{this.objdata3["C4517"]}}%</td>
              </tr>
              <tr>
                <td class="tablerow">Auto-flow balancing</td>
                <td class="tablerow">{{this.objdata3["C4401"]}}%</td>
                <td class="tablerow">{{this.objdata3["C4502"]}}%</td>
              </tr>
              <tr>
                <td>Maximum flow control </td>
                <td>{{this.objdata3["C4404"]}}%</td>
                <td>{{this.objdata3["C4505"]}}%</td>
              </tr>
              <tr>
                <td>Minimum flow control</td>
                <td>{{this.objdata3["C4407"]}}%</td>
                <td>{{this.objdata3["C4508"]}}%</td>
              </tr>
              <tr>
                <td>Dual Season Setup</td>
                <td>{{this.objdata3["C4410"]}}%</td>
                <td>{{this.objdata3["C4511"]}}%</td>
              </tr>
              <tr>
                <td>Asset Life Implication</td>
                <td>{{this.objdata3["C4418"]}}%</td>
                <td>{{this.objdata3["C4518"]}}%</td>
              </tr>

            </table>
            <br *ngIf="disablescomm">
            <br>
          </div>
        </div>
        <div class="page page3">
          <table class='head4' *ngIf="disablepupgrad">
            <tr>
              <td colspan="3">
                DEPC Retrofit
              </td>
            </tr>
            <tr>
              <td>Pump Characteristics</td>
              <td>Energy Savings Impact</td>
              <td>Maintenance Savings Impact</td>
            </tr>
            <tr>
              <td>Auto-flow balancing</td>
              <td>{{this.objdata4["C5401"]}}%</td>
              <td>{{this.objdata4["C5502"]}}%</td>
            </tr>
            <tr>
              <td class="tablerow">Maximum Flow Control</td>
              <td class="tablerow">{{this.objdata4["C5404"]}}%</td>
              <td class="tablerow">{{this.objdata4["C5505"]}}%</td>
            </tr>
            <tr>
              <td>Minimum flow control</td>
              <td>{{this.objdata4["C5407"]}}%</td>
              <td>{{this.objdata4["C5508"]}}%</td>
            </tr>
            <tr>
              <td>Dual Season Setup</td>
              <td>{{this.objdata4["C5410"]}}%</td>
              <td>{{this.objdata4["C5511"]}}%</td>
            </tr>
            <tr>
              <td>Protection Bundle</td>
              <td>{{this.objdata4["C5413"]}}%</td>
              <td>{{this.objdata4["C5514"]}}%</td>
            </tr>
            <tr>
              <td>PSPC Bundle</td>
              <td>{{this.objdata4["C5416"]}}%</td>
              <td>{{this.objdata4["C5517"]}}%</td>
            </tr>


          </table><br *ngIf="disablepupgrad">


          <div class="labs" *ngIf="alldisable">
            <label>
              No Service Offering has been selected</label>
          </div>


          <table class="headtag">
            <tr>
              <td colspan="4">D | Services & Parts Comparative Savings Summaries </td>
              <td></td>
              <td colspan="2"></td>
            </tr>
          </table>
          <!-- <br> -->
          <div class="logdet">
            <h4>Energy & Maintenance Expenditure Comparison </h4>

            <label class="labs" *ngIf="disableppro">
              Adding (Pump Manager Professional) to the Value Added Design Envelope solution would result in an
              incremental
              <label class="inc">{{this.objdata["C14ag"]}}%</label> savings in Energy, and <label
                class="inc">{{this.objdata["C15ag"]}}%</label> in Maintenance expenditures.
            </label>
            <br *ngIf="disableppro">
            <label class="labs" *ngIf="disablessm">
              Adding (SMART Startup) offerings would result in incremental Energy and Maintenance savings of
              <label class="inc">{{this.objdata2["C34ag"]}}%</label> and <label
                class="inc">{{this.objdata2["C35ag"]}}%</label> Respectively.
            </label>
            <label class="labs" *ngIf="disablescomm">
              Adding (SMART Commissioning) service offerings would result in incremental Energy and Maintenance savings
              of
              <label class="inc">{{this.objdata3["C44ag"]}}%</label> and <label
                class="inc">{{this.objdata3["C45ag"]}}%</label> Respectively.
            </label>
            <label class="labs" *ngIf="!disableppro && !alldisable">
              Though you have not selected (Pump Manager Professional) service, if selected, it would have resulted in
              an incremental
              <label class="inc1">{{this.objdata["C14ag"]}}%</label> savings in Energy, and <label
                class="inc1">{{this.objdata["C15ag"]}}%</label>
              in Maintenance expenditures</label>
            <label class="labs" *ngIf="!disablessm && !alldisable">
              Though you have not selected (SMART Startup), if selected, it would have resulted in an incremental
              <label class="inc1">{{this.objdata2["C34ag"]}}%</label> savings in Energy, and <label
                class="inc1">{{this.objdata2["C35ag"]}}%</label>
              in Maintenance expenditures.
            </label>
            <label class="labs" *ngIf="!disablescomm && !alldisable">
              Though you have not selected (SMART Commissioning), if selected, it would have resulted in an incremental
              <label class="inc1">{{this.objdata3["C44ag"]}}%</label> savings in Energy, and <label
                class="inc1">{{this.objdata3["C45ag"]}}%</label>
              in Maintenance expenditures.
            </label>
            <div class="labs" *ngIf="alldisable">
              <label>
                Though you have not selected an Active Performance Management service offerings, your unrealised
                operational savings are summarised as follows:
              </label>
              <!-- <br> -->
              <label>
                If (Pump Manger Professional) was selected, it would have resulted in an incremental <label
                  class="inc1">{{this.objdata["C14ag"]}}%</label> savings in Energy, and <label
                  class="inc1">{{this.objdata["C15ag"]}}%</label> in Maintenance expenditures. </label>
              <label>If (Smart Startup) was selected, it would have resulted in an incremental <label
                  class="inc1">{{this.objdata2["C34ag"]}}%</label> savings in Energy, and <label
                  class="inc1">{{this.objdata2["C35ag"]}}%</label> in Maintenance expenditures. </label>
              <label>If (Smart Commissioning) was selected, it would have resulted in an incremental <label
                  class="inc1">{{this.objdata3["C44ag"]}}%</label> savings in Energy, and <label
                  class="inc1">{{this.objdata3["C45ag"]}}%</label> in Maintenance expenditures.</label>
            </div>

            <div id="bar1st"></div>

            <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">Figure
              (1): 10-Yr Energy Expenditure</label>
          </div>

          <!-- <div id="bar2nd" style="height: 80%;"></div> -->
          <!-- <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">Figure (2): 10-Yr Maintenance Expenditure</label> -->


        </div>
        <div class="page page4">
          <div id="bar2nd" style="margin-top: 30px;"></div>
          <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">
            Figure (2): 10-Yr Maintenance Expenditure
          </label>
          <div id="bar3rd" style="margin-top: 30px;"></div>
          <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">
            Figure (3): 10-Yr Total Op-Ex
          </label>
        </div>
        <div class="page page5">
          <div class="logdet">
            <br>
            <h4>Service & Parts Comparative Offering ROI Comparison</h4>
            <label class="labs" *ngIf="!alldisable">
              Figure (4) highlights the running cumulative operational costs of the user-defined Base Bid (Non-DE)
              alternative to the Value Added Design Envelope (DE) solution.
              The intersection cross over point of the two curves occurs at Operational Year <label
                class="inc1">{{this.ROI3}}</label> ( ROI-3 ). Adding Armstrong’s various Field and Cloud
              service offerings to the DE solution proposal, results in a shortening of the realized Return on
              Investment period to Operational Year <label class="inc1">{{this.ROI1}}</label> ( ROI-1 ).
              Realtive to the Base Bid (non-DE), the DE solution plus services realizes a Return on Investment by
              Operational Year <label class="inc1">{{this.ROI2}}</label> ( ROI-2 ).
            </label>
            <label class="labs" *ngIf="alldisable">
              Figure (4) highlights the running cumulative operational costs of the user-defined Base Bid (Non-DE)
              alternative to the Value Added Design Envelope (DE) solution.
              The intersection cross over point of the two curves occurs at Operational Year <label
                class="inc1">{{this.ROI3}}</label> ( ROI-3 ).
              Though you have not added Armstrong’s various Field and Cloud service offerings to the Value Added Design
              Envelope solution proposal,
              if selected, they would have resulted in a shortening of the realized ROI period to Operational Year
              <label class="inc1">{{this.ROI1}}</label> ( ROI-1 ).
              Realtive to the Base Bid (non-DE), the DE solution plus services would have realized Return on Investment
              by Operational Year <label class="inc1">{{this.ROI2}}</label> ( ROI-2 ).
            </label>
            <br><br><br><br><br>
            <div id="line1st" style="height: 50%;"></div>
            <label style="width: 670px;display: block; text-align: center;margin-left: auto;margin-right: auto;">Figure
              (4): 10-Yr Total Op-Ex </label>
          </div>
        </div>
        <div class="page page6 page-last">
          <br><br>
          <div id="bar4th" style="display: block; height: 2px"></div>
          <!-- <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">Status Quo       Vs       Armstrong service</label> -->
          <!-- <br><br><br><br><br><br><br><br><br><br> -->

          <div id="bar5th"></div>
          <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">
            Status Quo Vs Armstrong training
          </label>
        </div>

        <!-- <div class="page6">
          <br><br><br><br>
          <div id="bar6th"></div>
          <label style="width: 650px;display: block; text-align: center;margin-left: auto;margin-right: auto;">
            Status Quo vs. Armstrong's CEW
          </label>
        </div> -->


      </div>
    </div>
  </div>
  <script src="../../assets/js/plotass.js"></script>

