
type CountryInfo = {
  country: string;
  currencyCode: string;
};

type WorkRate = {
  halfDay: number;
  fullDay: number;
}

type WorkRates = {
  [key: string]: WorkRate;
};


export const countriesInfo: CountryInfo[] = [
  {
    country: 'Canada',
    currencyCode: 'CAD',
  },
  {
    country: 'UK',
    currencyCode: 'GBP',
  },
  {
    country: 'US',
    currencyCode: 'USD',
  },
  {
    country: 'India',
    currencyCode: 'INR',
  },
]

export const timeCostInLocalCurrency: WorkRates = {
  Canada: {
    halfDay: 280,
    fullDay: 560,
  },
  UK: {
    halfDay: 420,
    fullDay: 720,
  },
  US: {
    halfDay: 740,
    fullDay: 1480,
  },
  India: {
    halfDay: 6512,
    fullDay: 13024,
  },
}

export const currenciesData = [
  {
    ccyIndex: "CAD",
    rate: 1.0,
    symbol: 'CA$',
    currencyCode: 'CAD',
  },
  {
    ccyIndex: "CHINESE YUAN",
    rate: 5.13,
    symbol: '¥',
    currencyCode: 'CNY',
  },
  {
    ccyIndex: "USD",
    rate: 0.72,
    symbol: 'US$',
    currencyCode: 'USD',
  },
  {
    ccyIndex: "IND",
    rate: 68,
    symbol: 'RS',
    currencyCode: 'INR',
  },
  {
    ccyIndex: "INDONESIA RUPIAH",
    rate: 11536.73,
    symbol: 'Rp',
    currencyCode: 'IDR',
  },
  {
    ccyIndex: "MALAYSIAN RINGGITS",
    rate: 3.28,
    symbol: 'RM',
    currencyCode: 'MYR',
  },
  {
    ccyIndex: "THAILAND BAHT",
    rate: 26.04,
    symbol: '฿',
    currencyCode: 'THB',
  },
  {
    ccyIndex: "SINGAPORE DOLLAR",
    rate: 1.02,
    symbol: 'S$',
    currencyCode: 'SGD',
  },
  {
    ccyIndex: "TAIWAN DOLLAR",
    rate: 22.92,
    symbol: 'NT$',
    currencyCode: 'TWD',
  },
  {
    ccyIndex: "SOUTH KOREAN WON",
    rate: 972.70,
    symbol: '₩',
    currencyCode: 'KRW',
  },
  {
    ccyIndex: "VIETNAMESE DONG",
    rate: 18254.80,
    symbol: '₫',
    currencyCode: 'VND',
  },
  {
    ccyIndex: "BRAZILIAN REAL",
    rate: 3.86,
    symbol: 'R$',
    currencyCode: 'BRL',
  },
  {
    ccyIndex: "EURO",
    rate: 0.71,
    symbol: '€',
    currencyCode: 'EUR',
  },
  {
    ccyIndex: "COLOMBIAN PESO",
    rate: 3597.07,
    symbol: 'Col$',
    currencyCode: 'COP',
  },
  {
    ccyIndex: "COSTA RICAN COLON",
    rate: 441.58,
    symbol: '₡',
    currencyCode: 'CRC',
  },
  {
    ccyIndex: "PERUVIAN SOL",
    rate: 2.85,
    symbol: 'S/.',
    currencyCode: 'PEN',
  },
  {
    ccyIndex: "BAHRAINI DINAR",
    rate: 0.28,
    symbol: 'د.ب',
    currencyCode: 'BHD',
  },
  {
    ccyIndex: "EGYPTIAN POUND",
    rate: 18.31,
    symbol: 'E£',
    currencyCode: 'EGP',
  },
  {
    ccyIndex: "IRAQI DINAR",
    rate: 1079.86,
    symbol: 'IQD',
    currencyCode: 'IQD',
  },
  {
    ccyIndex: "KUWAITI DINAR",
    rate: 0.23,
    symbol: 'د.ك',
    currencyCode: 'KWD',
  },
  {
    ccyIndex: "OMANI RIAL",
    rate: 0.29,
    symbol: 'ر.ع.',
    currencyCode: 'OMR',
  },
  {
    ccyIndex: "QATARI RIYAL",
    rate: 2.71,
    symbol: 'QR',
    currencyCode: 'QAR',
  },
  {
    ccyIndex: "SAUDI RIYAL",
    rate: 2.80,
    symbol: 'SAR',
    currencyCode: 'SAR',
  },
  {
    ccyIndex: "UAE DIRHAM",
    rate: 2.74,
    symbol: 'د.إ',
    currencyCode: 'AED',
  },
  {
    ccyIndex: "MEXICAN PESO",
    rate: 14.33,
    symbol: 'MX$',
    currencyCode: 'MXN',
  },
  {
    ccyIndex: "POUND STERLING",
    rate: 0.61,
    symbol: '£',
    currencyCode: 'GBP',
  },
];


export const getWorkRateByCurrencyCode = (currencyCode: string) => {
  const currencyData = currenciesData.find(x => x.currencyCode === currencyCode);
  if (!currencyData)
    throw new Error(`Currency code ${currencyCode} is not supported.`);

  const countryInfo = countriesInfo.find(x => x.currencyCode === currencyCode);
  const timeCostInfo = timeCostInLocalCurrency[countryInfo?.country];

  let rates = timeCostInfo;
  if (!rates) {
    const ratesUs = timeCostInLocalCurrency['US'];
    const exchangeRateCadToUsd = currenciesData.find(x => x.currencyCode === "USD").rate;
    const exchangeRateCadToLocal = currencyData.rate;
    const exchangeRateUsdToLocal = exchangeRateCadToLocal / exchangeRateCadToUsd;
    rates = {
      halfDay: ratesUs.halfDay * exchangeRateUsdToLocal,
      fullDay: ratesUs.fullDay * exchangeRateUsdToLocal,
    }
  }

  return rates;
}


export const getWorkRateFromPumpsCount = (pumpsCount: number, workRate: WorkRate) => {
  if (pumpsCount <= 0) return 0;

  const devidedBy4 = Math.floor((pumpsCount - 1) / 4);
  let totalCost = workRate.fullDay * Math.floor((devidedBy4 + 1) / 2);
  totalCost += workRate.halfDay * ((devidedBy4 + 1) % 2);

  return totalCost;
}

export const getWorkRateFromPumpsCountAndCurrency = (pumpsCount: number, currencyCode: string) =>
  getWorkRateFromPumpsCount(pumpsCount, getWorkRateByCurrencyCode(currencyCode));

const workRatesSmComm_HalfDay_GBP = 620;
const workRatesSmComm_FullDay_GBP = 1340;

export const getRateSmCommFromPumpsCount_GBP = (pumpsCount: number) =>
  getWorkRateFromPumpsCount(pumpsCount, { halfDay: workRatesSmComm_HalfDay_GBP, fullDay: workRatesSmComm_FullDay_GBP });

